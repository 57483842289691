<template>
  <!-- 设置交易密码 -->
  <div class="setLoginPassword">
    <div class="header_box">
      <van-nav-bar :title="$t('修改交易密码')" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>
    <div class="content">
      <van-field v-model="old_trade_password" :label="$t('旧密码')" :placeholder="$t('请输入旧密码')" type="password" clearable
        maxlength="6" />
      <van-field v-model="password" :label="$t('交易密码')" :placeholder="$t('请输入交易密码')" type="password" clearable
        maxlength="6" />
      <van-field v-model="confirm" :label="$t('确认交易密码')" :placeholder="$t('请再次输入交易密码')" type="password" clearable
        maxlength="6" />
      <div class="btn">
        <van-button color="#F13B10" @click="save">{{ $t('保存') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  data() {
    return {
      old_trade_password: '',
      password: '',
      confirm: '',
    }
  },
  created() { },
  methods: {
    save() {
      this.$post({
        url: '/app/user/updateTradeByOld',
        data: {
          trade_password: this.password,
          confirm_trade_password: this.confirm,
          old_trade_password: this.old_trade_password,
        },
        success: (res) => {
          console.log(res)
          this.$toast(this.$t("修改成功"))
          this.$router.go(-1)
        },
        tip: (val) => { }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.setLoginPassword {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    /deep/.van-cell {
      width: 100vw;
      height: 18vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {}
    }

    /deep/.van-cell::after {
      border: 0;
    }

    .btn {
      margin: 37vh auto 0;
      width: 79vw;

      /deep/.van-button {
        width: 79vw;
        height: 15vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
  }
}

/deep/.van-cell--required::before {
  top: 5vw;
}
</style>
