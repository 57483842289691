<template>
  <!-- 收货地址 -->
  <div class="myAddress">
    <div class="header_box">
      <van-nav-bar :title="$t('收货地址')" left-arrow @click-left="$router.go(-1)" :border="false" :right-text="$t('添加地址')"
        @click-right="
          $router.push({
            name: 'addOrEditAddress',
            query: { addOrEdit: 'add' },
          })
          " />
    </div>

    <div class="content">
      <div class="addressList">
        <div class="users" v-for="(ele, index) in AddressList" :key="index">
          <p>
            <span class="name">{{ ele.name }} </span>
            <span class="default" :class="ele.is_default == 1 ? 'yesDefault' : 'noDefault'"
              @click="onDefault(ele.is_default, ele.id)">
              {{ ele.is_default == 1 ? $t("默认") : $t("设为默认") }}
            </span>
          </p>
          <p>
            <span class="ids">{{
              ele.area_name.split(' ')[0] + "********"
            }}</span>
          </p>
          <div class="operation">
            <!-- <span>
              <img src="../../../assets/img/edit_icon.png" alt="" style="width: 3vw; height: 3vw" @click="
                $router.push({
                  name: 'addOrEditAddress',
                  query: { id: ele.id, addOrEdit: 'edit' },
                })
                " />
            </span> -->
            <span @click="onDelete(ele.id)">
              <van-icon name="delete-o" size="15" color="#666" />
            </span>
            <span v-if="code == -5">
              <span class="select cur" @click="bindAddress(ele.id)">{{ $t('使用') }}</span>
            </span>

            <span v-if="is_order">
              <span class="select" :class="ele.id == order_id ? 'cur' : ''" @click="goConfirmOr(ele.id)">{{ ele.id ==
                order_id ? $t("使用中") : $t("使用") }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mineAddress",
  props: {},
  data() {
    return {
      AddressList: [],
      //抢购 确认订单进入
      rushorder: this.$route.query.rushorder,
      seckill_id: this.$route.query.seckill_id,
      specs: this.$route.query.specs,
      amounts: this.$route.query.amount,

      //普通 确认订单进入
      is_order: this.$route.query.is_order || false,
      order_id: this.$route.query.order_id,
      goods_id: this.$route.query.goods_id,
      specs_id: this.$route.query.specs_id,
      amount: this.$route.query.amount,
      is_seckill: this.$route.query.is_seckill,
      //购物车订单的
      ids: "",
      //订单详情
      code: this.$route.query.code,
    };
  },
  created() { },
  mounted() {
    if (this.$route.query.ids) {
      this.ids = this.$route.query.ids;
    }
    this.getAddressList();
  },
  methods: {
    // 获取地址列表
    getAddressList() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/region/list",
        success: (res) => {
          this.$toast.clear();
          this.AddressList = res.data;
        },
        tip: () => { },
      });
    },
    // 设值为默认地址
    onDefault(e, id) {
      if (e !== 1) {
        this.$post({
          url: "/app/region/default",
          data: {
            id: id,
            is_default: 1,
          },
          success: (res) => {
            this.getAddressList();
          },
          tip: () => { },
        });
      }
    },

    // 选择使用的地址
    goConfirmOr(id) {
      if (this.ids) {
        this.$router.replace({
          name: "carOrder",
          query: { address_id: id, ids: this.ids },
        });
      } else if (this.rushorder) {
        this.$router.replace({
          name: "RushconfirmOrder",
          query: {
            address_id: id,
            goods_id: this.goods_id,
            seckill_id: this.seckill_id,
            specs: this.specs,
            amount: this.amounts,
          },
        });
      } else {
        this.$router.replace({
          name: "confirmOrder",
          query: {
            address_id: id,
            goods_id: this.goods_id,
            specs_id: this.specs_id,
            amount: this.amount,
            is_seckill: this.is_seckill,
          },
        });
      }
    },
    // 删除地址
    onDelete(e) {
      this.$dialog
        .confirm({
          title: this.$t("提示"),
          message: this.$t("确认要删除吗?"),
        })
        .then(() => {
          this.$post({
            url: "/app/region/delete",
            data: { id: e },
            success: () => {
              this.$toast.clear();
              this.$toast({
                message: this.$t("删除成功"),
                duration: 1500,
              });
              this.getAddressList();
              // setTimeout(() => {
              //   this.$router.replace("/myAddress");
              // }, 1500);
            },
            tip: () => { },
          });
        })
        .catch(() => {
          // on cancel
        });
    },

    bindAddress(id) {
      this.$dialog
        .confirm({
          title: this.$t("提示"),
          message: this.$t("确认要使用此地址码?"),
        })
        .then(() => {
          this.$post({
            url: "/app/order_goods/bindAddress",
            data: {
              order_id: this.order_id,
              address_id: id,
            },
            success: () => {
              this.$toast.clear();
              this.$toast({
                message: this.$t("绑定成功"),
                duration: 1200,
              });
              setTimeout(() => {
                this.$router.go(-1);
              }, 1300);
            },
            tip: () => { },
          });
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped lang='scss'>
.myAddress {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .addressList {
      padding: 4vw 0;

      .users {
        padding: 4vw 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        border-bottom: 3vw solid #f9f9f9;

        p {
          padding: 0 4vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .operation {
          padding: 0 4vw;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-top: 1px solid #f9f9f9;
          height: 9vw;

          >span {
            margin-left: 4vw;
          }
        }

        .name {
          font-size: 4vw;
          font-weight: bold;
          color: #000;
          margin-right: 3vw;
        }

        .default {
          display: inline-block;
          padding: 0 1vw;
          font-size: 3vw;
          color: #fff;
          border-radius: 1vw;
          transform: scale(0.8);
        }

        .yesDefault {
          background-color: #f13b10;
        }

        .noDefault {
          background-color: #5f5f5f;
        }

        .ids {
          margin-top: 2vw;
          font-size: 3vw;
          font-weight: 400;
          color: #666666;
        }

        .select {
          padding: 0 1vw;
          border: 1px solid #666666;
          color: #666666;
          border-radius: 1vw;
          transform: scale(0.8);
        }

        .cur {
          border: 1px solid #f13b10;
          color: #f13b10;
        }
      }
    }
  }

  .addAddress {
    position: absolute;
    bottom: 0;
    left: 0;

    /deep/.van-button {
      margin: 0;
      width: 100vw;
      height: 7vh;
      border-radius: 0vw;
    }

    /deep/.van-button--normal {
      padding: 0;
    }
  }
}
</style>