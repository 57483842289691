<template>
  <!-- 商品详情与评价 -->
  <div class="productDetailsOrEvaluation">
    <div class="header_box">
      <van-nav-bar :title="$t('商品详情')" left-arrow @click-left="xz_back" :border="false" />
    </div>
    <div class="content">
      <div class="stipeImg">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image" style="object-fit: cover" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <h3 class="padding04">
        {{ goodsList.goods_name }}
      </h3>
      <span class="priceNum padding04">
        <div class="price orange">
          <span class="sellingPrice orange font4 fontBold">
            {{ $t('元') }}{{ goodsList.discount_price }}
          </span>
          <span class="originalPrice grey">
            {{ $t('元') }}{{ goodsList.goods_price }}
          </span>
        </div>
        <div class="num grey">
          <span>{{ $t('销量') }}{{ goodsList.goods_sales }}{{ $t('件') }}</span>
          <span class="marginLett2">{{ $t('库存') }}{{ goodsList.goods_stock }}件</span>
        </div>
      </span>
      <div class="splitLine"></div>
      <!-- <div class="btn">
        <van-button type="primary" color="#fff" :class="selectState ? 'selectBtn' : ''" @click="onProductDetails">{{
        $t('商品详情') }}</van-button>
        <van-button type="primary" color="#fff" :class="!selectState ? 'selectBtn' : ''" @click="onEvaluation">{{
        $t('评价') }}</van-button>
      </div> -->
      <!-- 商品详情 -->
      <!-- <div class="productDetails" v-show="selectState">
        <div class="imgList" v-html="goodsList.goods_desc"></div>
      </div> -->
      <!-- 评价 -->
      <!-- <vant-up-down v-show="!selectState" @list="(val) => (evaluate = val)" res="res.data.list"
        url="/app/goods/getCommentList" :data="{ id: this.id }">
        <div class="evaluation">
          <div class="evaluationList" v-for="(item, index) in evaluate" :key="index">
            <div class="use padding04">
              <div class="imgNane">
                <img :src="item.image" alt="" style="
                    width: 10vw;
                    height: 10vw;
                    overflow-y: hidden;
                    border-radius: 10vw;
                  " />
                <p class="fontBold font4">{{ item.user_name }}</p>
              </div>
              <div class="grey">{{ item.create_time }}</div>
            </div>
            <span class="padding04 marginTop2 grey">{{ item.content }}</span>
            <div class="imgList padding04 marginTop2">
              <img v-for="(ele, index) in item.imge_atlas" :key="index" :src="ele" alt="" style="
                  width: 28.6vw;
                  overflow-y: hidden;
                  border-radius: 2vw;
                  margin-left: 2vw;
                   object-fit: cover;
                " />
            </div>
            <div class="replyInformation padding04">
              <div class="productInformation">
                <div class="use">
                  <p class="fontBold font4">{{ $t('商家回复') }}</p>
                  <div class="grey">{{ item.reply_time }}</div>
                </div>
                <span class="padding04 marginTop2 grey">{{
        item.reply_state == 1 ? item.reply : $t("商家未回复，请耐心等待")
      }}</span>
              </div>
            </div>
          </div>
        </div>
      </vant-up-down> -->
    </div>
    <van-goods-action>
      <!-- <van-goods-action-icon icon="chat-o" text="客服" color="#ee0a24" /> -->
      <van-goods-action-icon icon="cart" :text="$t('购物车')" @click="addCar" />
      <!-- <van-goods-action-icon icon="cart" color="#ff5000" text="购物车" /> -->
      <van-goods-action-icon icon="star" v-if="is_collect" :text="$t('已收藏')" color="#ff5000" @click="collect" />
      <van-goods-action-icon icon="star" v-if="!is_collect" :text="$t('收藏')" @click="collect" />
      <van-goods-action-button type="warning" :text="$t('加入购物车')" @click="onBuy('addCar')" />
      <van-goods-action-button type="danger" :text="$t('立即购买')" @click="onBuy('buyNow')" />
    </van-goods-action>

    <van-popup v-model="show" position="bottom" round :safe-area-inset-bottom="true">
      <div class="popupbox">
        <div class="listbox">
          <img :src="set_saleSpecimg ? set_saleSpecimg : goodsList.goods_thumb" alt="" />
          <div class="left">
            <p style="color: #e83323; font-size: 3.2vw">
              <span>{{ $t('元') }}{{ goodsList.discount_price }}</span>
            </p>
            <p class="tit">{{ $t('元') }}{{ goodsList.goods_price }}</p>
          </div>
        </div>

        <div>
          <div v-for="(val, index) in spec" :key="index">
            <p class="listtit">{{ val.attr_name }}</p>
            <div class="numsbox">
              <p :class="item.check ? 'show' : ''" v-for="(item, keys) in val.attr_values" :key="keys"
                @click="specChange(index, keys)">
                {{ item.attr_value }}
              </p>
            </div>
          </div>
        </div>

        <div class="stepp">
          <p class="listtit">{{ $t('数量') }}</p>
          <van-stepper v-model="num" integer min="1" :max="goodsList.goods_stock" />
        </div>

        <van-button @click="getorder" style="margin: 2vw 0 3vw" block class="anBtn" round type="info"
          color="linear-gradient(270deg, #E83323 0%, #FFA39B 100%)">{{ btnTitle }}</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      id: this.xz_id(),
      images: [],
      selectState: true,
      show: false,
      goods: {
        // 默认商品 sku 缩略图
        picture: "",
      },
      goodsList: {},
      btnTitle: "",
      is_collect: false,
      evaluate: [],

      spec: [], //规格
      saleSpec: [], //规格
      set_spec: [], //前端 选择后的规格
      goods_specs: {}, //前端 选择后的规格对应接口返回的规格
      ids: "", //展示选择后的规格id
      set_saleSpecimg: "", //展示选择后的规img
      num: 1, //数量
    };
  },
  created() {
    this.get_detail();
    //this.get_evaluate();
  },
  methods: {
    onProductDetails() {
      this.selectState = true;
    },
    onEvaluation() {
      this.selectState = false;
    },
    // 购物车
    addCar() {
      this.$router.push("shoppingCart");
    },
    //判断是加入购物车还是立即购买
    onBuy(e) {
      this.show = true;
      if (e == "addCar") {
        console.log(e);
        this.btnTitle = this.$t("加入购物车");
      } else if (e == "buyNow") {
        console.log(e);
        this.btnTitle = this.$t("立即购买");
      }
    },
    // 确认购买生成订单
    onPurchase(e) {
      console.log(e);
      this.$router.push({ name: "confirmOrder", query: { e: e } });
    },
    //商品收藏
    collect() {
      this.is_collect = !this.is_collect;
      if (this.is_collect) {
        this.$post({
          url: "/app/goods/collect",
          data: {
            goods_id: this.id,
            state: 1,
          },
          success: (res) => {
            console.log(res);
            this.$toast({
              message: this.$t("收藏成功"),
              duration: 2000,
            });
          },
          tip: (val) => { },
        });
      } else {
        this.$post({
          url: "/app/goods/collect",
          data: {
            goods_id: this.id,
            state: 2,
          },
          success: (res) => {
            console.log(res);
            this.$toast({
              message: this.$t("取消收藏成功"),
              duration: 2000,
            });
          },
          tip: (val) => { },
        });
      }
    },
    //获取商品详情
    get_detail() {
      this.$get({
        url: "/app/goods/detail",
        data: {
          goods_id: this.id,
        },
        success: (res) => {
          this.goodsList = res.data.goods;
          this.images = res.data.goods.goods_atlas;
          this.goods.picture = res.data.goods.goods_thumb;
          if (res.data.collect_state == 1) {
            this.is_collect = true;
          } else {
            this.is_collect = false;
          }

          res.data.goods_attr.forEach((val) => {
            val.attr_values.forEach((item) => {
              item.check = false;
            });
          });

          this.spec = JSON.parse(JSON.stringify(res.data.goods_attr));
          this.saleSpec = res.data.goods_specs;
        },
      });
    },
    //获取评价列表
    // get_evaluate() {
    //   this.$get({
    //     url: "/app/goods/getCommentList",
    //     data: {
    //       id: this.id,
    //     },
    //     success: (res) => {
    //       this.evaluate = res.data.list;
    //     },
    //   });
    // },
    //属性选择
    specChange(index, keys) {
      this.spec.forEach((val, indexs) => {
        if (index == indexs) {
          val.attr_values.forEach((item, indexkey) => {
            if (keys == indexkey) {
              item.check = true;
              if (item.check) {
                this.set_spec[index] = item;
              }
            } else {
              item.check = false;
            }
          });
        }
      });
      let ids = [];
      let names = [];
      this.set_spec.forEach((val) => {
        ids.push(val.id);
        names.push(val.attr_value);
      });
      ids = ids.join(",");
      this.set_saleSpecname = names.join("-");
      this.saleSpec.forEach((val) => {
        if (val.goods_path === ids) {
          this.goods_specs = val;
        }
      });
    },
    //确认购买
    getorder() {

      this.show = false;
      if (this.btnTitle == this.$t("立即购买")) {
        this.$router.push({
          name: "confirmOrder",
          query: {
            goods_id: this.goodsList.id, //商品id
            specs_id: this.goods_specs.id, //规格id
            amount: this.num, //数量id
            is_seckill: this.goodsList.is_seckill, //是1为抢购商品,为了区分普通商品
          },
        });
      } else if (this.btnTitle == this.$t("加入购物车")) {
        this.$post({
          url: "/app/goods_cart/add",
          data: {
            goods_id: this.goodsList.id, //商品id
            specs_id: this.goods_specs.id, //规格id
            amount: this.num, //数量id
          },
          success: (res) => {
            console.log(res);
            this.$toast({
              message: this.$t("加入成功"),
              duration: 2000,
            });
          },
          tip: (val) => { },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.grey {
  color: #999;
}

.orange {
  color: #f13710;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.padding04 {
  padding: 0 4vw;
}

.marginTop2 {
  margin-top: 2vw;
}

.marginLett2 {
  margin-left: 2vw;
}

.productDetailsOrEvaluation {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 7vh 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    padding-top: 6.7vh;
    min-height: 86.7vh;
    height: auto;

    /deep/.van-swipe-item {
      img {
        width: 100%;
        height: 110vw;
      }
    }

    >h3 {
      margin-top: 2vw;
    }

    .priceNum {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        .originalPrice {
          margin-left: 3vw;
          text-decoration: line-through;
        }
      }
    }

    .splitLine {
      margin-top: 2vw;
      width: 100%;
      height: 3vw;
      background-color: #f9f9f9;
    }

    .btn {
      display: flex;
      justify-content: center;

      /deep/.van-button {
        width: 50%;
        height: 13vw;
        border-radius: 0vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
        font-weight: bold;

        .van-button__text {
          color: #666;
        }
      }

      .selectBtn {
        /deep/ .van-button__text {
          color: #f13b10;
        }
      }
    }

    .productDetails {
      .imgList {
        margin: 0 4vw !important;

        /deep/span,
        /deep/p {
          font-size: 4vw !important;
          color: #333 !important;
          line-height: normal !important;
          text-indent: inherit !important;
        }

        /deep/img {
          width: 100%;
          object-fit: cover;
        }

        // /deep/table {
        //   max-width: 100%;
        //   overflow-x: auto;
        //   tbody {
        //     max-width: 100%;
        //   }
        //   tr {
        //     max-width: 100%;
        //   }
        //   td {
        //     max-width: 100%;
        //   }
        // }
      }
    }

    .evaluation {
      border-top: 1px solid #e8e8e8;

      .evaluationList {
        display: flex;
        flex-direction: column;
        padding: 4vw 0;
        border-bottom: 3vw solid #f9f9f9;
        height: auto;

        .use {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .imgNane {
            display: flex;
            align-items: center;

            >p {
              margin-left: 2vw;
            }
          }
        }

        .imgList {
          display: flex;
          flex-wrap: wrap;
        }

        .replyInformation {
          .productInformation {
            padding: 3vw;
            width: 92vw;
            background-color: #f8f8f8;
            border-radius: 2vw;

            .imgTite {
              display: flex;
              justify-content: space-between;
              padding: 3vw 0;

              .tite {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 67vw;

                .priceNum {
                  display: flex;
                  justify-content: space-between;
                  color: #f13710;
                }
              }
            }
          }
        }
      }
    }
  }

  //height: 7vh;
  border-top: 1px solid #e8e8e8;

  // 商品导航 开始
  .van-goods-action {
    height: 7vh;

    /deep/.van-goods-action-icon {
      width: 19vw;
    }

    .van-goods-action-button--warning {
      background-color: #f1ac10;
      background: -webkit-linear-gradient(left, #f1ac10, #f1ac10);
      background: linear-gradient(to right, #f1ac10, #f1ac10);
    }

    .van-goods-action-button--danger {
      background-color: #f13b10;
      background: -webkit-linear-gradient(left, #f13b10, #f13b10);
      background: linear-gradient(to right, #f13b10, #f13b10);
    }

    .van-goods-action-button--first,
    .van-goods-action-button--last {
      margin: 0;
      border-radius: 0;
    }

    .van-goods-action-button {
      height: 7vh;
      width: 31vw;
      font-size: 4vw;
    }
  }

  // 商品导航 结束
}

.popupbox {
  width: 100%;
  padding: 4vw 3vw 2vw;

  /deep/.van-button {
    height: 6vh;
    background-color: #f13b10;
    background: -webkit-linear-gradient(left, #f13b10, #f13b10) !important;
    background: linear-gradient(to right, #f13b10, #f13b10) !important;
  }

  .listbox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    img {
      width: 25vw;
      height: 25vw;
      object-fit: cover;
    }

    .left {
      flex: 1;
      height: 25vw;
      padding: 0 2vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      font-size: 3.6vw;
      color: #999999;
      font-weight: bold;

      span {
        font-size: 5vw !important;
        color: #e83323;
        font-weight: bold;
        margin-right: 3vw;
      }

      .tit {
        font-weight: 500;
        text-decoration: line-through;
      }
    }

    margin-bottom: 2vw;
  }

  .listtit {
    width: 100%;
    color: #332311;
    font-size: 4vw;
    padding: 2vw 0;
    line-height: 2;
    font-weight: 600;
  }

  .numsbox {
    width: 100%;
    padding: 2vw 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    p {
      color: #999999;
      font-size: 3.5vwv;
      background: #ffffff;
      border: 1px solid #ededed;
      border-radius: 4vw;
      padding: 0 5vw;
      margin: 0 2vw 2vw 0;
    }

    .show {
      background: #f9f9f9;
      border: 1px solid #f13b10;
      color: #f13b10;
    }
  }

  .stepp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /deep/.van-stepper {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
