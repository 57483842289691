// zh
const is_tabbar = {
    data() {
        return {
            tabbarShow: true,
        }
    },
    methods: {
        // 显示底部导航
        onTrue() {
            this.tabbarShow = true;
            console.log("显示")
        },
        // 隐藏底部导航
        onFalse() {
            this.tabbarShow = false;
            console.log("隐藏")
        }
    }
}

export default is_tabbar;