<template>
  <div class="loginbox">
    <van-nav-bar :title="$t('注册')" @click-left="$router.go(-1)" left-arrow :border="false" />
    <div class="logobox">
      <img :src="logoImg" alt="" />
      <!-- <div>ヤフージャパンです</div> -->
    </div>
    <div class="frombox">
      <div class="inputTitle">{{ $t('姓名') }}</div>
      <van-field v-model="reg_name" :placeholder="$t('请输入姓名')" />

      <div class="inputTitle">{{ $t('用户名') }}</div>
      <van-field v-model="user_name" :placeholder="$t('请输入用户名')" />
      <!-- <div class="inputTitle">验证码</div>
      <van-field v-model="sms_code" placeholder="请输入验证码(初始随便输入)" /> -->
      <!-- <van-field clearable v-model="tell" type="tel" maxlength="11" label="手机号" placeholder="请输入您的手机号" :border='false' /> -->
      <!-- <van-field v-model="code" type="text" maxlength="11" clearable placeholder="请输入验证码" :border='false'>
        <template #button>
          <van-button round size="small" type="primary" color="#0057FF" v-if="is_time == 1" @click="sendcode" class="anBtn">发送验证码</van-button>
          <van-button round size="small" type="primary" color="#0057FF" v-if="is_time == 2" disabled class="anBtn">{{ time }}S后获取</van-button>
        </template>
</van-field> -->
      <div class="inputTitle">{{ $t('登录密码') }}</div>
      <van-field clearable v-model="password" :placeholder="$t('请设置6-12位登录密码')"
        :type="loginPwEyeShow ? 'password' : 'text'" :right-icon="loginPwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onPwEyeEyeIcon(1)" />
      <div class="inputTitle">{{ $t('确认登录密码') }}</div>
      <van-field clearable v-model="confirm_password" :placeholder="$t('请输入确认登录密码')"
        :type="confirmLoginPwEyeShow ? 'password' : 'text'" :right-icon="confirmLoginPwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onPwEyeEyeIcon(2)" />
      <div class="inputTitle">{{ $t('交易密码') }}</div>
      <van-field clearable maxlength="6" v-model="trade_password" :placeholder="$t('请输入交易密码')"
        :type="tradePwEyeShow ? 'password' : 'text'" :right-icon="tradePwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onPwEyeEyeIcon(3)" />
      <div class="inputTitle">{{ $t('确认交易密码') }}</div>
      <van-field clearable maxlength="6" v-model="confirm_trade_password" :placeholder="$t('请输入确认交易密码')"
        :type="confirmTradePwEyeShow ? 'password' : 'text'" :right-icon="confirmTradePwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onPwEyeEyeIcon(4)" />
      <div class="btnbox">
        <van-button type="primary" color="#F13B10" block @click="registBtn">{{ $t('注册') }}</van-button>
      </div>
      <div class="service" @click="goContact">{{ $t('在线客服') }}</div>
      <div class="tit2">
        {{ $t('登录即代表阅读并同意') }}<span @click="xz_go('agreement')" style="color: cornflowerblue">{{ $t('注册协议')
          }}</span>{{ $t('和') }}<span @click="xz_go('agree')" style="color: cornflowerblue">{{ $t('隐私协议') }}</span>
        <!-- 登录即代表阅读并同意服务条款  -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      // 公司logo
      logoImg: "",
      reg_name: "",
      companyName: "",

      webSiteInfo: "",
      user_name: "",
      sms_code: "",
      password: "",
      confirm_password: "",
      trade_password: "",
      confirm_trade_password: "",
      logo: "",
      replacecdoe: "",
      disabled: false,
      time: 60,
      is_time: 1,
      downpath: "",
      checked: true,
      loginPwEyeShow: true,
      confirmLoginPwEyeShow: true,
      tradePwEyeShow: true,
      confirmTradePwEyeShow: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取公司logo
    get_companyLogo() {
      this.$get({
        url: "/app/extend/getCompay",
        success: (res) => {
          this.logoImg = res.data.logo;
          this.companyName = res.data.title;
        },
        tip: () => { },
      });
    },
    // 获取站点信息
    getWebSiteInfo() {
      this.$get({
        url: "/app/extend/getWebSiteInfo",
        success: (res) => {
          this.webSiteInfo = res.data;
        },
        tip: () => { },
      });
    },
    // 去联系
    goContact() {
      // window.location.href = this.webSiteInfo.mobile
      this.$router.push({
        name: "ChatWindow",
        query: {
          src: this.webSiteInfo.mobile,
        },
      });
    },
    //判断密码是否可见
    onPwEyeEyeIcon(edit) {
      if (edit == 1) {
        this.loginPwEyeShow = !this.loginPwEyeShow;
      } else if (edit == 2) {
        this.confirmLoginPwEyeShow = !this.confirmLoginPwEyeShow;
      } else if (edit == 3) {
        this.tradePwEyeShow = !this.tradePwEyeShow;
      } else {
        this.confirmTradePwEyeShow = !this.confirmTradePwEyeShow;
      }
    },

    registBtn() {

      if (!this.reg_name) return this.$toast(this.$t("请输入姓名"));
      if (!this.user_name) return this.$toast(this.$t("请输入用户名"));
      // if (!this.sms_code) return this.$toast("请输入验证码");
      if (!this.password) return this.$toast(this.$t("请输入登录密码"));
      if (!this.confirm_password) return this.$toast(this.$t("请输入确认登录密码"));
      if (this.password !== this.confirm_password)
        return this.$toast(this.$t("两次输入的登录密码不一致"));
      if (!this.trade_password) return this.$toast(this.$t("请输入交易密码"));
      if (!this.confirm_trade_password)
        return this.$toast(this.$t("请输入确认交易密码"));
      if (this.trade_password !== this.confirm_trade_password)
        return this.$toast(this.$t("两次输入的登录密码不一致"));

      this.$post({
        url: "/app/auth/register",
        noToken: true,
        data: {
          reg_name: this.reg_name,
          user_name: this.user_name,
          // sms_code: this.sms_code,
          password: this.password,
          confirm_password: this.confirm_password,
          trade_password: this.trade_password,
          confirm_trade_password: this.confirm_trade_password,
        },
        success: (res) => {
          this.$toast.clear();
          this.$toast(this.$t("注册成功"));
          localStorage.setItem("token", res.data.token);
          setTimeout(() => {
            this.$router.replace({
              name: "/",
            });
          }, 500);
        },
        tip: () => { },
      });
    },
    // getlogo() {
    //   this.$get({
    //     url: "/app/entry/getEntry",
    //     success: (res) => {
    //       this.$toast.clear();
    //       this.logo = res.data.entry_logo;
    //     },
    //     tip: () => {},
    //   });
    // },
  },
  created() {
    this.get_companyLogo();
    this.getWebSiteInfo();
    // this.getlogo();
    // if (this.$route.query.code) {
    //   this.replacecdoe = this.$route.query.code;
    //   this.disabled = true;
    // }
  },
  mounted() { },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  height: auto;

  /deep/.van-nav-bar {
    background-color: #fff;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
      font-size: 4vw;
    }
  }

  .logobox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >img {
      width: 10vh;
      //height: 25vw;
      border-radius: 3vw;
      object-fit: cover;
    }

    >div {
      font-size: 4vw;
      font-weight: 600;
    }
  }

  /deep/.van-nav-bar {
    background: none;

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  .frombox {
    width: 100%;
    padding: 0 4vw;

    .inputTitle {
      margin-top: 2vw;
      padding: 0vw 4vw;
      font-size: 4vw;
      font-weight: 700;
      color: #5b5e63;
    }

    /deep/.van-icon {
      font-size: 6vw;
    }

    /deep/.van-cell {
      margin-bottom: 0.1vh;
      padding: 0.5vh 4vw;
      border-radius: 8vw;
      font-size: 3vw;

      &:after {
        border-bottom: 1.3vw solid #ebedf0;
      }

      .van-field__label {
        text-align: center;
      }
    }

    /deep/.van-field {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .btnbox {
      width: 76vw;
      height: 14vw;
      margin: 1vh auto 0;

      /deep/.van-button {
        width: 100%;
        height: 100%;
      }

      /deep/.van-button--normal {
        font-size: 4vw;
      }
    }

    .tit {
      width: 100%;
      margin-top: 2.4vw;
      padding: 1vw 4vw;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      >a {
        color: #969799;
        font-size: 3vw;
      }
    }

    .service {
      margin-top: 2vw;
      display: flex;
      justify-content: center;
      color: #969799;
      font-size: 3vw;
    }

    .tit2 {
      width: 100%;
      text-align: center;
      font-size: 3.5vw;
      font-weight: 400;
      color: #8c9198;
      margin-top: 1vw;
    }
  }
}
</style>
