<template>
  <!-- 登录 -->
  <div class="loginbox">
    <van-nav-bar :title="$t('登录')" :border="false" />
    <div class="logobox">
      <img :src="logoImg" alt="" />
      <div>{{ companyName }}</div>
    </div>
    <div class="frombox">
      <div class="inputTitle">{{ $t('用户名') }}</div>
      <van-field v-model="user_name" :placeholder="$t('请输入用户名')" clearable />
      <!-- <van-field v-model="tell" maxlength="11" type="tel" placeholder="请输入您的手机号" border /> -->
      <div class="inputTitle">{{ $t('密码') }}</div>
      <van-field clearable v-model="password" :placeholder="$t('请输入您的登录密码')" :border="true"
        :type="pwEyeShow ? 'password' : 'text'" :right-icon="pwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onEyeIcon" />
      <div class="pass">
        <router-link to="register">{{ $t('立即注册') }}</router-link>
        <!-- <router-link to="password">忘记密码?</router-link> -->
        <div @click="goContact">
          <div>{{ $t('忘记密码') }}</div>
        </div>
      </div>
      <van-button type="primary" color="#F13B10 " block @click="loginBtn">{{ $t('登录') }}</van-button>
      <div class="service" @click="goContact">{{ $t('在线客服') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      // 公司logo
      companyName: "",
      logoImg: "",
      user_name: "",
      password: "",
      pwEyeShow: true,
      webSiteInfo: "",
    }
  },
  computed: {},
  watch: {},
  methods: {
    onEyeIcon() {
      this.pwEyeShow = !this.pwEyeShow
    },
    // 获取公司logo
    get_companyLogo() {
      this.$get({
        url: "/app/extend/getCompay",
        success: (res) => {
          this.logoImg = res.data.logo;
          this.companyName = res.data.title;
        },
        tip: () => { }
      });
    },
    // 登录
    loginBtn() {
      if (!this.user_name) return this.$toast(this.$t("请输入用户名"))
      if (!this.password) return this.$toast(this.$t("请输入登录密码"))
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
        duration: 0,
      })
      this.$post({
        url: "/app/auth/login",
        noToken: true,
        data: {
          user_name: this.user_name,
          password: this.password,
        },
        success: (res) => {
          this.$toast.clear()
          localStorage.setItem("token", res.data.token)
          this.$toast(this.$t("登录成功"))
          setTimeout(() => {
            this.$router.replace({
              name: "/",
            })
          }, 500)
        },
        tip: () => { },
      })
    },
    // 去联系（忘记密码）
    goContact() {
      // window.location.href = this.webSiteInfo.mobile
      this.$router.push({
        name: "ChatWindow",
        query: {
          src: this.webSiteInfo.mobile,
        },
      })
    },
    // 获取站点信息
    getWebSiteInfo() {
      this.$get({
        url: "/app/extend/getWebSiteInfo",
        success: (res) => {
          this.webSiteInfo = res.data
        },
        tip: () => { },
      })
    },
    // getlogo() {
    //   this.$get({
    //     url: "/app/entry/getEntry",
    //     success: (res) => {
    //       this.$toast.clear();
    //       this.logo = res.data.entry_logo;
    //     },
    //     tip: () => {},
    //   });
    // },
  },
  created() {
    // this.getlogo();
    this.get_companyLogo()
    this.getWebSiteInfo()
  },
  mounted() { },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0vw 4vw;

  /deep/.van-nav-bar {
    background-color: #fff;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
      font-size: 4vw;
    }
  }

  .logobox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;

    >img {
      width: 30vw;
      //height: 30vw;
      object-fit: cover;
      border-radius: 3vw;
    }

    >div {
      font-size: 4vw;
      font-weight: 600;
    }
  }

  /deep/.van-nav-bar__title {
    font-size: 4.5vw;
    font-weight: bold;
  }

  .frombox {
    width: 100%;
    padding: 0.83vw 4vw 2vw;

    .inputTitle {
      margin: 8vw 0 1vw;
      padding: 0vw 4vw;
      font-size: 4vw;
      font-weight: 700;
      color: #5b5e63;
    }

    /deep/.van-icon {
      font-size: 6vw;
    }

    /deep/.van-cell {
      margin-bottom: 4vw;
      padding: 1vw 4vw;
      border-radius: 8vw;
      font-size: 3vw;

      // background: rgba(255, 255, 255, 0.4);
      &:after {
        border-bottom: 1.3vw solid #ebedf0;
      }

      .van-field__label {
        text-align: center;
      }
    }

    /deep/.van-button {
      margin-top: 8vw;
      width: 75.67vw;
      height: 14.93vw;
    }

    /deep/.van-button--normal {
      padding: 0 13px;
      margin: 7vw auto 0;
      font-size: 4vw;
    }

    .pass {
      width: 100%;
      padding: 1vw 4vw;
      display: flex;
      justify-content: space-between;

      >a,
      div>a {
        color: #969799;
        font-size: 3vw;
      }
    }

    .tit {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4vh;

      >a {
        color: #fff;
        font-size: 4vw;
      }
    }

    .service {
      margin-top: 3vw;
      display: flex;
      justify-content: center;
      color: #969799;
      font-size: 3vw;
    }
  }
}
</style>
