var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productList"},[_c('div',{staticClass:"header_box"},[_c('van-search',{attrs:{"shape":"round","background":"#F13B10","placeholder":_vm.$t('请输入搜索关键词')},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.onSearch(_vm.searchVal)}}},[_c('van-icon',{attrs:{"name":"search","size":"18"}})],1)]},proxy:true},{key:"left",fn:function(){return [_c('div',{staticStyle:{"margin-right":"2vw"},on:{"click":_vm.xz_back}},[_c('van-icon',{attrs:{"name":"arrow-left","color":"#FFFFFF"}})],1)]},proxy:true}]),model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('div',{staticClass:"sort"},[_c('div',{staticClass:"comprehensive",class:_vm.selectStyle == 1 ? 'cur' : '',on:{"click":function($event){return _vm.changeTab(1)}}},[_vm._v(" "+_vm._s(_vm.$t('综合'))+" ")]),_c('div',{staticClass:"price",class:_vm.selectStyle == 2 ? 'cur' : '',on:{"click":function($event){return _vm.changeTab(2)}}},[_vm._v(" "+_vm._s(_vm.$t('价格'))+" "),_c('img',{staticStyle:{"width":"'1vw'","height":"2.5vw"},attrs:{"src":_vm.getSortPriceUrl,"alt":""}})]),_c('div',{staticClass:"salesVolume",class:_vm.selectStyle == 3 ? 'cur' : '',on:{"click":function($event){return _vm.changeTab(3)}}},[_vm._v(" "+_vm._s(_vm.$t('销量'))+" "),_c('img',{staticStyle:{"width":"'1vw'","height":"2.5vw"},attrs:{"src":_vm.getSortSalesUrl,"alt":""}})])])],1),_c('div',{staticClass:"container"},[_c('vant-up-down',{ref:"child",attrs:{"res":"res.data.list","url":"/app/goods/list","data":{
      search: _vm.searchVal,
      cat_id: 0,
      brand_id: _vm.id,
      is_recommend: 0,
      is_seckill: 2,
      sort_price: _vm.sort_price,
      sort_sales: _vm.sort_sales,
    }},on:{"list":(val) => (_vm.productList = val)}},[_c('div',{staticClass:"content"},_vm._l((_vm.productList),function(ele,index){return _c('div',{key:index,staticClass:"productInformation",on:{"click":function($event){return _vm.xz_go('productDetailsOrEvaluation', ele.id)}}},[_c('img',{staticStyle:{"width":"44vw","height":"49vw","border-radius":"2vw","overflow":"hidden"},attrs:{"src":ele.goods_thumb,"alt":""}}),_c('h3',[_vm._v(_vm._s(ele.goods_name))]),_c('span',{staticClass:"price_quantitySold"},[_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.$t('元'))+_vm._s(ele.discount_price))]),_c('div',{staticClass:"quantitySold"},[_vm._v(_vm._s(ele.goods_sales)+_vm._s(_vm.$t('人'))+_vm._s(_vm.$t('付款')))])])])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }