<template>
  <!-- 转卖记录 -->
  <div class="resaleCenter">
    <div class="header_box whiteBc">
      <van-nav-bar :title="$t('转卖中心')" left-arrow @click-left="xz_back" :border="false" />
      <div class="btn">
        <div class="recovery font4" :class="selectState == 1 ? 'selectBtn' : ''" @click="onRecovery(1)">
          {{ $t('待转卖') }}
        </div>
        <div class="recovery font4" :class="selectState == 2 ? 'selectBtn' : ''" @click="onRecovery(2)">
          {{ $t('已转卖') }}
        </div>
        <div class="recovery font4" :class="selectState == 3 ? 'selectBtn' : ''" @click="onRecovery(3)">
          {{ $t('已失败') }}
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="content greyBc">
      <vant-up-down @list="(val) => (list = val)" res="res.data.list" url="/app/order_transfer/transferList"
        :data="{ state: selectState }" ref="child">
        <div class="container" v-show="!list.length == 0">
          <div class="resultBox whiteBc" v-for="(item, index) in list" :key="index">
            <div class="top">
              <img src="../../../assets/img/incomeCalculation_coin.png" alt="" style="
                  width: 14vw;
                  height: 14vw;
                  border-radius: 2vw;
                  overflow: hidden;
                  margin-top: 4vw;
                  margin-right: 2vw;
                " />
              <div class="resultInformation marginTop3">
                <div>
                  <div class="font3 fontBold nowrap"> {{ $t('订单编号') }}:</div>
                  <div class="jcsb">
                    <span class="font3 fontBold nowrap">
                      {{ item.order_sn }}
                    </span>
                    <span class="btnCody" v-clipboard:copy="item.order_sn" v-clipboard:success="onCopy"
                      v-clipboard:error="onError">{{ $t('复制') }}</span>
                  </div>
                </div>
                <div class="resultInformation">
                  <span class="grey font3">{{ $t('商品原价') }}： {{ $t('元') }}{{ item.original_price }}</span>
                  <span class="grey font3">{{ $t('转卖金额') }}： {{ $t('元') }}{{ item.transfer_price }}</span>
                  <span class="grey font3">{{ $t('订单金额') }}： {{ $t('元') }}{{ item.order_price }}</span>
                </div>
              </div>
            </div>
            <div class="revenueResults">
              <div :class="selectState != 1 ? 'seleceStyle' : ''">
                <span class="font3 nowrap remarks" v-show="selectState != 1">{{
                  item.remarks
                }}</span>
                <span class="orange font3 nowrap">{{ $t('利润') }}： {{ $t('元') }}{{ item.transfer_profit }}</span>
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      selectState: 1,
      order_sn: "",
      list: [],
      state: 1,
    };
  },
  created() { },
  methods: {
    onRecovery(e) {
      this.selectState = e;
      setTimeout(() => {
        this.$refs.child.list_init();
      }, 0);
      console.log(this.selectState);
    },
    // 复制成功
    onCopy(e) {
      window.sessionStorage.setItem("order_sn", e.text);
      this.$toast("复制成功");
    },
    // 复制失败
    onError() {
      this.$toast("复制失败");
    },
  },
};
</script>

<style scoped lang="scss">
.btnCody {
  padding: 0 1vw;
  border-radius: 1vw;
  color: #fff;
  background-color: #f13710;
}

.jcsb {
  display: flex;
  justify-content: space-between;
}

.nowrap {
  white-space: nowrap;
}

.grey {
  color: #999;
}

.greyBc {
  background-color: #f9f9f9;
}

.orange {
  color: #f13710;
}

.white {
  color: #fff;
}

.whiteBc {
  background-color: #fff;
}

.font3 {
  font-size: 3vw;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop3 {
  margin-top: 3vw;
}

.padding04 {
  padding: 0 4vw;
}

.resaleCenter {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: calc(6.3vh + 8vh);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  .btn {
    position: relative;
    margin: 1vh auto;
    display: flex;
    width: 80vw;
    height: 6vh;
    border-radius: 11vw;
    background-color: #fff;
    border: 1px solid #bdc4ce;

    .recovery {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33%;
      height: 100%;
      color: #bdc4ce;
    }

    .selectBtn {
      color: #f13b10;
    }

    // .line {
    //   position: absolute;
    //   top: 4vw;
    //   left: 49.5%;
    //   width: 1px;
    //   height: 3vw;
    //   background-color: #bdc4ce;
    // }
  }

  // 内容
  .content {
    min-height: 85.7vh;
    height: auto;

    .container {
      min-height: 85.7vh;
    }

    .resultBox {
      display: flex;
      flex-direction: column;
      padding: 0 4vw;
      width: 100vw;
      height: auto;
      border-radius: 0vw;
      border-bottom: 3vw solid #f9f9f9;

      .top {
        display: flex;
        margin-bottom: 2vw;
      }

      .resultInformation {
        display: flex;
        flex-direction: column;
        color: #000;
        width: 76vw;
      }

      .revenueResults {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 92vw;
        min-height: 10vw;
        border-top: 1px solid #e8e8e8;

        >div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 76vw;
          height: 100%;
        }

        .seleceStyle {
          justify-content: space-between;
        }

        .remarks {
          max-width: 50vw;
          display: block;


          word-wrap: break-word;
          white-space: normal;
          word-break: normal;
        }
      }
    }
  }
}
</style>
