<template>
  <!-- 资讯中心 -->
  <div class="informationList">
    <div class="header_box">
      <van-nav-bar :title="$t('资讯中心')" left-arrow @click-left="$router.replace('/')" :border="false" />
    </div>

    <vant-up-down @list="(val) => (informationList = val)" res="res.data.list" url="/app/information/list" data="">
      <div class="container">
        <div class="content" v-for="(item, index) in informationList" :key="index"
          @click="xz_go('informationDetails', item.id)">
          <img :src="item.cover_image" alt="" style="
              width: 92vw;
              height: 43vw;
              border-radius: 2vw;
              overflow: hidden;
              object-fit: cover;
              margin: 0 4vw;
            " />
          <div class="titeDate">
            <h2 class="two">{{ item.title }}</h2>
            <div class="date">{{ item.create_time }}</div>
          </div>
          <span>{{ item.introduction }}</span>
        </div>
      </div>
    </vant-up-down>
  </div>
</template>

<script>
export default {
  name: "informationList",
  props: {},
  data() {
    return {
      informationList: [],
    };
  },
  created() { },
  methods: {},
};
</script>

<style scoped lang="scss">
.informationList {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 6.7vh 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  .container {
    min-height: $conter2;
    padding: 0vw 0vw 4vw;
    height: auto;
  }

  .content {
    padding-top: 4vw;
    padding-bottom: 4vw;
    border-bottom: 3vw solid #f2f2f2;

    .titeDate {
      // display: flex;
      // justify-content: space-between;
      // align-content: center;
      margin: 4vw 4vw 0;

      .date {
        font-size: 3vw;
        color: #999999;
      }
    }

    >span {
      display: flex;
      margin: 2vw 4vw 0;
      font-size: 4vw;
      font-weight: 500;
      color: #333;
      // 最多显示两行
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
