<template>
  <!-- 本月收益 -->
  <div class="currentMonthIncome">
    <div class="header_box">
      <van-nav-bar :title="$t('本月收益')" left-arrow @click-left="$router.replace('mine')" :border="false" />
      <div class="selectDate">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="monthList" @change="getIncomeData(value1)" />
        </van-dropdown-menu>
      </div>
    </div>

    <div class="content">
      <van-pull-refresh v-model="Down" :success-text="$t('刷新成功')" @refresh="get_down">
        <van-list v-model="up" :finished="over" :finished-text="finished_text" @load="get_up" :immediate-check="false">
          <div class="allList">
            <div class="date font4">{{ $t('收益') }}：{{ profit }}</div>
            <div class="recordList" v-for="(item, index2) in list" :key="index2">
              <div class="timeState">
                <p class="state font4" v-show="item.env !== 4">{{ item.remarks }}</p>
                <p class="state font4" v-show="item.env == 4">
                  {{ $t('转卖订单号') }}：{{ item.order_sn }},{{ $t('由') }}{{ item.remarks }}{{ $t('进行转卖') }}
                </p>
                <p class="time grey">{{ item.create_time }}</p>
              </div>
              <div class="rightbox font4 fontBold getNum">{{ item.num }}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      // 默认展示月份
      value1: 0,
      monthList: [
        // { text: "本月", value: 0 },
        // { text: "2018-03", value: 1 },
      ],
      now_time: 0,
      profit: "", //收益
      list: {},
      currentPage: 1,

      finished_text: "",
      page: 1,
      loading: false,
      Down: false,
      up: false,
      over: false,
    };
  },
  created() { },
  mounted() {
    this.getMonthBetween();
    this.getIncomeData();
  },
  methods: {
    // 获取收益数据
    getIncomeData(e) {
      this.page = 1;
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      let seleceYearMonth = "";
      if (!e) {
        let date = new Date();
        let curMonth = date.getMonth() + 1;
        let curYear = date.getFullYear();
        seleceYearMonth = curYear + "-" + curMonth;
      } else {
        this.value1 = e;
        seleceYearMonth = String(e).substr(0, 4) + "-" + String(e).substr(4, 2);
      }
      this.now_time = seleceYearMonth;
      setTimeout(() => {
        this.list_init();
        this.$toast.clear();
      }, 0);
    },
    //------[获取3年间所有的月份中]
    getMonthBetween() {
      let currentList = [];
      let date = new Date();
      let curMonth = date.getMonth() + 1;
      let curYear = date.getFullYear();
      // 当前年份
      let curYearMonth = curYear + "-" + curMonth;
      let oldYear = curYear - 3;
      // 后3年份
      let oldYearMonth = oldYear + "-" + curMonth;
      //拆分
      var s = oldYearMonth.split("-");
      var e = curYearMonth.split("-");
      var min = new Date();
      var max = new Date();
      //开始日期
      min.setFullYear(s[0], s[1] * 1 - 1, 1);
      max.setFullYear(e[0], e[1] * 1 - 1, 1);
      //结束日期
      console.log(e[0] + "---- " + e[1]);
      var curr = min;
      while (curr <= max) {
        var month = curr.getMonth();
        //month = month == 0 ? 12 : month;
        //console.log(month);
        var str = curr.getFullYear() + "-" + (month + 1);
        if (str == curYearMonth) {
          if (str.length < 7) {
            curYearMonth = curYearMonth.replace("-", "-0");
          }
          str = { text: this.$t("本月"), value: Number(curYearMonth.replace("-", "")) };
          this.value1 = str.value;
        } else {
          if (str.length < 7) {
            str = str.replace("-", "-0");
          }
          str = { text: str, value: Number(str.replace("-", "")) };
        }
        currentList.push(str);
        curr.setMonth(month + 1);
      }
      this.monthList = currentList.reverse();
    },

    //初始化数据
    list_init() {

      this.page = 1;
      this.over = false;
      this.get_list("down"); // 加载数据
      console.log(this.list);
    },
    //下拉刷新 下拉的这个 调了getlistla
    get_down() {

      setTimeout(() => {
        this.page = 1;
        this.over = false;
        this.get_list("down"); // 加载数据
      }, 1000);
    },
    // 上拉加载 上拉这个 调用getlist
    get_up() {

      this.page++;
      console.log(this.page)
      this.get_list("up"); // 加载数据
    },
    // 更多广告
    get_list(type) {
      // let data = Object.assign({ page: this.page }, this.data);
      this.$get({
        url: "/app/asset/getBalanceProfitList",
        data: {
          page: this.page,
          month: this.now_time,
        },
        success: (res) => {
          this.profit = res.data.profit;
          let data = res.data.list;

          if (type == "down") {
            this.list = data;
          } else if (type == "up") {
            if (this.list) {
              this.list = [...this.list, ...data];
            }
            if (data.length == 0) {
              this.over = true;
            }
          }
          this.Down = false;
          this.up = false;
        },
        tip: (val) => {
          this.over = true;
        },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.grey {
  color: #999;
}

.currentMonthIncome {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 4vw 0;
  padding-top: calc(6.3vh + 6.5vh);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 下拉
  .selectDate {
    width: 100vw;
    height: 6.5vh;
    background-color: #f5f5f5;

    /deep/.van-dropdown-menu__bar {
      background-color: #f5f5f5;
      height: 6.5vh;
      box-shadow: none;

      .van-dropdown-menu__title {
        font-size: 4vw;
        color: #333333;

        &::after {
          border-color: transparent transparent #333333 #333333;
        }
      }
    }
  }

  // 内容
  .content {
    min-height: calc(100vh - 6.3vh + 6.5vh);
    height: auto;

    .allList {
      padding: 4vw 0vw 0;
      height: auto;

      .date {
        margin: 0 4vw;
        font-weight: bold;
      }

      .recordList {
        padding: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;

        .timeState {
          max-width: 70%;
          padding-right: 2vw;
        }

        .rightbox {
          flex: 1;
          text-align: right;
        }

        .cashOutNum {
          color: #14ae95;
        }

        .getNum {
          color: #f13b10;
        }
      }
    }
  }

  // 分页
  .pagination {
    height: 5.4vh;

    /deep/.van-pagination__item,
    .van-pagination__page-desc {
      height: 5.4vh;
    }
  }
}
</style>