<template>
  <!-- 我的收藏 -->
  <div class="myCollection">
    <div class="header_box">
      <van-nav-bar :title="$t('我的收藏')" left-arrow @click-left="$router.replace('mine')" :border="false" />
    </div>
    <div class="content">
      <vant-up-down ref="child" @list="(val) => (list = val)" res="res.data.list" url="/app/goods/getCollectList"
        data="">
        <div class="productInformation">
          <div class="imgTite" v-for="(item, index) in list" :key="index">
            <img @click="xz_go('productDetailsOrEvaluation', item.goods_id)" :src="item.goods_thumb" alt="" style="
                width: 23vw;
                height: 23vw;
                border-radius: 2vw;
                overflow: hidden;
                margin-right: 3vw;
              " />
            <div class="tite">
              <h3>{{ item.goods_name }}</h3>
              <div class="priceNum">
                <div class="orange fontBold font4">
                  {{ $t('元') }}{{ item.goods_price }}
                </div>
                <div class="btn">
                  <van-button type="primary" color="#F13B10" @click="cancel(item.goods_id)">
                    {{ $t('取消收藏') }}
                  </van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>
  </div>
</template>

<script>
import vantUpDown from "../../../vant-extend/vant-up-down/vant-up-down.vue";
export default {
  name: "",
  components: { vantUpDown },
  props: {},
  data() {
    return {
      list: [],
    };
  },
  created() { },
  methods: {
    //取消收藏
    cancel(id) {
      this.$post({
        url: "/app/goods/collect",
        data: {
          goods_id: id,
          state: 2,
        },
        success: (res) => {
          console.log(res);
          setTimeout(() => {
            this.$refs.child.list_init();
          }, 1000);
          this.$toast({
            message: this.$t("取消成功"),
            duration: 2000,
          });
        },
        tip: (val) => { },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.orange {
  color: #f13710;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop4 {
  margin-top: 4vw;
}

.padding04 {
  padding: 0 4vw;
}

.myCollection {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .productInformation {
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      .imgTite {
        display: flex;
        justify-content: space-between;
        padding: 4vw 0;

        .tite {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 66vw;

          .priceNum {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .btn {
              display: flex;
              justify-content: center;

              /deep/.van-button {
                width: 23vw;
                height: 8vw;
                border-radius: 8vw;
              }

              /deep/.van-button--normal {
                padding: 0;
                font-size: 3vw;
              }
            }
          }
        }
      }
    }
  }
}
</style>
