<template>
  <!-- 资讯详情 -->
  <div class="informationDetails">
    <div class="header_box">
      <van-nav-bar :title="$t('资讯详情')" left-arrow @click-left="$router.replace('informationList')" :border="false" />
    </div>

    <div class="content">
      <h2>{{ infoDetail.title }}</h2>
      <div class="date">{{ infoDetail.create_time }}</div>
      <img :src="infoDetail.cover_image" alt="" style="
          width: 92vw;
          height: 43vw;
          border-radius: 2vw;
          overflow: hidden;
          object-fit: cover;
          margin: 2vw 4vw 0;
        " />
      <div class="briefIntroduction">
        <div></div>
        <span>{{ infoDetail.introduction }}</span>
      </div>
      <div class="splitLine"></div>
      <div class="textContent" v-html="infoDetail.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "informationDetails",
  props: {},
  data() {
    return {
      id: this.xz_id(),
      infoDetail: {
        title: "",
      },
    };
  },
  created() {
    this.get_infoDetail();
  },
  methods: {
    get_infoDetail() {
      this.$get({
        url: "/app/information/detail",
        data: { id: this.id },
        success: (res) => {
          this.infoDetail = res.data;
        },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.informationDetails {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  .content {
    padding-top: 6.7vh;
    min-height: $conter2;
    height: auto;

    >h2 {
      margin: 3vw 4vw 0;
    }

    .date {
      margin: 1vw 4vw 0;
      font-size: 3vw;
      color: #999999;
    }

    >span {
      margin: 4vw 4vw 0;
      font-size: 4vw;
      color: #333;
      text-indent: 2em;
    }

    .briefIntroduction {
      display: flex;
      align-items: center;
      margin: 4vw 4vw 0;

      >div {
        display: flex;
        align-content: center;
        align-items: center;
        margin-right: 3vw;
        width: 1.5vw;
        height: 3vw;
        background-color: #fd0606;
      }

      >span {
        font-size: 3.5vw;
        font-weight: 600;
        color: #333;
      }
    }

    .splitLine {
      margin-top: 5vw;
      height: 3vw;
      background-color: #f9f9f9;
    }

    .textContent {
      margin-top: 5vw;
      padding: 0 4vw;

      /deep/span,
      /deep/p {
        font-size: 4vw !important;
        color: #333 !important;
      }

      /deep/img {
        width: 100vw;
        object-fit: cover;
      }
    }
  }
}
</style>