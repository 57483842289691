<template>
  <div class="indexbox">
    <van-nav-bar title="忘记密码" left-arrow @click-left="$router.go(-1)" :border="false" />
    <div class="frombox">
      <div class="inputTitle">{{ $t('用户名') }}</div>
      <van-field clearable v-model="tell" :placeholder="$t('请输入用户名')" />
      <!-- <van-field label="手 机 号" v-model="tell" type="tel" maxlength="11" clearable placeholder="请输入手机账号" /> -->
      <div class="inputTitle">{{ $t('验证码') }}</div>
      <van-field v-model="code" type="text" maxlength="11" clearable :placeholder="$t('请输入验证码')">
        <template #button>
          <van-button size="small" type="primary" round color="#F13B10" v-if="is_time == 1" @click="sendcode"
            class="anBtn">{{ $t('获取验证码') }}</van-button>
          <van-button size="small" type="primary" round color="#0057FF" v-if="is_time == 2" disabled class="anBtn">{{
      time }}S{{ $t('后获取') }}</van-button>
        </template>
      </van-field>
      <div class="inputTitle">{{ $t('登录密码') }}</div>
      <van-field v-model="pass" clearable maxlength="12" :placeholder="$t('请输入登录密码(数字加字母6 - 12)')"
        :type="loginPwEyeShow ? 'password' : 'text'" :right-icon="loginPwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onPwEyeEyeIcon(1)" />
      <div class="inputTitle">{{ $t('确认登录密码') }}</div>
      <van-field v-model="passto" clearable maxlength="12" :placeholder="$t('请确认登录密码')"
        :type="confirmLoginPwEyeShow ? 'password' : 'text'" :right-icon="confirmLoginPwEyeShow ? 'closed-eye' : 'eye-o'"
        @click-right-icon="onPwEyeEyeIcon(2)" />
      <div class="btnbox">
        <van-button type="default" block @click="registBtn" color="#F13B10" style="margin: 47.73vw 0" class="anBtn">
          {{ $t('确定') }}
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tell: "",
      code: "",
      pass: "",
      passto: "",
      disabled: false,
      time: 60,
      is_time: 1,
      loginPwEyeShow: false,
      confirmLoginPwEyeShow: false,
    };
  },
  methods: {
    onPwEyeEyeIcon(edit) {
      if (edit == 1) {
        this.loginPwEyeShow = !this.loginPwEyeShow;
      } else {
        this.confirmLoginPwEyeShow = !this.confirmLoginPwEyeShow;
      }
    },
    sendcode() {
      if (!this.tell) return this.$toast(this.$t("请输入手机号"));
      if (!/^1[23456789]\d{9}$/.test(this.tell))
        return this.$toast(this.$t("请输入正确格式的手机号"));
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
        duration: 0,
      });
      this.$post({
        url: "/app/sms/sendSms",
        data: {
          mobile: this.tell,
          scene: "reset_trade",
        },
        success: () => {
          this.$toast.clear();
          this.$toast(this.$t("发送成功"));
          this.is_time = 2;
          this.time = 60;
          var timer = setInterval(() => {
            this.time--;
            if (this.time == 0) {
              clearInterval(timer);
              this.is_time = 1;
            }
          }, 1000);
        },
        tip: () => { },
      });
    },
    registBtn() {
      if (!this.tell) return this.$toast(this.$t("请输入手机号"));
      if (!/^1[23456789]\d{9}$/.test(this.tell))
        return this.$toast(this.$t("请输入正确格式的手机号"));
      if (!this.code) return this.$toast(this.$t("请输入验证码"));
      if (!this.pass) return this.$toast(this.$t("请输入登录密码"));
      if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/.test(this.pass))
        return this.$toast(this.$t("登录密码必须是6至12位的字母数字组合"));
      if (!this.passto) return this.$toast(this.$t("请输入确认登录密码"));
      if (this.pass !== this.passto)
        return this.$toast(this.$t("两次输入的登录密码不一致"));
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
        duration: 0,
      });

      this.$post({
        url: "/app/login/forgetLoginPassword",
        noToken: true,
        data: {
          mobile: this.tell,
          sms_code: this.code,
          password: this.pass,
          confirm_password: this.passto,
        },
        success: () => {
          this.$toast.clear();
          this.$toast(this.$t("修改成功"));
          setTimeout(() => {
            this.$router.replace("login");
          }, 1500);
        },
        tip: () => { },
      });
    },
  },
  created() { },
};
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  //   background: url("../../../assets/img/beijing@2x.png") no-repeat;
  //   background-size: 100% 100%;
  padding: 0vw 4vw;

  /deep/.van-nav-bar {
    background: none;

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  .frombox {
    width: 100%;
    padding: 0 4vw;

    .inputTitle {
      margin-bottom: 1vw;
      margin-top: 8vw;
      padding: 0vw 4vw;
      font-size: 4vw;
      font-weight: 700;
      color: #5b5e63;
    }

    /deep/.van-icon {
      font-size: 6vw;
    }

    /deep/.van-cell {
      margin-top: 2vw;
      margin-bottom: 4.5vw;
      padding: 1vw 4vw;
      font-size: 3vw;
      border-radius: 8vw;
      background: rgba(255, 255, 255, 0.4);

      &:after {
        border-bottom: 1.3vw solid #ebedf0;
      }
    }

    .btnbox {
      width: 100%;
      padding: 0 4vw;

      /deep/.van-button {
        width: 75.67vw;
        height: 14.93vw;
      }
    }
  }
}
</style>
