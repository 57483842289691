<template>
  <!-- 设置 -->
  <div class="mySet">
    <div class="header_box">
      <van-nav-bar title="set up" left-arrow @click-left="$router.replace('mine')" :border="false" />
    </div>
    <div class="content">
      <div class="navbox">
        <router-link to="setPersonalData" class="navs">
          <p>{{ $t('我的资料') }}</p>
          <van-icon name="arrow" color="#000" size="18" />
        </router-link>
        <router-link to="setTransactionPassword" class="navs">
          <p>{{ $t('修改交易密码') }}</p>
          <van-icon name="arrow" color="#000" size="18" />
        </router-link>
        <router-link to="setLoginPassword" class="navs">
          <p>{{ $t("修改登录密码") }}</p>
          <van-icon name="arrow" color="#000" size="18" />
        </router-link>
        <router-link to="bankCardList01" class="navs">
          <p>payment method</p>
          <van-icon name="arrow" color="#000" size="18" />
        </router-link>

        <!-- <router-link to="langue" class="navs">
          <p>{{ $t("选择语言") }}-{{ { zh: $t("简体中文"), jp: $t("日语") }[
        this.getLang()
      ] }}</p>

          <van-icon name="arrow" color="#000" size="18" />
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>

<style scoped lang='scss'>
.mySet {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .navbox {
      width: 100%;
      padding: 2vw 0vw;

      .navs {
        width: 100%;
        height: 14vw;
        padding: 3vw 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;

        p {
          flex: 1;
          padding-left: 2vw;
          color: #000000;
          font-size: 4vw;
        }

        // &:last-child {
        //   border-bottom: none;
        // }
      }
    }
  }
}
</style>