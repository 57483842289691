module.exports = {
  "请输入搜索关键词": "検索キーワードを入力してください",
  "加载中...": "ロード中...",
  "综合": "統合",
  "价格": "価格",
  "销量": "販売量",
  "人": "人",
  "付款": "支払い",
  "确认订单": "注文の確認",
  "请去添加地址": "住所を追加してください",
  "规格": "仕様",
  "共": "合計",
  "件": "件",
  "小计": "小計",
  "合计": "合計＃ゴウケイ＃",
  "结算": "決済",
  "交易密码": "トランザクションパスワード",
  "请输入交易密码": "取引パスワードを入力してください",
  "支付成功": "支払いに成功しました",
  "订单金额": "オーダー金額",
  "订单单号": "注文書番号",
  "复制": "レプリケーション",
  "查看其他等级购买价格": "他の等級購入価格の表示",
  "请选择收货地址": "お受取先を選択してください",
  "提交成功": "コミット成功",
  "复制成功": "コピー成功",
  "复制失败": "コピーに失敗しました",
  "联系客服": "カスタマー・センターです",
  "公司介绍": "会社紹介です",
  "发布时间": "リリース時間",
  "购买价格": "購入価格",
  "全场会员折扣": "フル会員割引",
  "等级利润": "等級利益",
  "转卖价格": "転売価格",
  "转卖利润": "転売マージン",
  "收益计算": "収益計算です",
  "请输入数量": "数量を入力してください",
  "开始计算": "計算の開始",
  "抢购价": "買占め価格",
  "元": "円",
  "转卖价": "転売価格",
  "选择抢购专区商品": "コーナー商品の買い占めを選択する",
  "新款商品": "新商品",
  "活动商品": "キャンペーン商品",
  "资讯详情": "情報の詳細",
  "资讯中心": "情報センター",
  "会员福利": "会員の福利",
  "当前会员等级": "現在の会員ランク",
  "已领取": "受領済",
  "领取": "受け取る",
  "会员升级礼金": "会員進級礼金",
  "天天现金红包": "毎日現金入りお年玉",
  "每月话费额度": "毎月の通話料限度額",
  "每月油卡额度": "毎月の給油限度額",
  "升级更高等级获得更多权益": "より高いレベルにアップグレードしてより多くの権益を獲得する",
  "点击联系客服升级": "カスタマー・センターアップグレードをクリックしてください",
  "请输入手机号": "携帯番号を入力してください",
  "请输入油卡卡号": "オイルカード番号を入力してください",
  "确定": "を選択して、",
  "您已经领取过该权益": "あなたはすでにこの権益を受け取ったことがあります",
  "公告详情": "公告の詳細",
  "公告": "公告",
  "商品详情": "商品の詳細",
  "库存": "ストック",
  "评价": "ひょうか",
  "商家回复": "業者からの回答",
  "商家未回复，请耐心等待": "業者からの返事がありませんので、お待ちください",
  "购物车": "ショッピングカート",
  "已收藏": "お気に入り",
  "收藏": "コレクション",
  "加入购物车": "カートに追加",
  "立即购买": "今すぐ購入",
  "数量": "数量",
  "收藏成功": "コレクション成功",
  "取消收藏成功": "コレクションのキャンセルに成功しました",
  "刷新成功": "リフレッシュ成功",
  "这里空空如也~": "ここはガラガラ~",
  "人付款": "支払人",
  "等级制度": "レベルです",
  "点击联系客服": "カスタマー・センターをクリックしてください",
  "请去添加收货地址": "お受取先を追加してください",
  "选择规格": "仕様の選択",
  "提交订单": "発注の発行",
  "请选择规格": "仕様を選択してください",
  "抢购商品详情": "買占め商品詳細",
  "立即抢购": "すぐに購入",
  "关闭": "閉じる",
  "搜索商品": "商品の検索",
  "已售": "販売済み",
  "未开始": "開始していません",
  "已结束": "終了",
  "完成": "完了",
  "编辑": "編集",
  "没有更多了": "これ以上ない",
  "删除": "削除＃サクジョ＃",
  "全选": "すべて選択",
  "立即付款": "即時支払",
  "确定删除": "削除のOK",
  "隐私协议": "プライバシー契約",
  "注册协议": "登録プロトコル",
  "下载安装": "ダウンロードインストール",
  "适用于安卓手机": "Android携帯電話向け",
  "扫描二维码下载APP": "QRコードをスキャンしてAPPをダウンロードする",
  "登录": "ログイン＃ログイン＃",
  "用户名": "ユーザー名",
  "请输入用户名": "ユーザー名を入力してください",
  "密码": "パスワード",
  "请输入您的登录密码": "ログインパスワードを入力してください",
  "立即注册": "今すぐ登録",
  "忘记密码": "パスワードを忘れる",
  "在线客服": "オンラインカスタマーサービス",
  "请输入登录密码": "ログインパスワードを入力してください",
  "登录成功": "ログイン成功",
  "验证码": "認証コード",
  "请输入验证码": "認証コードを入力してください",
  "获取验证码": "認証コードの取得",
  "后获取": "ポストフェッチ",
  "登录密码": "ログインパスワード",
  "请输入登录密码(数字加字母6 - 12)": "ログインパスワードを入力してください（英数字プラスアルファベット6-12）",
  "确认登录密码": "ログインパスワードの確認",
  "请确认登录密码": "ログインパスワードを確認してください",
  "请输入正确格式的手机号": "正しいフォーマットの携帯番号を入力してください",
  "发送成功": "送信成功",
  "登录密码必须是6至12位的字母数字组合": "ログインパスワードは6～12桁の英数字の組み合わせでなければなりません",
  "请输入确认登录密码": "確認ログインパスワードを入力してください",
  "两次输入的登录密码不一致": "2回入力されたログインパスワードが一致しません",
  "修改成功": "変更に成功しました",
  "请设置6-12位登录密码": "6～12ビットのログインパスワードを設定してください",
  "确认交易密码": "取引パスワードの確認",
  "请输入确认交易密码": "確認取引パスワードを入力してください",
  "注册": "登録",
  "登录即代表阅读并同意": "ログインは読み取りと同意を表します",
  "和": "と",
  "注册成功": "登録に成功しました",
  "姓名": "名前",
  "收货人姓名": "受取人名",
  "电话": "電話番号",
  "收货人手机号": "受取人の携帯番号",
  "地区": "都道府県",
  "请选择省 / 市 / 区": "都道府県を選択してください",
  "设为默认收货地址": "デフォルトのお受取先に設定してください",
  "保存": "保存＃ホゾン＃",
  "添加地址": "アドレスを追加",
  "编辑地址": "アドレスの編集",
  "我的银行卡": "マイ銀行カード",
  "使用": "使用",
  "添加银行卡": "銀行カードの追加",
  "提现": "引き出し",
  "提现记录": "キャッシュレコード",
  "余额钱包": "バランス財布",
  "去添加银行卡": "銀行カードを追加します",
  "提现数量": "引き出し数量",
  "请输入提现数量": "引き出し数量を入力してください",
  "到账数量": "入金数量",
  "自动计算到账数量": "勘定科目数量への自動計算",
  "手续费": "手数料",
  "确认提现": "現金引き出しの確認",
  "确认": "確認",
  "待审核": "レビュー保留中",
  "通过": "に通じをつける",
  "驳回": "却下",
  "已取消": "キャンセル済み",
  "取消": "キャンセル",
  "本月收益": "今月の収益",
  "收益": "に利益を与える",
  "转卖订单号": "転売注文番号",
  "由": "から",
  "进行转卖": "転売を行う",
  "本月": "今月",
  "推广名片": "名刺を広める",
  "推广享优惠": "普及して優遇を享受する.",
  "复制链接": "リンクのコピー",
  "资金记录": "資金記録です",
  "全部": "すべて",
  "简体中文": "簡体字中国語",
  "日语": "日本語",
  "收货地址": "お受取先です",
  "默认": "デフォルト",
  "设为默认": "デフォルトに設定",
  "使用中": "使用中",
  "提示": "ヒント",
  "确认要删除吗?": "削除を確認しますか？",
  "删除成功": "削除に成功しました",
  "确认要使用此地址码?": "このアドレスコードを使用することを確認しますか？",
  "绑定成功": "バインド成功",
  "我的收藏": "私のコレクション",
  "取消收藏": "コレクションのキャンセル",
  "取消成功": "キャンセル成功",
  "条评价": "条評価",
  "评论内容": "コメントの内容",
  "订单号": "注文番号",
  "我的资料": "私の資料",
  "修改交易密码": "トランザクションパスワードの変更",
  "修改登录密码": "ログインパスワードの変更",
  "选择语言": "言語の選択",
  "请输入姓名": "名前を入力してください",
  "开户行": "口座開設銀行",
  "请输入开户行": "口座明細を入力してください",
  "银行卡号": "支店番号",
  "请输入银行卡号": "支店番号を入力してください",
  "绑定银行卡": "銀行カードのバインド",
  "旧密码": "古いパスワード",
  "请输入旧密码": "古いパスワードを入力してください",
  "确认密码": "パスワードの確認",
  "请再次输入登录密码": "ログインパスワードを再入力してください",
  "个人资料": "個人情報",
  "修改头像": "アバターの修正",
  "保存成功": "保存に成功しました",
  "请再次输入交易密码": "取引パスワードを再入力してください",
  "订单详情": "注文の詳細",
  "待支付": "支払い保留中",
  "已支付": "支払い済み",
  "已发货": "発送済みです",
  "已收货": "受入済",
  "已完成": "完了",
  "退货申请中": "返品依頼中",
  "退货中待发货": "返品中、発送待ちです",
  "退货中已发货": "返品中、発送済みです",
  "退款成功": "返金に成功しました",
  "订单取消": "注文取消",
  "转卖中": "転売中",
  "转卖完成": "転売完了",
  "倒计时": "カウントダウン",
  "订单总数": "受注合計",
  "总金额": "合計金額",
  "创建时间": "作成時間",
  "申请退货": "返品の申し込み",
  "上传单号": "伝票番号のアップロード",
  "确认收货": "受入確認",
  "立即评价": "そくじひょうか",
  "立即支付": "即時支払い",
  "收货成功": "受取完了です",
  "此时此地，想和大家分享什么": "ここで皆さんと何を共有したいのか",
  "请输入退货理由": "返品理由を入力してください",
  "提交": "送信",
  "评价完成": "評価完了",
  "提交申请成功": "購買依頼の発行に成功しました",
  "配送单号": "配送伝票番号",
  "请输入配送单号": "配送伝票番号を入力してください",
  "配送公司": "配送会社",
  "请输入配送公司": "配送会社を入力してください",
  "上传": "アップロード",
  "上传成功": "アップロードに成功しました",
  "转卖中心": "転売センター",
  "待转卖": "転売待ち",
  "已转卖": "転売済み",
  "已失败": "失敗しました",
  "订单编号": "オーダー番号",
  "商品原价": "商品原価",
  "转卖金额": "転売金額",
  "利润": "利潤",
  "名牌购商城": "ブランドショッピングモール",
  "全部商品": "全商品",
  "超值正版好物": "超価値正規版の良いもの",
  "抢购专区": "買い占め専用区",
  "限时抢购，抢抢抢": "時限を限定して買いあさる",
  "轻松出手，赚赚赚": "気軽に手を出して、儲けて儲けます",
  "查看全部": "すべて表示",
  "品牌推荐": "ブランド推奨",
  "更多": "詳細",
  "现价": "げんか",
  "充值": "チャージ",
  "我的评价": "私の評価",
  "资金明细": "資金記録です",
  "退出登录": "ログオンの終了",
  "确认要退出登录吗?": "ログインを終了することを確認しますか？",
  "退出登录成功": "ログオンの終了に成功しました",
  "全部订单": "すべての注文",
  "未转卖": "未転売",
  "转卖成功": "転売成功",
  "转卖失败": "転売失敗",
  "订单类型": "オーダー・タイプ",
  "商品购买": "商品の購入",
  "幸运抢购": "幸運の買占め",
  "幸运爆单": "ラッキー爆単",
  "幸运连单": "ラッキーリスト",
  "去评价": "デアセスメント",
  "退款中": "返金中",
  "查看详情": "詳細の表示",
  "售后": "アフターサービス",
  "转卖区": "転売ゾーン",
  "转卖记录": "転売履歴",
  "请输入订单编号": "注文番号を入力してください",
  "粘贴": "貼り付け",
  "确认转卖": "転売の確認",
  "查看其他等级转卖利润": "その他の等級転売利益の表示",
  "请先输入订单编号": "まず注文番号を入力してください",
  "首页": "トップページ",
  "抢购区": "買い占め区域",
  "订单": "オーダー",
  "我的": "マイ",
  "银行名称": "銀行名",
  "接口不存在": "インタフェースが存在しません",
  "未登录": "ログインしていません",
  "服务器内部错误": "サーバ内部エラー",

  "详细地址": "住所の詳細",
  "街道门牌、楼层房间号等": "請輸入市区町村、街区、建物名、部屋番号等詳細情報",
  "加入成功": "追加に成功しました",
  "上传单号": "伝票番号のアップロード",
  "请输入银行名称": "銀行名を入力してください",
  "联系电话": "連絡先電話番号",
  "请选择商品！": "商品を選んでください！",
  "订单超时了,请联系客服": "注文がタイムアウトしました。カスタマー・センターにお問い合わせください",

  "身份证明书": "身分証明書です",
  "邮政编码": "邮政编码",
  "自动生成": "自动生成",
  "银行支行": "银行支行",
  "请输入银行支行": "请输入银行支行"

}
