<template>
  <!-- 我的银行卡 -->
  <div class="bankCardList">
    <div class="header_box">
      <van-nav-bar title="payment method" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>

    <div class="content">
      <div class="list" v-for="(ele, index) in list" :key="ele.id" @click="go_to(ele.id, index, ele.payment_name)">
        <div class="nameType">
          <span class="name">{{ ele.payment_name }}</span>
          <!-- <span class="type">{{ ele.payment_name }}</span> -->
        </div>

      </div>
      <div style="height: 15vw;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bankCardList",
  props: {},
  data() {
    return {
      opt_id: '',
      list: [],
    };
  },
  created() {
    this.opt_id = this.$route.query.opt_id;
  },
  mounted() {
    this.getList();
  },
  methods: {
    go_to(id, index, payment_name) {

      this.$router.push({
        name: "bankCardList",
        query: {
          opt_id: this.opt_id,
          id: id,
          color: index,
          title: payment_name
        },
      });
    },
    // 获取银行卡列表
    getList() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/payment/getPayment",
        success: (res) => {
          this.$toast.clear();
          this.list = res.data;
        },
        tip: () => { },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.bankCardList {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
    }
  }

  // 内容
  .content {
    min-height: $navHeight;
    height: auto;

    >.list:nth-child(3n-2) {
      background: url("../../../assets/img/bank_card_bc1.png") no-repeat;
      background-size: 100% 100%;
    }

    >.list:nth-child(2) {
      background: url("../../../assets/img/bank_card_bc2.png") no-repeat;
      background-size: cover;
    }

    >.list:nth-child(3n+2) {
      background: url("../../../assets/img/bank_card_bc2.png") no-repeat;
      background-size: cover;
    }

    >.list:nth-child(3n-3) {
      background: url("../../../assets/img/bank_card_bc3.png") no-repeat;
      background-size: cover;
    }

    .list {
      position: relative;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      margin: 4vw auto 0;
      padding: 4vw;
      width: 92vw;
      // height: 18vw;
      border-radius: 4vw;

      .nameType {
        display: flex;
        align-content: center;
        margin-bottom: 1vw;

        .name {
          margin-right: 5vw;
          font-size: 5vw;
          color: #fff;
        }

        .type {
          font-size: 3vw;
          color: #fff;
        }
      }

      .num {
        font-size: 3.5vw;
        color: #fff;
      }

      .btn {
        position: absolute;
        bottom: 0;
        right: 4vw;
        display: flex;
        justify-content: flex-end;
        align-content: center;

        div {
          height: 7vw;
        }

        .edit {
          color: #fff;
        }

        .del,
        .use {
          margin-left: 4vw;
          color: #fff;
        }
      }
    }
  }

  .addCard {
    position: absolute;
    bottom: 0;
    left: 0;

    /deep/.van-button {
      margin: 0;
      width: 100vw;
      height: 7vh;
      border-radius: 0vw;
    }

    /deep/.van-button--normal {
      padding: 0;
    }
  }

  /deep/.van-goods-action {
    width: 100%;
  }
}
</style>