<template>
  <!-- 等级制度 -->
  <div class="rankingSystem">
    <div class="header_box">
      <van-nav-bar :title="$t('等级制度')" left-arrow @click-left="$router.replace('/')" :border="false" />
    </div>

    <div class="content">
      <div class="contentBox" v-html="rankingInfo.content">
      </div>
      <div class="btnAndImg">
        <van-button type="primary" color="#F1AC10" block @click="goContact">{{ $t('点击联系客服') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rankingSystem",
  props: {},
  data() {
    return {
      rankingInfo: {},
      webSiteInfo: "",
    }
  },
  created() { },
  mounted() {
    this.getRankingInfo()
    this.getWebSiteInfo()
  },
  methods: {
    // 获取等级制度信息
    getRankingInfo() {
      this.$get({
        url: "/app/hierarchy/detail",
        success: (res) => {
          this.rankingInfo = res.data
        },
        tip: () => { },
      })
    },
    // 获取站点信息
    getWebSiteInfo() {
      this.$get({
        url: "/app/extend/getWebSiteInfo",
        success: (res) => {
          this.webSiteInfo = res.data
        },
        tip: () => { },
      })
    },
    // 去联系
    goContact() {
      // window.location.href = this.webSiteInfo.mobile
      this.$router.push({
        name: "ChatWindow",
        query: {
          src: this.webSiteInfo.mobile,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.rankingSystem {
  padding: $navHeight 0 0 0;
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: linear-gradient(#fbb152, #f45e1a);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fbb152;
    }

    .van-icon {
      color: #fff;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #fff;
    }
  }

  .content {
    width: 100%;
    padding: 4vw 5vw;

    .contentBox {
      padding: 2vw;
      overflow: auto;
      width: 100%;
      max-height: 54vh;
      background-color: #fff;
      border-radius: 3vw;
      overflow-x: scroll;

      /deep/span {
        font-size: 4vw;
        color: #333;
      }

      /deep/p {
        font-size: 4vw !important;
        color: #333 !important;
        clear: both !important;
      }

      /deep/img {
        max-width: 100% !important;
        object-fit: cover !important;
      }
    }

    .btnAndImg {
      width: 100%;
      height: 66vw;
      background: url("../../../assets/img/rankingSystem_bc.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 50vw;

      /deep/.van-button {
        width: 79vw;
        height: 13vw;
        margin: 0 auto;
      }
    }
  }
}
</style>
