<template>
  <!-- 首页 -->
  <div class="index">
    <header class="header">
      <div class="logo">
        <div class="logo_img" v-if="logoImg">
          <img :src="logoImg" alt="" />
        </div>
        <div class="logo_text">
          <span class="one">{{ companyName }}</span>
        </div>
      </div>

      <van-search v-model="searchVal" shape="round" background="#F13B10" :placeholder="$t('请输入搜索关键词')">
        <template #right-icon>
          <div @click="onSearch(searchVal)">
            <van-icon name="search" size="18" />
          </div>
        </template>
      </van-search>
    </header>

    <div class="container">
      <div class="content1">
        <div class="banner">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#f1ac10">
            <van-swipe-item v-for="  ele in bannerList  " :key="ele.id">
              <img :src="ele.cover_image" style="width: 92vw; height: 36.8vw; object-fit: cover" />
            </van-swipe-item>
          </van-swipe>
        </div>



        <!-- //九宫格 -->
        <div class="qt_grid">
          <van-grid :column-num="5">
            <van-grid-item @click="navback(item.path)" v-for="(  item, index  ) in grid_list  " :key="index">
              <div class="van-image"><img :src="item.img" /></div>
              <span class="van-grid-item__text two">{{ item.name }}</span>
            </van-grid-item>
          </van-grid>
        </div>

        <div class="notice" @click="navback('noticeList')">
          <van-notice-bar :scrollable="false" background="#F9F9F9">
            <template #left-icon>
              <img src="../../assets/img/index_notice_icon.png" style="margin-right: 3vw; width: 5vw; object-fit: cover"
                alt="" />
              <!-- <span class="noticeTite">公告</span> -->
            </template>
            <template #right-icon>
              <van-icon name="arrow" color="#B4C0D4" />
            </template>
            <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
              <van-swipe-item class="hid" v-for="(  item, index  ) in notice  " :key="index">{{ item.title
                }}</van-swipe-item>
            </van-swipe>
          </van-notice-bar>
        </div>
        <div class="whole">
          <div class="wholeShop" @click="navback('allGoods')">
            <!-- <h2 class="titeColor"> {{ $t("全部商品") }}</h2>
            <span class="titeColor">{{ $t("超值正版好物") }}</span> -->
          </div>
          <div class="specialArea">
            <div class="rushToTuy" @click="navback('rushToPurchase')">
              <!-- <h2 class="titeColor">{{ $t("抢购专区") }}</h2>
              <div class="titeColor titeBox">
                <span>{{ $t("限时抢购，抢抢抢") }}！</span>
              </div> -->
            </div>
            <div class="resell" @click="navback('resell')">
              <!-- <h2 class="titeColor">{{ $t("转卖中心") }}</h2>
              <div class="titeColor titeBox">
                <span>{{ $t("轻松出手，赚赚赚") }}！</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="splitLine"></div>
      <div class="content2">
        <!-- 抢购专区 -->
        <div class="tite" v-show="rushToBuyList.length !== 0">
          <img src="../../assets/img/index_titeImg.png" />
          <h3>{{ $t('抢购专区') }}</h3>
        </div>
        <van-pull-refresh v-model="Down" :success-text="$t('刷新成功')" @refresh="get_down">
          <van-list v-model="up" :finished="over" :finished-text="finished_text" @load="get_up"
            :immediate-check="false">
            <div class="rushToBuyList" v-show="rushToBuyList.length !== 0">
              <div class="rushToBuyShop" v-for="  ele in rushToBuyList  " :key="ele.id">
                <div class="timeAndImg" @click="navback('RushGoodsDetail', ele.id)">
                  <img :src="ele.goods_thumb" alt="" style="
                      width: 44vw;
                      height: 44vw;
                      border-radius: 3vw;
                      overflow: hidden;
                    " />
                  <div class="time">
                    <van-icon name="clock-o" color="#fff" size="14" />
                    {{ $t("倒计时") }}
                    <van-count-down millisecond :time="reTime * 1000" format="HH:mm:ss" />
                  </div>
                </div>
                <h3>{{ ele.goods_name }}</h3>
                <span class="price">
                  <div class="sellingPrice">
                    <span>{{ $t('元') }}{{ ele.discount_price }}</span>
                  </div>
                  <div class="originalPrice">{{ ele.goods_price }}</div>
                </span>
                <div class="purchase">
                  <div class="purchasePrice purchaseFlex">
                    <span class="one">{{ $t('元') }}{{ ele.discount_price }}</span>
                  </div>
                  <div class="state purchaseFlex" v-if="begin < + new Date() / 1000 && end > +new Date() / 1000
                  " @click="makePanicPurchase(ele)">
                    {{ $t("立即抢购") }}
                    <!-- Not started yet -->
                  </div>
                  <div class="state purchaseFlex" v-if="begin > +new Date() / 1000">
                    {{ $t("未开始") }}
                  </div>
                  <div class="state purchaseFlex" v-if="end < + new Date() / 1000">
                    {{ $t("已结束") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="viewAll" v-show="rushToBuyList.length !== 0" @click="navback('rushToPurchase')">
              —— {{ $t("查看全部") }} ——
            </div>
          </van-list>
        </van-pull-refresh>
        <!-- 品牌推荐 -->
        <div class="tite" v-show="recommendList.length !== 0">
          <img src="../../assets/img/index_titeImg.png" />
          <h3>{{ $t("品牌推荐") }}</h3>
        </div>
        <!-- <van-pull-refresh v-model="Down" success-text="刷新成功" @refresh="get_down">
          <van-list v-model="up_brand" :finished="over_brand" :finished-text="finished_text_brand" @load="get_up_brand"
            :immediate-check="false"> -->
        <div class="rushToBuyList" v-for="  ele in recommendList  " :key="ele.id">
          <div class="brandLogo">
            <div>
              <img :src="ele.brand_img" alt="" style="
                      width: 8vw;
                      height: 8vw;
                      margin-right: 2vw;
                      border-radius: 50%;
                    " />
              <span>{{ ele.brand_name }}</span>
            </div>
            <div @click="navback('BrandGoods', ele.id)">
              {{ $t("更多") }}
              <van-icon name="arrow" color="#B4C0D4" />
            </div>
          </div>
          <div class="rushToBuyShop" v-for="item in ele.goods_list" :key="item.id"
            @click="navback('productDetailsOrEvaluation', item.id)">
            <div class="timeAndImg">
              <img :src="item.goods_thumb" alt="" style="
                      width: 44vw;
                      height: 49.33vw;
                      border-radius: 3vw;
                      overflow: hidden;
                    " />
            </div>
            <h3>{{ item.goods_name }}</h3>
            <span class="price">
              <div class="sellingPrice">
                {{ $t("现价") }}<span>{{ $t('元') }}{{ item.discount_price }}</span>
              </div>
              <div class="originalPrice">{{ item.goods_price }}</div>
            </span>
            <div class="price">
              <span>{{ $t("销量") }}：{{ item.goods_sales }}</span>
              <span>{{ $t("库存") }}：{{ item.goods_stock }}</span>
            </div>
          </div>
        </div>
        <!-- </van-list>
        </van-pull-refresh> -->
      </div>
    </div>

    <van-overlay :show="show">
      <div class="wrapper boxa" @click.stop v-if="showcode.code == 1">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="navorders">
          {{ $t("确定") }}
        </van-button>
      </div>

      <div class="wrapper boxb" @click.stop v-if="showcode.code == 2">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D"
          @click="$router.replace({ name: 'order', query: { active: 1 } })">
          {{ $t("关闭") }}
        </van-button>
      </div>



      <div class="wrapper boxc" @click.stop v-if="showcode.code === 0">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="show = false">
          {{ $t("关闭") }}
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchVal: "",
      // 公司logo
      logoImg: "",
      companyName: "",
      grid_list: [
        {
          img: require("@/assets/img/i01.png"),
          name: this.$t("公司介绍"),
          path: "companyDetails",
        },
        {
          img: require("@/assets/img/i02.png"),
          name: this.$t("等级制度"),
          path: "rankingSystem",
        },
        {
          img: require("@/assets/img/i03.png"),
          name: this.$t("会员福利"),
          path: "membershipBenefits",
        },
        {
          img: require("@/assets/img/i04.png"),
          name: this.$t("收益计算"),
          path: "incomeCalculation",
        },
        {
          img: require("@/assets/img/i05.png"),
          name: this.$t("资讯中心"),
          path: "informationList",
        },
      ],

      bannerList: [],
      rushToBuyList: [],
      recommendList: [],
      notice: [],
      begin: "",
      end: "",
      // 抢购专区
      finished_text: "",
      page: 1,
      loading: false,
      Down: false,
      up: false,
      over: false,
      // 品牌推荐
      finished_text_brand: "",
      page_brand: 1,
      loading_brand: false,
      Down_brand: false,
      up_brand: false,
      over_brand: false,

      show: false,
      showcode: {},
      orders: {},
    };
  },
  computed: {
    reTime: function () {
      return this.end - Math.floor(+new Date() / 1000);
    },
  },
  created() { },
  mounted() {
    this.get_companyLogo();
    this.getBanner();
    this.get_notice();
    this.get_indexGoods();
    this.getRecommendList();
    // this.get_up_brand();
  },
  methods: {
    navback(name, id) {
      if (localStorage.getItem("token")) {
        this.$router.push({ path: name, query: { id: id } });
      } else {
        this.$router.replace("login");
      }
    },
    // 获取公司logo
    get_companyLogo() {
      this.$get({
        url: "/app/extend/getCompay",
        success: (res) => {
          this.logoImg = res.data.logo;
          this.companyName = res.data.title;
          this.$nextTick(() => {
            this.logoImg = res.data.logo;
            this.companyName = res.data.title;
          });

          // var link = document.querySelector('link[rel="icon"]');
          // if (link) {
          //   link.href = this.logoImg; // 尝试更改现有链接的 href  
          // } else {
          //   var newLink = document.createElement('link');
          //   newLink.rel = 'icon';
          //   newLink.href = this.logoImg;
          //   document.head.appendChild(newLink);
          // }

          // document.title = this.companyName;

        },
        tip: () => { },
      });
    },
    // 获取轮播图列表
    getBanner() {
      this.$get({
        url: "/app/banner/getBannerList",
        data: { type: 1 },
        success: (res) => {
          if (res.code == 1) {
            this.bannerList = res.data;
          }
        },
        tip: () => { },
      });
    },
    //获取公告
    get_notice() {
      this.$get({
        url: "/app/announcement/getAnnouncementList",
        success: (res) => {
          console.log(res);
          if (res.data.list.length < 4) {
            this.notice = res.data.list;
          } else {
            this.notice.push(res.data.list[0]);
            this.notice.push(res.data.list[1]);
            this.notice.push(res.data.list[2]);
          }
        },
        tip: () => { },
      });
    },
    //获取首页推荐的商品
    // get_indexGoods() {
    //   this.$get({
    //     url: "/app/goods/list",
    //     data: {
    //       is_recommend: 1,
    //       is_seckill: 1,
    //       brand_id: 0,
    //       cat_id: 0,
    //       search: this.searchVal,
    //     },
    //     success: (res) => {
    //       this.rushToBuyList = res.data.list;
    //       this.begin = res.data.seckill_set.begin_time;
    //       this.end = res.data.seckill_set.end_time;
    //     },
    //     tip: () => {},
    //   });
    // },
    // 获取品牌推荐列表
    // getRecommendList() {
    //   this.$get({
    //     url: "/app/goods/getRecommendList",
    //     success: (res) => {
    //       this.recommendList = res.data.list;
    //     },
    //     tip: () => {},
    //   });
    // },
    onSearch(val) {
      if (localStorage.getItem("token")) {
        this.$router.push({
          name: "searchCommodity",
          query: {
            searchContent: val,
          },
        });
      } else {
        this.$router.replace("login");
      }
    },

    ////获取首页推荐的商品 初始化数据
    get_indexGoods() {
      console.log("初始化");
      this.page = 1;
      this.over = false;
      this.get_list("down"); // 加载数据
      console.log(this.rushToBuyList);
    },
    get_down() {
      console.log("下拉");
      setTimeout(() => {
        this.page = 1;
        this.over = false;
        this.get_list("down"); // 加载数据
      }, 1000);
    },
    get_up() {
      this.page++;
      this.get_list("up"); // 加载数据
    },
    get_list(type) {
      // let data = Object.assign({ page: this.page }, this.data);
      this.$get({
        url: "/app/goods/list",
        data: {
          page: this.page,
          is_recommend: 1,
          is_seckill: 1,
          brand_id: 0,
          cat_id: 0,
          search: "",
        },
        success: (res) => {
          this.begin = res.data.seckill_set.begin_time;
          this.end = res.data.seckill_set.end_time;
          let data = res.data.list;
          // console.log("返回的数据", data);
          if (type == "down") {
            this.rushToBuyList = data;
          } else if (type == "up") {
            if (this.rushToBuyList) {
              this.rushToBuyList = [...this.rushToBuyList, ...data];
            }
            if (data.length == 0) {
              this.over = true;
            }
          }
          this.Down = false;
          this.up = false;
        },
        tip: (val) => {
          this.over = true;
        },
      });
    },
    //品牌推荐 初始化数据
    getRecommendList() {
      this.page_brand = 1;
      this.over_brand = false;
      this.get_list_brand("down"); // 加载数据
      console.log(this.recommendList);
    },
    // get_down_brand() {
    //   setTimeout(() => {
    //     this.page_brand = 1;
    //     this.over_brand = false;
    //     this.get_list_brand("down"); // 加载数据
    //   }, 1000);
    // },
    get_up_brand() {
      this.page++;
      this.get_list_brand("up"); // 加载数据
    },
    get_list_brand(type) {
      // let data = Object.assign({ page: this.page }, this.data);
      this.$get({
        url: "/app/goods/getRecommendList",
        success: (res) => {
          let data = res.data.list;
          // console.log("返回的数据", data);
          if (type == "down") {
            this.recommendList = data;
          } else if (type == "up") {
            if (this.recommendList) {
              this.recommendList = [...this.recommendList, ...data];
            }
            if (data.length == 0) {
              this.over_brand = true;
            }
          }
          this.Down_brand = false;
          this.up_brand = false;
        },
        tip: (val) => {
          this.over_brand = true;
        },
      });
    },
    makePanicPurchase(data) {
      if (localStorage.getItem("token")) {
        this.$post({
          url: "/app/order_goods/makePanicPurchase",
          data: {
            goods_id: data.id,
          },
          success: (res) => {
            this.showcode = res;
            this.orders.seckill_id = res.data.seckill_id;
            this.orders.id = data.id;
            this.show = true;
          },
          tips: (err) => {
            console.log(12, err);
            this.showcode = err;
            this.show = true;
          },
        });
      } else {
        this.$router.replace("login");
      }
    },
    navorders() {
      this.$router.push({
        name: "RushconfirmOrder",
        query: {
          seckill_id: this.orders.seckill_id,
          goods_id: this.orders.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
//九宫格
.qt_grid {
  width: 100%;
  // height: 18vw;
  background: #ffffff;
  // box-shadow: 0rem 0.53vw 5.33vw -2.93vw rgba(109, 109, 109, 0.5);
  border-radius: 2.13vw;
  margin: 2.67vw auto;

  /deep/.van-grid {
    flex-wrap: nowrap;
  }

  /deep/.van-grid-item__content {
    padding: 0 1vw 0 1vw;
  }

  .van-image {
    width: 12vw;
    height: 12vw;
    margin-bottom: 2vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  /deep/[class*="van-hairline"]::after {
    border: 0;
  }

  .van-grid-item__text {
    font-size: 3.2vw;
    font-weight: 400;
    color: #666;
    // white-space: nowrap;
  }
}

.marginTop4 {
  margin-top: 4vw;
}

/deep/.van-overlay {
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 80vw;
    height: 80vw;
    padding: 18vw 14vw 3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    .titblock {
      width: 100%;
      color: #fff;
      font-size: 4vw;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
      }
    }
  }
}

.boxa {
  background: url("../../assets/img/a.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxb {
  background: url("../../assets/img/b.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxc {
  background: url("../../assets/img/c.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.index {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 8vh 0;

  .header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f13b10;
    padding: 0 0 0 3vw;
    color: #fff;

    .logo {
      display: flex;
      align-items: center;

      .logo_img {
        width: 8vw;
        height: 8vw;
        margin-right: 2vw;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .logo_text {
        color: #fff;
        font-size: 2.8vw;
      }
    }

    >img {
      width: 8vw;
      object-fit: cover;
    }

    /deep/.van-search {
      //flex: 1;
      max-width: 39vw;

      .van-field__left-icon {
        display: none;
      }
    }
  }

  /deep/.van-count-down {
    color: #fff;
  }

  .container {
    padding-top: 54px;
    height: auto;
  }

  .content1 {
    padding: 0vw 4vw;

    .banner {
      overflow: hidden;
      margin-top: 4vw;
      width: 92vw;
      height: 36.8vw;
      border-radius: 4vw;
    }

    .navIcon {
      margin-top: 4vw;

      .navList {
        display: flex;
        justify-content: space-between;
        height: 19.2vw;

        .navContent {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 13vw;
          height: 19.2vw;
          opacity: 1;

          .navTite {
            margin-top: 1vw;
            text-align: center;
            font-size: 3vw;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            white-space: nowrap;
          }
        }
      }
    }

    .notice {
      margin-top: 3.47vw;

      .notice-swipe {
        height: 13.33vw;
        line-height: 13.33vw;
      }

      /deep/.van-notice-bar {
        font-size: 3vw;
        color: #000000;
        padding: 0 6px;

        img {
          margin-right: 2vw;
        }

        .hid {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .noticeTite {
        margin: 0 2.67vw;
      }
    }

    .whole {
      display: flex;
      justify-content: space-between;
      margin-top: 3.73vw;
      height: 60.53vw;

      .wholeShop {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        // margin-right: 2.67vw;
        padding-top: 2.93vw;
        width: 44.8vw;
        height: 100%;
        background: url("../../assets/img/index_whole_shop.png") no-repeat;
        background-size: contain;
        border-radius: 3vw;

        .titeColor {
          color: #fff;
        }

        >h2 {
          font-size: 5vw;
        }

        >span {
          font-size: 3vw;
        }
      }

      .specialArea {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 44.53vw;
        height: 100%;

        .rushToTuy,
        .resell {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: 2.67vw 2.67vw 0;
          // margin-bottom: 2.67vw;
          //width: 100%;
          height: 27.43vw;
          background: url("../../assets/img/index_shopping_area.png") no-repeat;
          background-size: contain;
          border-radius: 3vw;

          h3 {
            font-size: 4vw;
            white-space: nowrap;
          }

          .titeColor {
            color: #fff;
          }

          .titeBox {
            display: inline-block;
            position: relative;
            // width: 31.93vw;
            height: 4vw;

            //padding:1px 2vw;
            // background-color: #f1ac10;
            // border-radius: 4vw;
            >span {
              display: inline-block;
              position: absolute;
              left: -4px;
              top: -4px;
              font-size: 3vw;
              transform: scale(0.9);
              // white-space: nowrap;
            }
          }
        }

        .resell {
          background: url("../../assets/img/index_resale_center.png") no-repeat;
          background-size: contain;

          .titeBox {
            //background-color: #f13b10;
          }
        }
      }
    }
  }

  .splitLine {
    margin-top: 4vw;
    width: 100%;
    height: 2.67vw;
    background-color: #f9f9f9;
  }

  .content2 {
    padding: 0 4vw 4vw;

    .tite {
      display: flex;
      position: relative;
      margin: 6.13vw 0;
      justify-content: center;

      >img {
        display: inline-block;
        width: 28.8vw;
        height: 1.33vw;
      }

      >h3 {
        font-size: 4vw;
        position: absolute;
        color: #f13b10;
        transform: translateY(-50%);
      }
    }

    .rushToBuyList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 92vw;

      .brandLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 92vw;
        height: 10vw;

        span {
          font-size: 3.5vw;
          font-weight: bold;
        }
      }

      .rushToBuyShop {
        margin-bottom: 3vw;
        width: 44vw;
        min-height: 74vw;

        .timeAndImg {
          position: relative;

          >img {
            object-fit: cover;
          }

          .time {
            display: flex;
            align-items: center;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            height: 8vw;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            font-size: 3vw;
            color: #fff;
            border-bottom-left-radius: 3vw;
            border-bottom-right-radius: 3vw;

            /deep/.van-count-down {
              font-size: 3vw;
            }
          }
        }

        >h3 {
          margin-top: 2.67vw;
          color: #3d444d;
          /*文字溢出隐藏显示省略号*/
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .price {
          display: flex;
          justify-content: space-between;
          margin-top: 0.67vw;

          .sellingPrice {
            font-size: 3vw;
            color: #3d444d;

            >span {
              font-size: 4vw;
              font-weight: bold;
              color: #3a3d57;
            }
          }

          .originalPrice {
            color: #3a3d57;
            text-decoration: line-through;
          }
        }

        .purchase {
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          margin-top: 2.67vw;
          height: 9vw;
          border-radius: 1vw;
          width: 100%;

          .purchaseFlex {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            height: 100%;
          }

          .purchasePrice {
            font-size: 4vw;
            color: #f13b10;
            font-weight: bold;
            background-color: #ffce60;
          }

          .state {
            width: 40%;
            font-size: 3vw;
            color: #fff;
            background-color: #f13b10;
            line-height: 1.2;
            padding: 1vw;
          }
        }
      }
    }

    .viewAll {
      display: flex;
      justify-content: center;
      font-size: 3vw;
      color: #999999;
      font-weight: medium;
      margin-top: 6vw;
    }
  }
}
</style>
