<template>
  <!-- 确认订单 -->
  <div class="confirmOrder greyBc">
    <div class="header_box">
      <van-nav-bar :title="$t('确认订单')" left-arrow @click-left="$router.replace('rushToPurchase')" :border="false" />
    </div>

    <div class="content">
      <div class="personalInformation whiteBc padding04" @click="goAddressList(addressInof.id)">
        <img src="../../../assets/img/orderDateils_tel_address_icon.png" alt=""
          style="width: 7vw; height: 7vw; margin-right: 4vw" />
        <div v-if="!addressInof.id" class="contact tel font4 fontBold">
          {{ $t('请去添加收货地址') }}
        </div>
        <div v-if="addressInof.id" class="contact">
          <div class="tel font4 fontBold">
            {{ addressInof.name }} {{ mask_code(addressInof.phone) }}
          </div>
          <div class="address">
            {{
              addressInof.area_name.split(' ')[0] +
              "********"
            }}
          </div>
        </div>
        <van-icon name="arrow" size="18" />
      </div>
      <div class="productInformation whiteBc">
        <div class="imgTite">
          <img :src="goods_list.goods_thumb" alt="" style="
              width: 23vw;
              height: 23vw;
              border-radius: 2vw;
              overflow: hidden;
              margin-right: 3vw;
              object-fit: cover;
            " />
          <div class="tite">
            <h3>{{ goods_list.goods_name }}</h3>
            <div class="orange" @click="show = true">
            </div>
            <div class="priceNum">
              <div class="orange fontBold font4">
                {{ $t('元') }}{{ goods_list.discount_price }}
              </div>
              <div class="num grey" @click="show = true">
                <div class="stepp2">
                  <van-stepper v-model="amount" integer min="1" :max="goods_list.goods_stock" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="altogether grey">
          <span>{{ $t('共') }}{{ amount }}{{ $t('件') }} {{ $t('小计') }}</span><span class="orange font4 fontBold">
            {{ $t('元') }}{{ (Number(amount * goods_list.discount_price)).toFixed(2) }}
          </span>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="bottom" round :safe-area-inset-bottom="true">
      <div class="popupbox">
        <div class="listbox">
          <img :src="specs.cover ? specs.cover : goods_list.goods_thumb" alt="" />
          <div class="left">
            <p style="color: #e83323; font-size: 3.2vw">
              <span>{{ $t('元') }}{{ goods_list.discount_price }}</span>
            </p>
            <p class="tit">
              <span>{{ $t('元') }}{{ goods_list.goods_price }}</span>
              <span>{{ $t('库存') }}:{{ goods_list.goods_stock }}</span>
            </p>
          </div>
        </div>

        <div>
          <div v-for="(val, index) in goods_attr" :key="index">
            <p class="listtit">{{ val.attr_name }}</p>
            <div class="numsbox">
              <p :class="item.check ? 'show' : ''" v-for="(item, keys) in val.attr_values" :key="keys"
                @click="specChange(index, keys)">
                {{ item.attr_value }}
              </p>
            </div>
          </div>
        </div>

        <div class="stepp">
          <p class="listtit">{{ $t('数量') }}</p>
          <van-stepper v-model="amount" integer min="1" :max="goods_list.goods_stock" />
        </div>

        <van-button @click="show = false" style="margin: 2vw 0 3vw" block class="anBtn" round type="info"
          color="linear-gradient(270deg, #E83323 0%, #FFA39B 100%)">{{ $t('确定') }}</van-button>
      </div>
    </van-popup>

    <van-goods-action>
      <div class="numPrice">
        {{ $t('共') }}{{ amount }}{{ $t('件') }} {{ $t('合计') }}:
        <span>
          {{ $t('元') }}{{ (Number(amount * goods_list.discount_price)).toFixed(2) }}
        </span>
      </div>
      <van-goods-action-button type="danger" :text="$t('提交订单')" color="#F13B10" @click="makeSeckillOrder" />
    </van-goods-action>

    <!-- 输入密码弹窗 -->
    <van-dialog v-model="passshow" :title="$t('交易密码')" show-cancel-button :before-close="onConfirmBtn">
      <van-cell-group>
        <van-field v-model="transactionPw" :label="$t('交易密码')" type="password" :placeholder="$t('请输入交易密码')"
          :border="false" clearable maxlength="6" />
      </van-cell-group>
    </van-dialog>

    <!-- 支付成功弹窗 -->
    <div class="paymentSuccessfulPopup" v-show="showSuccessful">
      <div class="paymentSuccessful">
        <div class="closeIcon" @click="showSuccessful = false">
          <van-icon name="close" size="18" />
        </div>
        <p class="font4">{{ $t('支付成功') }}</p>
        <img src="../../../assets/img/login_bc@2x.png" alt="" style="width: 34vw; height: 34vw; margin-top: 4vw" />
        <span class="grey">{{ $t('订单金额') }} {{ $t('元') }}{{ ordersinfo.order_price }}</span>
        <div class="orderNum">
          <span class="grey">{{ $t('订单单号') }}:{{ ordersinfo.order_sn }}</span>
          <span class="btnCody" v-clipboard:copy="ordersinfo.order_sn" v-clipboard:success="onCopy"
            v-clipboard:error="onError">{{ $t('复制') }}</span>
        </div>
        <div class="btn" @click="
          $router.push({
            name: 'gradeDiscount',
            query: { sn: ordersinfo.order_sn },
          })
          ">
          <van-button type="primary" color="#F13B10">{{ $t('查看其他等级购买价格') }}</van-button>
        </div>
      </div>
    </div>


    <van-overlay :show="success_show">
      <div class="wrapper boxa" @click.stop>
        <div class="titblock">
          <p>{{ showcode_msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="navorders">{{ $t('确定') }}</van-button>
      </div>

    </van-overlay>




  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      success_show: false,
      showcode_msg: '',
      //成功弹窗
      transactionPw: "",
      // 输入密码弹窗
      passshow: false,
      show: false,
      // 支付成功弹窗
      showSuccessful: false,
      // 地址信息
      addressInof: {},

      address_id: "", //这是从选择地址里返回的地址id
      goods_id: this.$route.query.goods_id,
      seckill_id: this.$route.query.seckill_id,

      amount: 1,
      total_price: 0,

      goods_list: {}, //商品信息
      goods_attr: [], //商品规格信息
      goods_specs: {}, //商品规格拼接信息

      set_spec: [], //前端 选择后的规格id
      specs: {}, //选择完

      order_id: "",
      ordersinfo: {}, //订单信息
    }
  },
  created() { },
  mounted() {
    if (this.$route.query.specs) {
      this.specs = this.$route.query.specs
    }
    if (this.$route.query.amount) {
      this.amount = this.$route.query.amount
    }

    this.getUnpaidOrder()
    if (this.$route.query.address_id) {
      this.address_id = this.$route.query.address_id
      this.getAddressInof(this.address_id)
    }
  },
  methods: {
    // 获取待支付订单页面数据
    getUnpaidOrder() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      })
      this.$get({
        url: "/app/order_goods/getSeckillWaitPayInfo",
        data: {
          goods_id: this.goods_id,
          seckill_id: this.seckill_id,
        },
        success: (res) => {
          this.$toast.clear()
          if (!this.address_id) {
            // if (res.data.address.id) {
            //   this.$toast("请选择收货地址")
            // } else {
            // }
            this.addressInof = res.data.address
          }
          this.goods_list = res.data.goods
          res.data.goods_attr.forEach((val) => {
            val.attr_values.forEach((item) => {
              item.check = false
            })
          })
          this.goods_attr = res.data.goods_attr
          this.goods_specs = res.data.goods_specs
        },
        tip: () => { },
      })
    },
    // 获取地址信息
    getAddressInof(e) {
      this.$get({
        url: "/app/region/list",
        success: (res) => {
          this.$toast.clear()
          for (let i = 0; i < res.data.length; i++) {
            if (!e) {
              if (res.data[i].is_default == 1) {
                this.addressInof = res.data[i]
              }
            } else {
              if (res.data[i].id == e) {
                this.addressInof = res.data[i]
              }
            }
          }
        },
        tip: () => { },
      })
    },
    // 更换地址
    goAddressList(id) {
      this.$router.push({
        name: "myAddress",
        query: {
          is_order: true,
          order_id: id,
          goods_id: this.goods_id,
          seckill_id: this.seckill_id,
          specs: this.specs,
          amount: this.amount,
          rushorder: true,
        },
      })
    },
    //属性选择
    specChange(index, keys) {
      this.goods_attr.forEach((val, indexs) => {
        if (index == indexs) {
          val.attr_values.forEach((item, indexkey) => {
            if (keys == indexkey) {
              item.check = true
              if (item.check) {
                this.set_spec[index] = item
              }
            } else {
              item.check = false
            }
          })
        }
      })
      let ids = []
      this.set_spec.forEach((val) => {
        ids.push(val.id)
      })
      ids = ids.join(",")
      this.goods_specs.forEach((val) => {
        if (val.goods_path === ids) {
          this.specs = val
        }
      })

      console.log(111, this.specs)
    },

    makeSeckillOrder() {
      if (!this.addressInof.id) return this.$toast(this.$t("请选择收货地址"))

      this.$post({
        url: "/app/order_goods/makeSeckillOrder",
        data: {
          goods_id: this.goods_id,
          seckill_id: this.seckill_id,
          amount: this.amount,
          specs_id: this.specs.id,
          address_id: this.addressInof.id,
        },
        success: (res) => {
          this.ordersinfo = res.data
          this.order_id = res.data.order_id
          //  this.$toast(res.msg)
          //更换提示
          this.showcode_msg = res.msg
          this.success_show = true;



        },
        tip: () => { },
      })
    },

    //成功后跳页
    navorders() {
      this.$router.push({ name: "order", query: { active: 1 } })
    },

    // 支付密码确认
    onConfirmBtn(action, done) {
      if (action == "confirm") {
        if (!this.transactionPw) {
          this.$toast(this.$t("请输入交易密码"))
          done(false)
          return
        }
        this.$post({
          url: "/app/order_goods/pay",
          data: {
            order_id: this.order_id,
            password: this.transactionPw,
          },
          success: (res) => {
            this.$toast(this.$t("提交成功"))
            this.showSuccessful = true
            done()
          },
          tip: () => {
            done(false)
          },
        })
      } else {
        done()
      }
    },

    // 复制成功
    onCopy(e) {
      window.sessionStorage.setItem("order_sn", e.text);
      this.$toast(this.$t("复制成功"))
    },
    // 复制失败
    onError() {
      this.$toast(this.$t("复制失败"))
    },
  },
}
</script>

<style scoped lang='scss'>
/deep/.van-overlay {
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 80vw;
    height: 80vw;
    padding: 18vw 14vw 3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    .titblock {
      width: 100%;
      color: #fff;
      font-size: 4vw;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
      }
    }
  }
}

.boxa {
  background: url("~@/assets/img/a.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxb {
  background: url("~@/assets/img/b.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxc {
  background: url("~@/assets/img/c.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}


.grey {
  color: #999;
}

.greyBc {
  background-color: #f9f9f9;
}

.orange {
  color: #f13710;
}

.white {
  color: #fff;
}

.whiteBc {
  background-color: #fff;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop4 {
  margin-top: 4vw;
}

.padding04 {
  padding: 0 4vw;
}

.confirmOrder {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background-color: #fff;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
      font-size: 4vw;
    }
  }

  // 支付密码弹窗
  /deep/.van-dialog {
    top: 28vh
  }

  .content {
    min-height: 86.7vh;
    height: auto;

    .personalInformation {
      display: flex;
      align-items: center;
      margin-bottom: 3vw;

      .contact {
        padding: 4vw 0;
        width: 80vw;

        .address {
          font-size: 3.6vw;
        }
      }
    }

    .productInformation {
      padding: 0 4vw;

      .imgTite {
        display: flex;
        justify-content: space-between;
        padding: 4vw 0;
        border-bottom: 1px solid #f5f5f5;

        .tite {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 66vw;

          .specs {
            margin: 1vw 0;
            width: 100%;
            color: #f13710;
            font-size: 5vw;
            font-weight: 700;
          }

          .priceNum {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .altogether {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 13vw;
      }
    }
  }

  /deep/.van-goods-action {
    display: flex;
    align-items: center;
    width: 100%;

    .numPrice {
      display: flex;
      align-content: center;
      justify-content: flex-end;
      padding: 0 4vw;
      width: 68vw;
      font-size: 3.4vw;

      span {
        color: #f13b10;
        font-size: 3.6vw;
        font-weight: 550;
      }
    }

    .van-button {
      width: 32vw;
      height: 13vw;
      border-radius: 0vw;
    }

    .van-button--normal {
      padding: 0;
      font-size: 3vw;
    }

    .van-goods-action-button--last,
    .van-goods-action-button--first {
      margin: 0;
    }
  }

  // 确认订单弹窗
  .passwordPopup {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 4vw;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .pwInput {
      position: relative;
      margin-top: 61vw;
      width: 92vw;
      height: 48vw;
      background-color: #fff;
      overflow: hidden;

      >p {
        display: flex;
        justify-content: center;
        margin: 5vw 0 6vw;
      }

      .btn {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 92vw;
        height: 15vw;
        border-top: 1px solid #f9f9f9;

        .cancelBtn,
        .confirmBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 4vw;
        }

        .confirmBtn {
          border-left: 1px solid #f9f9f9;
        }
      }

      // /deep/.van-password-input__security {
      //   display: flex;
      //   justify-content: space-between;
      //   height: 9vw;
      // }
    }
  }

  // 支付成功弹窗
  .paymentSuccessfulPopup {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 4vw;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .paymentSuccessful {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 54vw;
      width: 92vw;
      height: 94vw;
      background-color: #fff;
      overflow: hidden;
      border-radius: 2vw;

      .closeIcon {
        position: absolute;
        top: 5vw;
        right: 4vw;
      }

      >p {
        display: flex;
        justify-content: center;
        margin-top: 5vw;
      }

      >span {
        margin: 6vw 0 3vw;
      }

      .orderNum {
        margin-bottom: 7vw;

        .btnCody {
          margin-left: 1vw;
          padding: 0 1vw;
          border-radius: 1vw;
          color: #fff;
          ;
          background-color: #f13710;
        }
      }

      .btn {
        display: flex;
        justify-content: center;

        /deep/.van-button {
          width: 71vw;
          height: 11vw;
          border-radius: 1vw;
        }

        /deep/.van-button--normal {
          padding: 0;
          font-size: 3vw;
        }
      }
    }
  }

  .popupbox {
    width: 100%;
    padding: 4vw 3vw 2vw;

    /deep/.van-button {
      height: 6vh;
      background-color: #f13b10;
      background: -webkit-linear-gradient(left, #f13b10, #f13b10) !important;
      background: linear-gradient(to right, #f13b10, #f13b10) !important;
    }

    .listbox {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      img {
        width: 25vw;
        height: 25vw;
        object-fit: cover;
      }

      .left {
        flex: 1;
        height: 25vw;
        padding: 0 2vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        >p {
          width: 100%;
          font-size: 3.6vw;

          span {
            font-size: 5vw;
            color: #e83323;
            font-weight: 550;
            margin-right: 3vw;
          }
        }

        .tit {
          width: 100%;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span:nth-child(1) {
            text-decoration: line-through;
          }

          span {
            font-size: 3.6vw;
            color: #999999 !important;
            font-weight: 500 !important;
          }
        }
      }

      margin-bottom: 2vw;
    }

    .listtit {
      width: 100%;
      color: #332311;
      font-size: 4vw;
      padding: 2vw 0;
      line-height: 2;
      font-weight: 600;
    }

    .numsbox {
      width: 100%;
      padding: 2vw 0 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      p {
        color: #999999;
        font-size: 3.5vwv;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 4vw;
        padding: 0 5vw;
        margin: 0 2vw 2vw 0;
      }

      .show {
        background: #f9f9f9;
        border: 1px solid #f13b10;
        color: #f13b10;
      }
    }


  }
}

.stepp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /deep/.van-stepper {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.stepp2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /deep/.van-stepper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>