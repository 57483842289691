<template>
  <!--其他等级购买的价格 -->
  <div class="gradeDiscount">
    <div class="header_box">
      <van-nav-bar :title="$t('转卖利润')" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>

    <div class="content">
      <div class="levelWelfare" v-for="ele in list" :key="ele.id">
        <div class="grade">{{ ele.vip_name }}</div>
        <div class="detailedBar">
          <div>
            <van-icon name="success" color="#fe0000" size="16" style="margin-right: 1vw" />
            <span>{{ $t('购买价格') }}:{{ ele.order_price }}</span>
          </div>
          <div>
            <van-icon name="success" color="#fe0000" size="16" style="margin-right: 1vw" />
            <span>{{ $t('转卖价格') }}:{{ ele.transfer_price }}</span>
          </div>
          <div>
            <van-icon name="success" color="#fe0000" size="16" style="margin-right: 1vw" />
            <span>{{ $t('转卖利润') }}:{{ $t('元') }}{{ ele.transfer_profit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      order_sn: '',
      list: []
    };
  },
  created() {
    if (this.$route.params.order_sn) {
      this.order_sn = this.$route.params.order_sn
    }
    this.get_price()
  },
  methods: {
    get_price() {
      this.$get({
        url: '/app/order_transfer/getOtherVipPrice',
        data: {
          order_sn: this.order_sn
        },
        success: (res) => {
          console.log(res)
          this.list = res.data
        }
      })
    }
  },
};
</script>

<style scoped lang='scss'>
.gradeDiscount {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    padding: 4vw;
    padding-top: 6.7vh;
    min-height: 100vh;
    height: auto;
    background: url("../../../assets/img/membershipBenefits_bc.png") no-repeat;
    background-size: 100%;

    .levelWelfare {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 3vw 5vw;
      margin-top: 4vw;
      width: 92vw;
      //height: 106vw;
      background-image: linear-gradient(156deg, #ffc98d, #e38c00);
      border-radius: 3vw;

      .grade {
        margin: 5vw 0 5vw 0;
        font-size: 5vw;
        color: #fff;
        font-family: Weibei SC;
        font-weight: bold;
      }

      .detailedBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 4vw 4vw;
        width: 87vw;
        border-radius: 2vw;
        color: #fff;
        background-color: #f7c24c;

        >div {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>