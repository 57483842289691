<template>
  <!-- 我的 -->
  <div class="mine">
    <header>
      <van-nav-bar :title="$t('我的')" :border="false">
        <template #right>
          <van-icon name="setting-o" size="18" @click="$router.push('mySet')" />
        </template>
      </van-nav-bar>

      <div class="user">
        <img src="../../assets/img/mine_use.png" alt="" class="headerimg" v-if="!userInfo.image" />
        <img :src="userInfo.image" alt="" class="headerimg" v-if="userInfo.image" />
        <div class="namebox">
          <div class="users">
            <p>
              <span class="name">{{ mask_code(userInfo.user_name) }}</span>
              <span class="level">{{ userInfo.vip_remark }}</span>
            </p>
          </div>
          <van-icon name="arrow" color="#fff" size="18" @click="$router.push('extension')" />
        </div>
      </div>
      <div class="lists">
        <div>
          <p class="num">{{ $t('元') }}{{ userInfo.balance }}</p>
          <p>{{ $t("余额钱包") }}</p>
        </div>
        <div @click="$router.push('currentMonthIncome')">
          <p class="num">{{ $t('元') }}{{ userInfo.transfer_profit }}</p>
          <p>{{ $t("本月收益") }}</p>
        </div>
      </div>
    </header>
    <div class="btn">
      <van-button type="primary" color="#F13B10" @click="goContact">{{ $t("充值") }}</van-button>
      <van-button type="primary" color="#F13B10" @click="readname_fun02">{{ $t("提现") }}</van-button>
    </div>
    <div class="navbox">
      <!-- <router-link to="myEvaluate" class="navs">
        <img src="../../assets/img/mine_evaluate_icon.png" alt="" class="left" />
        <p>{{ $t("我的评价") }}</p>
        <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
      </router-link> -->
      <router-link to="myCollection" class="navs">
        <img src="../../assets/img/mine_collection_icon.png" alt="" class="left" />
        <p>{{ $t("我的收藏") }}</p>
        <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
      </router-link>
      <router-link to="myAddress" class="navs">
        <img src="../../assets/img/mine_eceiving_address_icon.png" alt="" class="left" />
        <p>{{ $t("收货地址") }}</p>
        <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
      </router-link>
      <div @click="readname_fun01">
        <router-link to="" class="navs">
          <img src="../../assets/img/shiming_icon.png" alt="" class="left" />
          <p>{{ $t("身份证明书") }}</p>
          <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
        </router-link>
      </div>
      <router-link to="cashOutRecord" class="navs">
        <img src="../../assets/img/mine_cash_out_icon.png" alt="" class="left" />
        <p>{{ $t("提现记录") }}</p>
        <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
      </router-link>
      <div @click="goContact">
        <router-link to="" class="navs">
          <img src="../../assets/img/mine_customer_service_icon.png" alt="" class="left" />
          <p>{{ $t("联系客服") }}</p>
          <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
        </router-link>
      </div>

      <router-link to="fundDetails" class="navs">
        <img src="../../assets/img/mine_fund_details_icon.png" alt="" class="left" />
        <p>{{ $t("资金明细") }}</p>
        <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
      </router-link>
      <div @click="loginout" class="returnLogin">
        <router-link to="" class="navs">
          <img src="../../assets/img/mine_log_out_icon.png" alt="" class="left" />
          <p>{{ $t("退出登录") }}</p>
          <img src="../../assets/img/mine_right_arrow_icon.png" alt="" class="right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "mine",
    props: {},
    data() {
      return {
        userInfo: {},
        webSiteInfo: "",
        is_real: false,
      };
    },
    created() { },
    mounted() {
      this.getUserInfo();
      this.getWebSiteInfo();
    },
    methods: {
      readname_fun02() {
        if (this.is_real == 0) {

          this.$dialog
            .confirm({
              title: this.$t("提示"),
              message: 'Please submit your identification first',
            })
            .then(() => {
              this.xz_go('readname')
            })
            .catch(() => {
              // on cancel
            });


        }
        else if (this.is_real == 1) {
          this.xz_go('cashOut')
        }
        else if (this.is_real == 2) {
          this.$toast({
            message: "Under review",
            duration: 1500,
          });
        }
      },
      readname_fun01() {
        if (this.is_real == 0) {
          this.xz_go('readname')
        }
        else if (this.is_real == 1) {
          this.$toast({
            message: 'Certified',
            duration: 1500,
          });
        }
        else if (this.is_real == 2) {
          this.$toast({
            message: "Under review",
            duration: 1500,
          });
        }
      },
      // 获取用户信息
      getUserInfo() {
        this.$toast.loading({
          message: this.$t("加载中..."),
          forbidClick: true,
        });
        this.$get({
          url: "/app/user/getUserInfo",
          success: (res) => {
            this.$toast.clear();
            if (res.code == 1) {
              this.userInfo = res.data;
              this.is_real = res.data.is_real;
            }
          },
          tip: () => { },
        });
      },
      // 获取站点信息
      getWebSiteInfo() {
        this.$get({
          url: "/app/extend/getWebSiteInfo",
          success: (res) => {
            this.webSiteInfo = res.data;
          },
          tip: () => { },
        });
      },
      // 退出登录
      loginout() {
        this.$dialog
          .confirm({
            title: this.$t("提示"),
            message: this.$t("确认要退出登录吗?"),
          })
          .then(() => {
            this.$toast.clear();
            localStorage.removeItem("token");
            this.$toast({
              message: this.$t("退出登录成功"),
              duration: 1500,
            });
            setTimeout(() => {
              this.$router.replace("/login");
            }, 1500);
          })
          .catch(() => {
            // on cancel
          });
      },
      // 去联系（充值）
      goContact() {
        // window.location.href = this.webSiteInfo.mobile
        this.$router.push({
          name: "ChatWindow",
          query: {
            src: this.webSiteInfo.mobile,
          },
        });
      },
    },
  };
</script>

<style scoped lang='scss'>
  .mine {
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 0 0 8vh 0;
    header {
      //padding-bottom: 4vw;
      width: 100%;
      background: url("../../assets/img/mine_header_bc.png") no-repeat;
      background-size: 100% 100%;
      /deep/.van-nav-bar {
        background: none;
        .van-nav-bar__content {
          height: $navHeight;
        }
        .van-icon {
          color: #fff;
        }
        .van-nav-bar__title {
          font-size: 4.5vw;
          font-weight: bold;
          color: #fff;
        }
      }
      .user {
        width: 100%;
        display: flex;
        padding: 3vw 4vw 0;
        justify-content: flex-start;
        align-items: center;
        .headerimg {
          width: 17vw;
          height: 17vw;
          object-fit: cover;
          border-radius: 50%;
        }
        .namebox {
          flex: 1;
          padding: 0 0 0 2vw;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .users {
            width: 100%;
            .name {
              font-size: 4vw;
              font-weight: 550;
              color: #ffffff;
              margin-right: 3vw;
            }
            .level {
              font-size: 3vw;
              color: #fff;
            }
            .ids {
              font-size: 3vw;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .int {
            width: 8vw;
            height: 8vw;
          }
        }
      }
      .lists {
        width: 100%;
        padding: 2vw 2vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >div {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            color: #ffffff;
            font-size: 3vw;
          }
          .num {
            font-size: 5vw;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      padding: 1vw 9vw 2vw;
      border-bottom: 3vw solid #f9f9f9;
      /deep/.van-button {
        width: 35%;
        height: 10vw;
        border-radius: 2vw;
      }
      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
    // 内容
    .navbox {
      width: 100%;
      padding: 0vw 4vw;
      .returnLogin {
        //margin-bottom: 8vh;
      }
      .navs {
        width: 100%;
        height: 7vh;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        .left {
          width: 5vw;
          //height: 5vw;
          object-fit: cover;
        }
        p {
          flex: 1;
          padding-left: 2vw;
          color: #000000;
          font-size: 4vw;
        }
        .right {
          width: 1.5vw;
          height: 3vw;
        }
        // &:last-child {
        //   border-bottom: none;
        // }
      }
    }
  }
</style>