<template>
  <div class="container">
    <van-nav-bar :title="$t('注册协议')" fixed left-arrow @click-left="onClickLeft" :border="false" />
    <h1 class="title" v-html="list.title"></h1>
    <div class="content" v-html="list.content" v-if="list"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: '',
    }
  },
  created() {
    this.get_agree()
  },
  methods: {
    get_agree() {
      this.$get({
        url: '/app/extend/getAgreement',
        data: {
          type: 1,
        },
        success: (res) => {
          this.list = res.data
        },
        tip: () => { },
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  width: 100vw;
  min-height: 100vh;
  padding: 12vw 4vw 4vw;
  box-sizing: border-box;

  /deep/.van-nav-bar {
    background: none;

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      color: #000;
    }
  }

  .content {
    width: 100%;

    border-radius: 2vw;
    padding: 3vw;

    /deep/ img {
      width: 100%;
      margin: 2vw 0;
    }
  }
}
</style>
