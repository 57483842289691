<template>
  <!-- 添加或编辑地址 -->
  <div class="addOrEditAddress">
    <div class="header_box">
      <van-nav-bar :title="title" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>

    <div class="content">
      <van-field clearable v-model="name" :label="$t('姓名')" :placeholder="$t('收货人姓名')" />
      <van-field clearable v-model="phone" :label="$t('电话')" :placeholder="$t('收货人手机号')" maxlength="11" />
      <van-field clearable v-model="area_name" :label="$t('地区')" :placeholder="$t('请选择省 / 市 / 区')" readonly
        @click="choseAddress" />


      <van-field clearable v-model="area_id" :label="$t('邮政编码')" readonly :placeholder="$t('自动生成')" />
      <van-field clearable v-model="address" :label="$t('详细地址')" :placeholder="$t('街道门牌、楼层房间号等')" />

      <div class="van-cell van-field">
        <div class="oneline">
          <div class="moren">{{ $t('设为默认收货地址') }}</div>
          <van-switch v-model="is_default" active-color="#ff5e00" size="20" />
        </div>
      </div>
      <div class="btn">
        <van-button type="primary" color="#F13B10" @click="onSave">{{ $t('保存') }}</van-button>
        <!-- <van-button
          type="primary"
          color="#999"
          v-show="title == '编辑地址'"
          @click="onDelete(id)"
          >删除</van-button
        > -->
      </div>



      <van-popup v-model="show" position="bottom">
        <!-- <van-area :value="addcode" :area-list="areaList" @confirm="confirmchose" @cancel="choseAddress" /> -->

        <van-picker :title="$t('地区')" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="onCancel"
          @change="onChange" />

      </van-popup>
    </div>
  </div>
</template>

<script>
import areaList from "../../../assets/js/area";
export default {
  name: "addOrEditAddress",
  props: {},
  data() {
    return {
      id: "", //编辑地址的id
      title: "",
      pro_id: "", //   省主键id
      city_id: "", //	  市主键id
      area_id: "", //   区主键id
      address: "", //   详细地址
      name: "", //   收货人姓名
      phone: "", //   手机号
      is_default: false, //是否默认：1：默认 2：不默认
      pro_name: "", //  省名称
      city_name: "", // 市名称
      area_name: "", // 区名称

      add: "", //省 市 区组合
      show: false,
      addcode: "",
      areaList: areaList,
      searchResult: [],

      countries: [], // 国家列表  
      provinces: [], // 省份列表  
      cities: [],    // 城市列表  
      columns: [
        {}, // 国家列  
        {}, // 省份列  
        {}, // 城市列  
      ],
      selectedCountryIndex: 0,
      selectedProvinceIndex: 0,
      selectedCityIndex: 0,
    };
  },
  created() { },
  mounted() {

    if (this.$route.query.addOrEdit == "add") {
      this.title = this.$t("添加地址");
    } else {
      this.title = this.$t("编辑地址");
    }
    this.id = this.$route.query.id;
    this.getAddressDetail();
    this.get_address();
  },
  methods: {
    async get_address() {
      const response = await this.$get2({ url: "/app/us_codes/getStates" });// 替换为你的后端API 
      this.countries = response.data.map(country => { return { text: country.state, code: 0 } });
      this.columns[0] = { values: this.countries }

      const countryCode = this.countries[0].text; // 假设每个国家都有一个唯一的code  
      console.log('countryCode', countryCode)
      let response1 = await this.$get2({ url: `/app/us_codes/getCounties?state=${countryCode}` }); // 替换为你的
      this.provinces = response1.data.map(province => { return { text: province.county, code: 1 } });
      this.columns[1] = { values: this.provinces };

      let provinceCode = this.provinces[0].text; // 假设每个省份都有一个唯一的code  
      console.log('provinceCode', provinceCode)

      let response2 = await this.$get2({ url: `/app/us_codes/getCities?state=${countryCode}&county=${provinceCode}` }); // 替换为你的后端API
      this.cities = response2.data.map(city => { return { text: city.city, code: city.code } });
      this.columns[2] = { values: this.cities };

      console.log('this.columns', this.columns)

    },
    async get_address02(countryCode) {
      let response1 = await this.$get2({ url: `/app/us_codes/getCounties?state=${countryCode}` }); // 替换为你的后端API  
      this.provinces = response1.data.map(province => { return { text: province.county, code: 1 } });
      this.$set(this.columns, 1, { values: this.provinces });
      // 初始化城市为空数组  
      let provinceCode = this.provinces[0].text; // 假设每个省份都有一个唯一的code  
      console.log('provinceCode', provinceCode)
      this.get_address03(countryCode, provinceCode);
    },

    async get_address03(countryCode, provinceCode) {
      let response2 = await this.$get2({ url: `/app/us_codes/getCities?state=${countryCode}&county=${provinceCode}` }); // 替换为你的后端API

      this.cities = response2.data.map(city => { return { text: city.city, code: city.code } });
      this.$set(this.columns, 2, { values: this.cities });
      console.log('this.columns', this.columns)

    },
    onChange(picker, values, index) {
      console.log(values, '有变化', index)
      // const [countryIndex, provinceIndex, cityIndex] = values;
      // console.log(countryIndex, provinceIndex, cityIndex)
      // this.selectedCountryIndex = countryIndex;
      if (index == 0) { // 如果不是最后一个国家（即用户正在选择国家）  
        this.get_address02(values[0].text);
      } else if (index == 1) { // 如果不是最后一个省份（即用户正在选择省份）  
        this.get_address03(values[0].text, values[1].text);
      } else {
        // 用户正在选择城市，不需要额外操作，因为城市列表已经根据省份更新过了  
      }
      // 更新选中的省份和城市索引（如果适用）  
      // this.selectedProvinceIndex = provinceIndex;
      // this.selectedCityIndex = cityIndex > this.columns[2].length - 1 ? 0 : cityIndex; // 防止数组越界  
    },

    onConfirm(area) {

      console.log('areaareaareaareaarea', area);
      this.area_name = area[0].text + " " + area[1].text + " " + area[2].text;
      // this.pro_name = area[0].text;
      // this.city_name = area[1].text;
      // this.area_name = area[2].text;
      // this.pro_id = area[0].code;
      // this.city_id = area[1].code;
      this.area_id = area[2].code;
      this.show = false;
    },
    onCancel() {
      this.show = false
    },
    // 获取要编辑的地址详情
    getAddressDetail() {
      if (this.id) {
        this.$toast.loading({
          message: this.$t("加载中..."),
          forbidClick: true,
        });
        this.$get({
          url: "/app/region/detail",
          data: { id: this.id },
          success: (res) => {
            this.$toast.clear();
            this.pro_id = res.data.pro_id; //   省主键id
            this.city_id = res.data.city_id; //	  市主键id
            this.area_id = res.data.area_id; //   区主键id
            this.address = res.data.address; //   详细地址
            this.name = res.data.name; //   收货人姓名
            this.phone = res.data.phone; //   手机号
            this.is_default = res.data.is_default == 1 ? true : false; //是否默认：1：默认 2：不默认
            this.pro_name = res.data.pro_name; //  省名称
            this.city_name = res.data.city_name; // 市名称
            this.area_name = res.data.area_name; // 区名称
            //
            this.add =
              res.data.pro_name +
              " " +
              res.data.city_name +
              " " +
              res.data.area_name;
          },
          tip: () => { },
        });
      }
    },
    //选择城市后
    confirmchose(area) {
      console.log('areaareaareaareaarea', area);
      this.add = area[0].text + " " + area[1].name + " " + area[2].name;
      this.pro_name = area[0].name;
      this.city_name = area[1].name;
      this.area_name = area[2].name;
      this.pro_id = area[0].code;
      this.city_id = area[1].code;
      this.area_id = area[2].code;
      this.show = false;
    },
    choseAddress() {
      this.show = !this.show;
    },
    // 添加编辑地址
    onSave() {
      // if (!this.area_name) return this.$toast("选择地区")
      let url = "";
      let obj = {};
      if (this.id) {
        // 编辑
        url = "/app/region/edit";
        obj = {
          id: this.id,
          pro_id: this.pro_id, //   省主键id
          city_id: this.city_id, //	  市主键id
          area_id: this.area_id, //   区主键id
          address: this.address, //   详细地址
          name: this.name, //   收货人姓名
          phone: this.phone, //   手机号
          is_default: this.is_default == true ? 1 : 2, //是否默认：1：默认 2：不默认
          pro_name: this.pro_name, //  省名称
          city_name: this.city_name, // 市名称
          area_name: this.area_name, // 区名称
        };
      } else {
        // 添加
        url = "/app/region/add";
        obj = {
          pro_id: this.pro_id, //   省主键id
          city_id: this.city_id, //	  市主键id
          area_id: this.area_id, //   区主键id
          address: this.address, //   详细地址
          name: this.name, //   收货人姓名
          phone: this.phone, //   手机号
          is_default: this.is_default == true ? 1 : 2, //是否默认：1：默认 2：不默认
          pro_name: this.pro_name, //  省名称
          city_name: this.city_name, // 市名称
          area_name: this.area_name, // 区名称
        };
      }

      this.$post({
        url: url,
        data: obj,
        success: () => {
          this.$toast.clear();
          this.$toast({
            message: "添加成功",
            duration: 1500,
          });
          this.$router.go(-1)
          // 用了replace，返回就会失败
          // setTimeout(() => {
          //   this.$router.replace("/myAddress");
          // }, 1500);
        },
        tip: () => { },
      });
    },
    // 删除地址
    // onDelete(e) {
    //   this.$dialog
    //     .confirm({
    //       title: "提示",
    //       message: "确认要删除吗?",
    //     })
    //     .then(() => {
    //       this.$post({
    //         url: "/app/region/delete",
    //         data: { id: e },
    //         success: () => {
    //           this.$toast.clear();
    //           this.$toast({
    //             message: "删除成功",
    //             duration: 1500,
    //           });
    //           setTimeout(() => {
    //             this.$router.replace("/myAddress");
    //           }, 1500);
    //         },
    //         tip: () => {},
    //       });
    //     })
    //     .catch(() => {
    //       // on cancel
    //     });
    // },
  },
};
</script>

<style scoped lang='scss'>
/deep/.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 3vw;
}

.addOrEditAddress {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    /deep/.van-cell {
      width: 100vw;
      height: 14vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      // width: 92.53vw;
      // display: block;
      // box-shadow: 0vw 0vw 4.27vw 0vw rgba(96, 98, 102, 0.08);
      // border-radius: 2.13vw;
      // padding: 0 16px;
      // margin: 3.73vw;
      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {}
    }

    /deep/.van-cell::after {
      border: 0;
    }

    .btn {
      margin: 21vh auto 0;
      width: 79vw;

      /deep/.van-button {
        margin: 0 auto;
        width: 79vw;
        height: 15vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }

    .oneline {
      display: flex;
      line-height: 12.8vw;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 92vw;

      &:last-child {
        border: none;
      }

      .name {
        width: 30%;
        height: 9vw;
        font-size: 4vw;
        font-weight: bold;
      }

      /deep/.text {
        font-size: 3.5vw;
      }

      .moren {
        font-size: 4vw;
        font-weight: bold;
        color: #646566;
      }

      input {
        flex: 1;
        font-size: 3.5vw;
        font-weight: 400;
        background: #fff;
        width: 90%;
      }

      input::-webkit-input-placeholder {
        font-size: 3.5vw;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }

      img {
        width: 5.87vw;
        height: 5.87vw;
        float: right;
        margin-top: 3.5vw;
      }

      textarea {
        flex: 1;
        font-size: 3.5vw;
        font-weight: 400;
        background: #fff;
        border: none;
        line-height: 4.5vw;
      }

      textarea::-webkit-input-placeholder {
        font-size: 3.5vw;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
      }
    }

    .block {
      display: block;
    }
  }
}
</style>