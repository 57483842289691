<template>
  <div class="order">
    <van-nav-bar :title="$t('全部订单')" :border="false" />
    <van-tabs background="#Ffff" border v-model="active" @click="change" sticky>
      <van-tab v-for="(ele, index) in tab" :key="index" :title="ele.title">
        <div class="container">
          <van-pull-refresh v-model="Down" :success-text="$t('刷新成功')" @refresh="get_down">
            <van-list v-model="up" :finished="over" :finished-text="finished_text" @load="get_up"
              :immediate-check="false">
              <div class="content">
                <van-empty :description="$t('这里空空如也~')" v-if="list && list.length == 0" />

                <div class="orderInformation" v-for="( item, index ) in list" :key="index">
                  <div class="orderState">
                    <div class="sn_box">
                      <span class="sn"> {{ $t('订单号') }}:{{ item.sn }} </span>
                      <span class="btnCody" v-clipboard:copy="item.sn" v-clipboard:success="onCopy"
                        v-clipboard:error="onError">{{ $t('复制') }}</span>
                    </div>
                    <div class="state">
                      <span>
                        {{
                          item.state == -1
                            ? $t("待支付")
                            : item.state == 1
                              ? $t("已支付")
                              : item.state == 2
                                ? $t("已发货")
                                : item.state == 3
                                  ? $t("已收货")
                                  : item.state == 4
                                    ? $t("已完成")
                                    : item.state == 5
                                      ? $t("退货申请中")
                                      : item.state == 6
                                        ? $t("退货中待发货")
                                        : item.state == 7
                                          ? $t("退货中已发货")
                                          : item.state == 8
                                            ? $t("退款成功")
                                            : item.state == 9
                                              ? $t("已取消")
                                              : item.state == 10
                                                ? $t("转卖中")
                                                : item.state == 11
                                                  ? $t("转卖完成")
                                                  : "" }}
                      </span>
                      <span>
                        {{
                          item.transfer_state == 2
                            ? $t("转卖中")
                            : item.transfer_state == 3
                              ? $t("转卖成功")
                              : item.transfer_state == 4
                                ? $t("转卖失败")
                                : "" }}
                      </span>
                    </div>
                  </div>
                  <div v-for="( ele, index ) in item.goods_list " :key="index">
                    <div class="imgInformation" @click="navdel(ele)">
                      <img :src="ele.goods_thumb" alt="" style="
                          width: 23vw;
                          height: 23vw;
                          border-radius: 2vw;
                          overflow: hidden;
                          margin-right: 3vw;
                          object-fit: cover;
                        " />
                      <div class="information">
                        <p class="name two">{{ ele.goods_name }}</p>
                        <span class="specs">
                          <div class="colorSize">
                            <span class="titeColor font3"></span>
                          </div>
                          <div class="priceNum">
                            <span class="priceColor priceFont one">
                              {{ $t('元') }}{{ ele.discount_price }}</span>
                            <span class="titeColor font3">X{{ ele.amount }}</span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <div v-if="item.order_part.length>0">
                    <p class="order_part" v-for="orderval in item.order_part" :key="orderval.id">
                      <span>{{orderval.order_id}}</span>
                      <span>-</span>
                      <span>{{orderval.pay_price}}</span>
                    </p>
                  </div> -->
                  <p class="ordernum">
                    <span v-if="item.type">{{ $t('订单类型') }}：{{
                      item.type == 1
                        ? $t("商品购买")
                        : item.type == 2
                          ? $t("幸运抢购")
                          : item.type == 3
                            ? $t("幸运爆单")
                            : $t("幸运连单") }}
                    </span>
                    <span v-if="item.order_total_num > 0">{{ $t('订单总数') }}：{{ item.order_total_num }}</span>
                  </p>

                  <div class="altogether">
                    <div class="time" v-if="item.state == -1">
                      {{ $t('倒计时') }}
                      <van-count-down :time="item.pay_expire_time * 1000 | get_time" />
                    </div>
                    <div></div>
                    <div style="display: flex">
                      <div class="titeColor font3">
                        {{ $t('共') }}{{ item.total_amount }}{{ $t('件') }} {{ $t('合计') }}：
                      </div>
                      <div>
                        <span class="priceColor font3"></span>
                        <span class="priceColor font4 fontBold">
                          {{ $t('元') }}{{ item.pay_price }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="btn">
                    <van-button type="primary" color="#F13B10" round v-show="item.state == -1"
                      @click="onOrderPayBtn(item.state, item.id, item.pay_expire_time * 1000)">{{ $t('立即支付')
                      }}</van-button>
                    <van-button type="primary" color="#FFB100" round v-show="item.state == 2"
                      @click="onOrderBtn(item.state, item.id)">{{ $t('申请退货') }}</van-button>
                    <van-button type="primary" color="#F13B10" round v-show="item.state == 2"
                      @click="onOrderBtn(item.state, item.id)">{{ $t('确认收货') }}</van-button>
                    <van-button type="primary" color="#F13B10" round v-show="item.state == 3"
                      @click="onOrderBtn(item.state, item.id)">{{ $t('去评价') }}</van-button>
                    <van-button type="primary" color="#F13B10" round v-show="item.state == 5 || item.state == 6 || item.state == 7
                      " @click="onOrderBtn(item.state, item.id)">{{ $t('退款中') }}</van-button>


                    <!-- <van-button type="primary" color="#F13B10" round v-show="item.state == -1
      " @click="onOrderPayBtn(item.state, item.id)">{{ $t('查看详情') }}</van-button> -->.

                    <van-button type="primary" color="#F13B10" round v-show="item.state == 1 ||
                      item.state == 4 ||
                      item.state == 8 ||
                      item.state == 9 ||
                      item.state == 10 ||
                      item.state == 11
                      " @click="onOrderBtn(item.state, item.id)">{{ $t('查看详情') }}</van-button>
                    <van-button type="primary" color="#F13B10" round @click="
                      $router.push({
                        name: 'gradeProfit',
                        query: { sn: item.sn },
                      })
                      ">{{ $t('等级利润') }}</van-button>
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "order",
  props: {},
  data() {
    return {
      pay_expire_time: 30 * 60 * 60 * 1000,
      active: 0,
      list: null,
      tab: [
        { title: this.$t("全部"), value: 0 },
        { title: this.$t("待支付"), value: -1 },
        { title: this.$t("已支付"), value: 1 },
        { title: this.$t("已发货"), value: 2 },
        { title: this.$t("已收货"), value: 3 },
        // { title: this.$t("已完成"), value: 4 },
        { title: this.$t("售后"), value: 5 },
      ],
      finished_text: "",
      page: 1,
      loading: false,
      Down: false,
      up: false,
      over: false,
    }
  },
  filters: {
    get_time(times) {
      var date = new Date() //当前时间
      var time = date.getTime()
      var dates = new Date(times) //进行时间
      var timea = dates.getTime()
      let sj = timea - time
      return sj
    },
  },
  watch: {
    active: function (newVal, oldVal) {
      this.list_init()
    },
  },
  created() {
    //滚动到顶部
    this.xz_top();
    if (sessionStorage.getItem("orderindex")) {
      this.active = Number(sessionStorage.getItem("orderindex"))
    }
    if (this.$route.query.active) {
      this.active = this.$route.query.active
    }
    this.list_init()
  },
  mounted() { },
  methods: {
    finish() {
      console.log("倒计时结束")
      //this.list_init();

      // this.getOrderDetails(this.order_id)
    },
    navdel(data) {
      if (data.is_seckill == 1) {
        this.$router.push({
          name: "RushGoodsDetail",
          query: { id: data.goods_id },
        })
      } else {
        this.$router.push({
          name: "productDetailsOrEvaluation",
          query: { id: data.goods_id },
        })
      }
    },
    change(index) {
      sessionStorage.setItem("orderindex", index)
      this.active = index
    },
    //初始化数据
    list_init() {
      this.page = 1
      this.over = false
      this.get_list("down") // 加载数据
      console.log(this.list)
    },
    //下拉刷新 下拉的这个 调了getlistla
    get_down() {
      setTimeout(() => {
        this.page = 1
        this.over = false
        this.get_list("down") // 加载数据
      }, 1000)
    },
    // 上拉加载 上拉这个 调用getlist
    get_up() {
      this.page++
      this.get_list("up") // 加载数据
    },
    // 更多广告
    get_list(type) {
      this.$get({
        url: "/app/order_goods/list",
        data: {
          page: this.page,
          state: this.tab[this.active].value,
        },
        success: (res) => {
          let data = res.data.list
          // console.log("返回的数据", data)
          if (type == "down") {
            this.list = data
            this.$emit("list", this.list)
          } else if (type == "up") {
            if (this.list) {
              this.list = [...this.list, ...data]
              this.$emit("list", this.list)
            }
            if (data.length == 0) {
              this.over = true
            }
          }
          this.Down = false
          this.up = false
        },
        tip: (val) => {
          this.over = true
        },
      })
    },
    // 点击进订单详情
    onOrderBtn(state, id) {
      this.$router.push({
        name: "orderDetails",
        query: { state: state, id: id },
      })
    },

    onOrderPayBtn(state, id, times) {

      var date = new Date() //当前时间
      var time = date.getTime()
      var dates = new Date(times) //进行时间
      var timea = dates.getTime()
      let sj = timea - time
      if (sj > 0) {
        this.$router.push({
          name: "orderDetails",
          query: { state: state, id: id },
        })
      } else {
        this.$toast(this.$t("订单超时了,请联系客服"))
      }


    },
    // 复制成功
    onCopy(e) {
      window.sessionStorage.setItem("order_sn", e.text)
      this.$toast(this.$t("复制成功"))
    },
    // 复制失败
    onError() {
      this.$toast(this.$t("复制失败"))
    },
  },
}
</script>

<style scoped lang='scss'>
.order {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 12vh 0;
  background: #f9f9f9;

  .font3 {
    font-size: 3vw;
  }

  .font4 {
    font-size: 4vw;
  }

  .fontBold {
    font-weight: bold;
  }

  /deep/.van-nav-bar {
    background: #fff;

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  /deep/.van-tabs--line .van-tabs__wrap {
    height: 6vh;
  }

  /deep/.van-tabs__nav--line.van-tabs__nav--complete {
    padding-right: 0;
    padding-left: 0;
  }

  /deep/.van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-top-width: 0px;
  }

  .container {
    width: 100%;
    height: auto;

    .content {
      height: auto;
    }

    .orderInformation {
      width: 100%;
      height: auto;
      background: #fff;
      padding: 0 4vw 4vw;
      border-bottom: 3vw solid #f9f9f9;

      .titeColor {
        color: #999;
      }

      .priceColor {
        color: #f13710;
      }

      .orderState {
        display: flex;
        flex-direction: column;
        padding: 2vw 0;

        // justify-content: space-between;
        // align-items: center;
        //height: 13vw;
        .sn_box {
          display: flex;
          justify-content: space-between;

          .sn {
            font-size: 3vw;
          }

          .btnCody {
            padding: 0 1vw;
            border-radius: 1vw;
            color: #fff;
            ;
            background-color: #f13710;
          }
        }

        .state {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 4vw;
            font-weight: 500;
            color: #f13b10;
            white-space: nowrap;
          }
        }
      }

      .imgInformation {
        display: flex;
        padding: 4vw 0;
        border-bottom: 1px solid #f5f5f5;
        border-top: 1px solid #f5f5f5;

        .information {
          width: 100%;

          .name {
            width: 100%;
            font-size: 4vw;
            font-weight: 550;
            color: #000;
          }

          .specs {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 2vw;

            .colorSize {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }

            .priceNum {
              display: flex;
              flex-direction: column;
              align-items: flex-end;

              .priceFont {
                font-size: 4vw;
                font-weight: bold;
              }

              span:nth-last-child(1) {
                margin-top: 1vw;
              }
            }
          }
        }
      }

      .ordernum {
        width: 100%;
        color: #999;
        font-size: 3.2vw;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2vw;

        span:nth-child(1) {
          margin-right: 3vw;
        }
      }

      .altogether {
        width: 100%;
        padding: 1vw 0;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        font-size: 3.2vw;
      }

      .time {
        padding: 1vw 0;
        display: flex;
        // justify-content: flex-end;
        // align-items: flex-end;
        font-size: 3.2vw;

        /deep/.van-count-down {
          color: #f13b10;
          font-size: 4vw;
          line-height: 20px;
        }
      }

      .btn {
        margin-top: 2vw;
        display: flex;
        justify-content: flex-end;

        /deep/.van-button {
          margin-left: 4vw;
          width: 23vw;
          height: 8vw;
        }

        /deep/.van-button--normal {
          padding: 0;
          font-size: 3vw;
        }
      }
    }
  }
}
</style>