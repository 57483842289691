import Vue from 'vue'
import App from './App'
import router from './router/index'
import http from './request/http'
import 'vant/lib/index.css';
import './assets/css/reset.css';
// 宫政的工具开始
import vue_fun from './function/vue_fun.js';
import './function/fun.js';
Vue.use(vue_fun);
import vant_extend from '@/vant-extend';
Vue.use(vant_extend);
Vue.use(http)
Vue.config.productionTip = false;
//复制粘贴
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
//常规验证码 运算验证码 滑动验证码 拼图验证码 选字验证码
import Verify from 'vue2-verify'
Vue.component('Verify', Verify)

import { i18n } from './utils/i18n'

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')