<template>
  <!-- 搜索商品 -->
  <div class="searchCommodity">
    <div class="header_box">
      <van-nav-bar :title="$t('搜索商品')" left-arrow @click-left="$router.go(-1)" :border="false" />
      <div class="searchBox">
        <van-search v-model="searchContent" shape="round" :placeholder="$t('请输入搜索关键词')">
          <template #right-icon>
            <div @click="onSearch()">
              <van-icon name="search" size="18" />
            </div>
          </template>
        </van-search>
      </div>
    </div>

    <div class="container">
      <vant-up-down ref="child" @list="(val) => (searchList = val)" res="res.data.list" url="/app/goods/list" :data="{
        search: searchContent,
        cat_id: 0,
        brand_id: 0,
        is_recommend: 0,
        is_seckill: 1,
      }">
        <div class="content">
          <div class="list" v-for="ele in searchList" :key="ele.id">
            <div class="productInformation">
              <div class="imgTite">
                <img :src="ele.goods_thumb" alt="" style="
                    width: 23vw;
                    height: 23vw;
                    border-radius: 2vw;
                    overflow: hidden;
                    margin: 4vw 3vw 4vw 0vw;
                  " />
                <div class="tite">
                  <h3>{{ ele.goods_name }}</h3>
                  <div class="priceNum">
                    <div class="orange font4">
                      {{ $t('抢购价') }}：<span class="font4 fontBold">
                        {{ $t('元') }}{{ ele.discount_price }}
                      </span>
                      <div class="num grey">{{ $t('已售') }}{{ ele.goods_sales }}{{ $t('件') }}</div>
                    </div>
                    <div>
                      <div class="originalPrice grey" style="font-size: 3.5vw">
                        {{ $t('元') }}{{ ele.goods_price }}
                      </div>
                      <div class="immi" v-if="begin < +new Date() / 1000 && end > +new Date() / 1000
                      " @click="makePanicPurchase(ele)">
                        {{ $t('立即抢购') }}
                      </div>
                      <div class="nostart" v-if="begin > +new Date() / 1000">
                        {{ $t('未开始') }}
                      </div>
                      <div class="finish" v-if="end < +new Date() / 1000">
                        {{ $t('已结束') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>

    <van-overlay :show="show">
      <div class="wrapper boxa" @click.stop v-if="showcode.code == 1">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="navorders">
          {{ $t('确定') }}
        </van-button>
      </div>

      <div class="wrapper boxb" @click.stop v-if="showcode.code == 2">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D"
          @click="$router.replace({ name: 'order', query: { active: 1 } })">
          {{ $t('关闭') }}
        </van-button>
      </div>

      <div class="wrapper boxc" @click.stop v-if="showcode.code === 0">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="show = false">
          {{ $t('关闭') }}
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      searchContent: "",
      searchList: [],
      begin: "",
      end: "",

      show: false,
      showcode: {},
      orders: {},
    };
  },
  created() {
    this.searchContent = this.$route.query.searchContent;
    console.log(this.searchContent);
  },
  mounted() {
    this.get_SeachGoodes();
  },
  methods: {
    onSearch() {
      this.$refs.child.list_init();
    },
    get_SeachGoodes() {
      this.$get({
        url: "/app/goods/list",
        data: {
          search: this.searchContent,
          cat_id: 0,
          brand_id: 0,
          is_recommend: 0,
          is_seckill: 1,
        },
        success: (res) => {
          // console.log(res);
          // this.searchList = res.data.list;
          this.begin = res.data.seckill_set.begin_time;
          this.end = res.data.seckill_set.end_time;
        },
        tip: () => { },
      });
    },

    makePanicPurchase(data) {
      this.$post({
        url: "/app/order_goods/makePanicPurchase",
        data: {
          goods_id: data.id,
        },
        success: (res) => {
          this.showcode = res;
          this.orders.seckill_id = res.data.seckill_id;
          this.orders.id = data.id;
          this.show = true;
        },
        tips: (err) => {
          console.log(12, err);
          this.showcode = err;
          this.show = true;
        },
      });
    },
    navorders() {
      this.$router.push({
        name: "RushconfirmOrder",
        query: {
          seckill_id: this.orders.seckill_id,
          goods_id: this.orders.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.van-overlay {
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 80vw;
    height: 80vw;
    padding: 18vw 14vw 3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    .titblock {
      width: 100%;
      color: #fff;
      font-size: 4vw;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
      }
    }
  }
}

.boxa {
  background: url("../../../assets/img/a.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxb {
  background: url("../../../assets/img/b.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxc {
  background: url("../../../assets/img/c.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.grey {
  color: #999;
}

.orange {
  color: #f13710;
}

.searchCommodity {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: calc(6.7vh + 54px);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  .searchBox {
    height: 7.3vh;

    /deep/.van-search {
      flex: 1;

      .van-field__left-icon {
        display: none;
      }
    }
  }

  .container {
    min-height: 84vh;
    height: auto;
  }

  // 内容
  .content {
    padding: 0 4vw;
    min-height: 84vh;

    .productInformation {
      .imgTite {
        display: flex;
        justify-content: space-between;

        .tite {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 4vw 0;
          width: 66vw;
          border-bottom: 1px solid #f5f5f5;

          >h3 {
            /*文字溢出隐藏显示省略号*/
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .priceNum {
            display: flex;
            justify-content: space-between;
            align-content: flex-end;

            .originalPrice {
              text-decoration: line-through;
              transform: scale(0.9);
            }

            .num {
              display: flex;
              align-items: flex-end;
              margin-top: 2vw;
            }

            .finish,
            .nostart,
            .immi {
              padding: 1vw;
              background: burlywood;
              text-align: center;
              margin-top: 1vw;
            }

            .nostart {
              background: coral;
            }

            .immi {
              background: pink;
            }
          }
        }
      }
    }
  }
}
</style>
