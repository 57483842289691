<template>
  <!-- 我的评价 -->
  <div class="myEvaluate">
    <div class="header_box">
      <van-nav-bar title="评价" left-arrow @click-left="$router.replace('mine')" :border="false" />
      <div class="allEvaluate padding04">{{ $t('共') }}{{ list.length }}{{ $t('条评价') }}</div>
    </div>

    <div class="content">
      <vant-up-down @list="(val) => (list = val)" res="res.data.list" url="/app/order_goods/getCommentList" :data="{}">
        <div class="list" v-for="ele in list" :key="ele.id">
          <div class="use padding04">
            <div class="imgNane">
              <img :src="userInfo.image" alt="" style="
                  width: 10vw;
                  height: 10vw;
                  overflow-y: hidden;
                  border-radius: 10vw;
                " />
              <p class="fontBold font4">{{ mask_code(userInfo.user_name) }}</p>
            </div>
            <div class="grey">{{ ele.create_time }}</div>
          </div>
          <span class="padding04 marginTop2 grey">{{ $t('评论内容') }}：{{ ele.content }}</span>
          <div class="imgList padding04 marginTop2">
            <img v-for="(img, index) in ele.imge_atlas" :key="index" :src="img" alt="" style="
                width: 28.6vw;
                height: 28vw;
                border-radius: 2vw;
                margin-left: 2vw;
                object-fit: cover;
              " />
          </div>
          <div class="orderInformation padding04">
            <div class="productInformation padding04">
              <div class="imgTite">
                <img src="../../../assets/img/resell_bc.png" alt="" style="
                    width: 15vw;
                    height: 15vw;
                    border-radius: 2vw;
                    overflow: hidden;
                    margin-right: 2vw;
                    object-fit: cover;
                  " />
                <div class="tite">
                  <p>{{ $t('订单号') }}：{{ ele.order_sn }}</p>
                  <div class="priceNum fontBold font4">
                    {{ $t('元') }}{{ ele.pay_price }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>
  </div>
</template>

<script>
import vantUpDown from "../../../vant-extend/vant-up-down/vant-up-down.vue";
export default {
  components: { vantUpDown },
  name: "",
  props: {},
  data() {
    return {
      userInfo: "",
      evaluateList: { list: [] },
      list: [],
    };
  },
  created() { },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/user/getUserInfo",
        success: (res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.userInfo = res.data;
          }
        },
        tip: () => { },
      });
    },
    // 获取评价列表
    // getEvaluateList() {
    //   this.$toast.loading({
    //     message: "加载中...",
    //     forbidClick: true,
    //   });
    //   this.$get({
    //     url: "/app/order_goods/getCommentList",
    //     success: (res) => {
    //       this.evaluateList = res.data
    //     },
    //   });
    // },
  },
};
</script>

<style scoped lang='scss'>
.grey {
  color: #8c9198;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop2 {
  margin-top: 2vw;
}

.marginTop4 {
  margin-top: 4vw;
}

.padding04 {
  padding: 0 4vw;
}

.myEvaluate {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: calc(6.3vh + 7vh);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  .allEvaluate {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 7vh;
    background-color: #f9f9f9;
  }

  // 内容
  .content .VantUpDown {
    min-height: calc(100vh - 6.3vh - 7vh);
    height: auto;

    .list {
      display: flex;
      flex-direction: column;
      padding: 4vw 0;
      border-bottom: 3vw solid #f9f9f9;

      .use {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .imgNane {
          display: flex;
          align-items: center;

          >p {
            margin-left: 2vw;
          }
        }
      }

      .imgList {
        display: flex;
        flex-wrap: wrap;
      }

      .orderInformation {
        .productInformation {
          width: 92vw;
          height: 22vw;
          background-color: #f8f8f8;
          border-radius: 2vw;

          .imgTite {
            display: flex;
            justify-content: space-between;
            padding: 3vw 0;

            .tite {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 67vw;

              .priceNum {
                display: flex;
                justify-content: space-between;
                color: #f13710;
              }
            }
          }
        }
      }
    }
  }
}
</style>