<template>
  <!-- 产品列表 -->
  <div class="productList">
    <div class="header_box">
      <van-search v-model="searchVal" shape="round" background="#F13B10" :placeholder="$t('请输入搜索关键词')">
        <template #right-icon>
          <div @click="onSearch(searchVal)">
            <van-icon name="search" size="18" />
          </div>
        </template>
        <template #left>
          <div style="margin-right: 2vw" @click="xz_back">
            <van-icon name="arrow-left" color="#FFFFFF" />
          </div>
        </template>
      </van-search>

      <div class="sort">
        <div class="comprehensive" :class="selectStyle == 1 ? 'cur' : ''" @click="changeTab(1)">
          {{ $t('综合') }}
        </div>
        <div class="price" :class="selectStyle == 2 ? 'cur' : ''" @click="changeTab(2)">
          {{ $t('价格') }}
          <img :src="getSortPriceUrl" style="width: '1vw'; height: 2.5vw" alt="" />
        </div>
        <div class="salesVolume" :class="selectStyle == 3 ? 'cur' : ''" @click="changeTab(3)">
          {{ $t('销量') }}
          <img :src="getSortSalesUrl" style="width: '1vw'; height: 2.5vw" alt="" />
        </div>
      </div>
    </div>

    <div class="container">
      <vant-up-down ref="child" @list="(val) => (productList = val)" res="res.data.list" url="/app/goods/list" :data="{
        search: searchVal,
        cat_id: 0,
        brand_id: id,
        is_recommend: 0,
        is_seckill: 2,
        sort_price: sort_price,
        sort_sales: sort_sales,
      }">
        <div class="content">
          <div class="productInformation" v-for="(ele, index) in productList" :key="index"
            @click="xz_go('productDetailsOrEvaluation', ele.id)">
            <img :src="ele.goods_thumb" alt="" style="
                width: 44vw;
                height: 49vw;
                border-radius: 2vw;
                overflow: hidden;
              " />
            <h3>{{ ele.goods_name }}</h3>
            <span class="price_quantitySold">
              <div class="price">{{ $t('元') }}{{ ele.discount_price }}</div>
              <div class="quantitySold">{{ ele.goods_sales }}{{ $t('人') }}{{ $t('付款') }}</div>
            </span>
          </div>
        </div>
      </vant-up-down>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandGoods",
  props: {},
  data() {
    return {
      id: this.xz_id(),
      searchVal: "",
      productList: [
        {
          id: 0,
          goods_thumb: "",
          goods_name: "dsfa",
          discount_price: "asdfad",
          goods_sales: "fdasf",
        },
      ],

      // 排序    正序 :ASC ：倒序 ：DESC   空
      sort_price: "ASC", //默认正序
      sort_sales: "", //默认空
      selectStyle: 1, //判断选用样式用
    };
  },
  computed: {
    // 价格
    getSortPriceUrl() {
      if (this.sort_price == "") {
        // 未按
        return require(`../../../assets/img/sort_un.png`);
      } else if (this.sort_price == "ASC") {
        // 正序
        return require(`../../../assets/img/sort_up.png`);
      } else {
        // 倒叙序
        return require(`../../../assets/img/sort_down.png`);
      }
      return require(`../../../assets/img/sort_un.png`);
    },
    // 销量
    getSortSalesUrl() {
      if (this.sort_sales == "") {
        // 未按
        return require(`../../../assets/img/sort_un.png`);
      } else if (this.sort_sales == "DESC") {
        // 正序
        return require(`../../../assets/img/sort_down.png`);
      } else {
        // 倒叙序
        return require(`../../../assets/img/sort_up.png`);
      }
      return require(`../../../assets/img/sort_un.png`);
    },
  },
  created() {
    //this.get_brank();
  },
  methods: {
    // 获取tab列表
    // get_brank() {
    //   this.$get({
    //     url: "/app/goods/getBrandList",
    //     data: {
    //       cat_id: this.id,
    //     },
    //     success: (res) => {
    //       this.tabList = res.data;
    //       this.tabList.unshift({ brand_name: "全部" });
    //       // this.brand_id = this.tabList[0].id
    //       this.changeTab(this.active);
    //     },
    //   });
    // },
    changeTab(e) {
      this.selectStyle = e;
      if (e == 1) {
        // 综合
        this.sort_price = "ASC";
        this.sort_sales = "";
      } else if (e == 2) {
        // 价格
        this.sort_sales = "";
        if (this.sort_price == "ASC") {
          this.sort_price = "DESC";
        } else {
          this.sort_price = "ASC";
        }
      } else {
        // 销售
        this.sort_price = "";
        if (this.sort_sales == "DESC") {
          this.sort_sales = "ASC";
        } else {
          this.sort_sales = "DESC";
        }
      }
      //获取商品列表
      this.$refs.child.list_init();
    },
    onSearch() {
      this.$refs.child.list_init();
    },
    // get_brank() {
    //   this.$get({
    //     url: "/app/goods/list",
    //     data: {
    //       cat_id: 0,
    //       brand_id: this.id,
    //       is_recommend: 0,
    //       is_seckill: 2,
    //     },
    //     success: (res) => {
    //       this.productList = res.data.list;
    //     },
    //   });
    // },
  },
};
</script>

<style scoped lang="scss">
.productList {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  /deep/.van-tabs--line .van-tabs__wrap {
    height: 6vh;
  }

  /deep/.van-search {
    flex: 1;

    .van-field__left-icon {
      display: none;
    }
  }

  .sort {
    padding: 0 4vw 0 7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15vw;
    background-color: #fff;

    .comprehensive {
      font-size: 4vw;
      font-weight: 500;
    }

    .price,
    .salesVolume {
      margin-right: 3vw;
      color: #999999;
      font-size: 4vw;
      font-weight: 500;
    }

    .cur {
      color: #f13b10;
    }
  }

  .container {
    padding-top: calc(15vw + 54px);
    min-height: 87.4vh;
    height: auto;
  }

  .content {
    padding: 4vw;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    min-height: 87.4vh;
    height: auto;

    .productInformation {
      margin-bottom: 4vw;
      width: 44vw;
      height: 60vw;

      >h3 {
        margin-top: 2vw;
        font-size: 4vw;
        color: #3d444d;
        /*文字溢出隐藏显示省略号*/
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .price_quantitySold {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .price {
          font-size: 4vw;
          color: #f13710;
          font-weight: 700;

          >span {
            //display: inline-block;
            font-size: 2vw;
            transform: scale(0.2);
          }
        }

        .quantitySold {
          color: #c0c5cc;
        }
      }
    }

    .empty {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
