<template>
  <!-- 全部商品 -->
  <div class="allGoods">
    <div class="header_box">
      <van-search v-model="searchVal" shape="round" show-action background="#F13B10" :placeholder="$t('请输入搜索关键词')">
        <template #right-icon>
          <div @click="onSearch(searchVal)">
            <van-icon name="search" size="18" />
          </div>
        </template>
        <template #left>
          <div style="margin-right: 2vw" @click="$router.replace('/')">
            <van-icon name="arrow-left" color="#FFFFFF" />
          </div>
        </template>
        <template #action>
          <div @click="$router.push('shoppingCart')">
            <img src="../../../assets/img/allGoods_shopping_art.png" alt="" style="width: 6vw; object-fit: cover" />
          </div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <div class="left">
        <van-sidebar v-model="activeKey" :border="false" @change="onSidebar">
          <van-sidebar-item v-for="(item, index) in sidebarList" :key="index">
            <template #title>
              <h3 :class="activeKey == index ? 'sidebar' : ''">
                {{ item.cat_name }}
              </h3>
            </template>
          </van-sidebar-item>
          <!-- <van-sidebar-item>
            <template #title>
              <h3 :class="activeKey == 1 ? 'sidebar' : ''">标题2</h3>
            </template>
          </van-sidebar-item>
          <van-sidebar-item>
            <template #title>
              <h3 :class="activeKey == 2 ? 'sidebar' : ''">标题3</h3>
            </template>
          </van-sidebar-item> -->
        </van-sidebar>
      </div>
      <div class="right" v-if="sidebarList[activeKey].id">
        <img :src="sidebarList[activeKey].cat_img" alt="" style="height: 32vw; width: 64vw; object-fit: cover" />
        <div class="categoryList">
          <div class="commodity" v-for="(t, i) in commodityList.child_list" :key="i"
            @click="xz_go('productList', t.id)">
            <img :src="t.cat_img" alt="" style="height: 21vw; width: 28vw; object-fit: cover" />
            <span class="two">{{ t.cat_name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "allGoods",
  props: {},
  data() {
    return {
      id: this.xz_id(),
      searchVal: "",
      activeKey: 0,
      sidebarList: [{ cat_img: "", id: "" }],
      commodityList: [],
    };
  },
  created() {
    if (sessionStorage.getItem("allgoogs")) {
      this.activeKey = Number(sessionStorage.getItem("allgoogs"));
    }

    this.get_classify();
  },
  methods: {
    //获得商品分类列表
    get_classify() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/goods/getCatList",
        success: (res) => {
          this.sidebarList = res.data;
          this.commodityList = res.data[this.activeKey];
          this.$toast.clear();
        },
      });
    },
    //点击分类改变
    onSidebar(e) {
      sessionStorage.setItem("allgoogs", e);
      this.commodityList = this.sidebarList[e];
    },
    onSearch(val) {
      this.$router.push({
        name: "searchCommodity",
        query: {
          searchContent: val,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.allGoods {
  width: 100%;
  min-height: 100vh;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  /deep/.van-search {
    flex: 1;

    .van-field__left-icon {
      display: none;
    }
  }

  .content {
    padding-top: 54px;
    display: flex;
    height: calc(100vh - 54px);

    .left {
      display: flex;
      justify-content: center;
      width: 28vw;
      height: calc(100vh - 54px);
      border-right: 0.2vw solid #eeeeee;

      /deep/.van-sidebar-item--select::before,
      .van-sidebar-item {
        display: flex;
        justify-content: center;
        background-color: transparent;
        padding: 20px 0;
      }

      h3 {
        font-size: 3.5vw;
        color: #06121e;
        text-align: center;
        padding-bottom: 1vw;
      }

      .sidebar {
        border-bottom: 0.7vw solid #06121e;
      }
    }

    .right {
      padding: 5vw 4vw 0;
      height: calc(100vh - 54px);
      width: 72vw;
      overflow-y: auto;
      box-sizing: border-box;

      .categoryList {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 4vw 2vw;
        width: 64vw;

        .commodity {
          width: 28vw;
          display: flex;
          flex-direction: column;
          align-items: center;

          >span {
            display: block;
            margin-top: 2vw;
            font-size: 4vw;
            color: #06121f;
          }
        }
      }
    }
  }
}
</style>
