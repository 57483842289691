import axios from 'axios';
import router from './../router'
import qs from 'qs';
import Vant from 'vant';
import { Lazyload } from 'vant';
export default {
  install: Vue => {
    Vue.use(Vant);
    Vue.use(Lazyload, {
      lazyComponent: true,
    });

    Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    axios.defaults.timeout = 10000; //请求超时
    const no_login_code = -1; //未登录返回的状态码
    const success_code = 1; //成功返回的状态码
    const err_code = 0; //失败返回的状态码
    const special_err_code = -4; //特殊失败返回的状态码
    //http://singlemerchant.p2.sdqttx.net:92
    //http://singlemerchant.php.qwangluo.net:90/

    //http://mingpaigou.php.qwangluo.net:90 日语
    //http://singlemerchant.php.qwangluo.net:90/ 英语
    if (process.env.NODE_ENV == 'development') setPath('http://mingpaigou-us.php.qwangluo.net:90/') //设置开发环境域名
    else if (process.env.NODE_ENV == 'production') setPath('') //设置正式生产环境域名
    // else if (process.env.NODE_ENV == 'debug') setPath('') //设置测试环境域名

    function setPath(path) {
      axios.defaults.baseURL = path;
      Vue.prototype.httpPath = path;
      Vue.prototype.wsPath = ''; //WebSocket
    }

    Vue.prototype.$get = params => {
      const token = localStorage.getItem("token") || "";

      //多语言传参
      //   en: 'English',
      //   zh: '简体中文',
      //   hk: '繁體中文',
      const locale = localStorage.getItem(`mingpaigoushangchengus_lang`) || 'en'
      console.log("Stored locale:", locale);
      console.log({ en: 'us-en', zh: 'zh-cn', jp: 'ja-jp' }['jp'], { en: 'us-en', zh: 'zh-cn', jp: 'ja-jp' }[locale])

      const lang = { en: 'us-en', zh: 'zh-cn', jp: 'ja-jp' }[locale]

      axios
        .get(params.url, {
          headers: {
            'Accept-Language': lang
          },
          params: Object.assign({}, params.data, token ? {
            token
          } : "")
        })
        .then(res => {
          if (res.data.code == no_login_code) {
            Vue.prototype.$toast(res.data.msg);
            if (token) localStorage.removeItem("token");
            setTimeout(() => {
              router.replace('login')
            }, 1300);
          } else if (res.data.code == success_code) {
            // console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)

            if (params.success)
              params.success(res.data);
          } else if (res.data.code == err_code) {
            if (params.tip) {
              params.tip('val')
              Vue.prototype.$toast(res.data.msg);
              // console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)

            }
            // 有轻提示
            else if (params.tips) {
              params.tips('vals')
              // console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)

            }
            // 无轻提示



          }

        })
        .catch(err => {


          if (err.response.status == '404') {


            Vue.prototype.$toast({
              messgae: this.$t('接口不存在')
            });

            // Vue.prototype.$toast('接口不存在');
          } else if (err.response.status == '500') {
            Vue.prototype.$toast(this.$t('服务器内部错误'));
          } else if (params.fail)
            params.fail(err);
        })
    }



    Vue.prototype.$get2 = async function (params) {
      const token = localStorage.getItem("token") || "";
      const locale = localStorage.getItem("mingpaigoushangchengus_lang") || 'en';
      const lang = { en: 'us-en', zh: 'zh-cn', jp: 'ja-jp' }[locale] || 'us-en'; // 添加默认语言以防未定义  

      try {
        const response = await axios.get(params.url, {
          headers: {
            'Accept-Language': lang
          },
          params: Object.assign({}, params.data, token ? { token } : {})
        });

        const { data } = response;

        if (data.code === no_login_code) { // 确保 no_login_code 已在外部定义  
          Vue.prototype.$toast(data.msg);
          if (token) {
            localStorage.removeItem("token");
          }
          setTimeout(() => {
            router.replace('login');
          }, 1300);
          throw new Error('Not logged in'); // 抛出错误以便调用者可以捕获  
        } else if (data.code === success_code) { // 确保 success_code 已在外部定义  
          if (params.success) {
            params.success(data);
          }
          return data; // 成功时返回数据  
        } else if (data.code === err_code) { // 确保 err_code 已在外部定义  
          if (params.tip) {
            params.tip('val');
            Vue.prototype.$toast(data.msg);
          } else if (params.tips) {
            params.tips('vals');
          }
          throw new Error(data.msg); // 抛出错误以便调用者可以捕获  
        } else {
          // 对于其他未知的代码，也可以选择抛出错误或返回数据  
          throw new Error('Unknown response code');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error('Request canceled', error.message);
        } else if (error.response) {
          // 服务器响应了一个状态码，且状态码不在2xx范围内  
          switch (error.response.status) {
            case 404:
              Vue.prototype.$toast(this.$t('接口不存在') || '接口不存在'); // 注意：这里的 this 可能不是 Vue 实例，需要处理  
              break;
            case 500:
              Vue.prototype.$toast(this.$t('服务器内部错误') || '服务器内部错误'); // 同上  
              break;
            default:
              Vue.prototype.$toast(`Error ${error.response.status}: ${error.response.statusText}`);
          }
        } else if (error.request) {
          // 请求已经发出，但没有收到响应  
          console.error('No response received', error.request);
        } else {
          // 其他错误  
          console.error('Error in setting up request', error.message);
        }

        if (params.fail) {
          params.fail(error);
        }

        throw error; // 重新抛出错误以便调用者可以进一步处理  
      }
    };

    // 注意：  
    // 1. 由于 this 在箭头函数中不绑定到 Vue 实例，因此如果 $toast 或 $t 是 Vue 实例的方法，  
    //    您可能需要将 $get 方法定义为普通函数（如上所示）或使用其他方法来确保正确的上下文。  
    // 2. 如果 no_login_code、success_code 和 err_code 没有在外部定义，您需要在适当的位置定义它们。  
    // 3. 如果调用者希望处理特定的错误类型（如取消请求），他们可以在 catch 块中检查错误对象。


    Vue.prototype.$post = params => {
      const token = localStorage.getItem("token") || "";
      const locale = localStorage.getItem("mingpaigoushangchengus_lang") || 'en';
      const lang = { en: 'us-en', zh: 'zh-cn', jp: 'ja-jp' }[locale] || 'us-en'; // 添加默认语言以防未定义  

      let $axios;
      if (params.upload) {
        params.data.append('token', token ? token : "")
        // params.data.append({ 'selfLange': lang })

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept-Language': lang
          }
        };
        $axios = axios.post(params.url, params.data, config)
      } else if (params.noToken) {

        let config = {
          headers: {
            'Accept-Language': lang
          }
        };

        let data = qs.stringify(Object.assign({}, params.data))
        $axios = axios.post(params.url, data, config)
      } else {

        let config = {
          headers: {
            'Accept-Language': lang
          }
        };

        let data = qs.stringify(Object.assign({}, params.data, token ? {
          token
        } : {}))
        $axios = axios.post(params.url, data, config)
      }

      $axios
        .then(res => {
          if (res.data.code == no_login_code) {
            Vue.prototype.$toast(res.data.msg);
            if (token) localStorage.removeItem("token");
            setTimeout(() => {
              router.replace('login')
            }, 1300);
          } else if (res.data.code == success_code || res.data.code == 2) {
            // console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            if (params.success)
              params.success(res.data);
          } else if (res.data.code == err_code || res.data.code == special_err_code || res.data.code == -5) {
            if (params.tip) {
              params.tip(res.data)
              Vue.prototype.$toast(res.data.msg);
            } else if (params.tips) {
              params.tips(res.data)
            }
            // console.group('%c' + params.url, 'color:#6600FF;line-height:30px;padding:10px 0;', res.data)
            // 无轻提示
          }
        })
        .catch(err => {

          if (err.response.status == '404') {
            Vue.prototype.$toast(this.$t('接口不存在'));

          } else if (err.response.status == '500') {
            Vue.prototype.$toast(this.$t('服务器内部错误'));
          } else if (params.fail)
            params.fail(err);
          // if (params.fail)
          //     params.fail(err);
        })
    }

  }
}