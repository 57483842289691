<template>
  <!-- 会员福利 -->
  <div class="membershipBenefits">
    <div class="header_box">
      <van-nav-bar :title="$t('会员福利')" left-arrow @click-left="$router.replace('/')" :border="false" />
    </div>

    <div class="content">
      <img src="../../../assets/img/membershipBenefits_masonry.png" alt=""
        style="width: 18vw; height: 15vw; margin-top: 5vw" />
      <h3 class="currentLevel">{{ $t('当前会员等级') }}</h3>


      <!-- 一层 -->

      <div class="levelWelfare">
        <div class="grade" @click="zj_show = !zj_show">{{ vip_name }}</div>
        <!-- <span>{{ vip_name }}</span> -->
        <div class="zhankai_box" :class="zj_show ? 'active' : ''">
          <div class="detailedBar">
            <span>{{ $t('全场会员折扣') }}:{{ list.vip_discount }}</span>
            <div class="receiveBox" :class="is_receive.discount_state ? 'receive' : 'onReceive'"
              @click="noReceive(0, id)">
              {{ is_receive.discount_state ? $t("已领取") : $t("领取") }}
            </div>
          </div>
          <div class="detailedBar">
            <span>{{ $t('会员升级礼金') }}:{{ $t('元') }}{{ list.cash_num }}</span>
            <div class="receiveBox" :class="is_receive.money_state ? 'receive' : 'onReceive'" @click="noReceive(1, id)">
              {{ is_receive.money_state ? $t("已领取") : $t("领取") }}
            </div>
          </div>
          <div class="detailedBar">
            <span>{{ $t('天天现金红包') }}：{{ $t('元') }}{{ list.day_min_cash_num.replace(".00", "") }}-{{ $t('元') }}{{
              list.day_max_cash_num.replace(".00", "")
            }}</span>
            <div class="receiveBox" :class="is_receive.day_money_state ? 'receive' : 'onReceive'"
              @click="noReceive(3, id)">
              {{ is_receive.day_money_state ? $t("已领取") : $t("领取") }}
            </div>
          </div>
          <!-- <div class="detailedBar">
            <span>{{ $t('每月话费额度') }}:{{ $t('元') }}{{ list.phone_remark }}</span>
            <div class="receiveBox" :class="is_receive.month_tel_state ? 'receive' : 'onReceive'"
              @click="onIsTel(is_receive.month_tel_state)">
              {{ is_receive.month_tel_state ? $t("已领取") : $t("领取") }}
            </div>
          </div>
          <div class="detailedBar">
            <span>{{ $t('每月油卡额度') }}:{{ $t('元') }}{{ list.oil_remark }}</span>
            <div class="receiveBox" :class="is_receive.month_oil_state ? 'receive' : 'onReceive'"
              @click="onIsOil(is_receive.month_oil_state)">
              {{ is_receive.month_oil_state ? $t("已领取") : $t("领取") }}
            </div>
          </div> -->
        </div>

        <div class="zhankai" @click="zj_show = !zj_show">

          <van-icon name="arrow-down" color="#fff" size="20" />
        </div>



      </div>
      <div class="upgrade">{{ $t('升级更高等级获得更多权益') }}</div>


      <!-- 二层 -->


      <!-- 更多权益列表 -->
      <div class="levelWelfare" v-for="(ele, index) in upgradList" :key="index">
        <div class="grade" @click="ele.zj_show = !ele.zj_show">{{ ele.vip_name }}</div>
        <!-- <span>{{ ele.vip_name }}</span> -->
        <div class="zhankai_box" :class="ele.zj_show ? 'active' : ''">
          <div class="detailedBar">
            <span>{{ $t('全场会员折扣') }}:{{ ele.vip_discount }}</span>
            <div></div>
          </div>
          <div class="detailedBar">
            <span>{{ $t('会员升级礼金') }}:{{ $t('元') }}{{ ele.cash_num }}</span>
            <div class="receiveBox" :class="ele.level_true == 0
              ? 'onReceive'
              : is_receive.money_state
                ? 'onReceive'
                : 'receive'
              " @click="noReceive(1, ele.id)">
              {{
                ele.level_true == 0
                  ? $t("领取")
                  : is_receive.money_state
                    ? $t("领取")
                    : $t("已领取")
              }}
            </div>
          </div>
          <div class="detailedBar">
            <span>{{ $t('天天现金红包') }}：{{ $t('元') }}{{ ele.day_min_cash_num.replace(".00", "") }}-{{ $t('元') }}{{
              ele.day_max_cash_num.replace(".00", "")
            }}</span>
            <div class="receiveBox" :class="ele.level_true == 0
              ? 'onReceive'
              : is_receive.day_money_state
                ? 'onReceive'
                : 'receive'
              " @click="noReceive(3, ele.id)">
              {{
                ele.level_true == 0
                  ? $t("领取")
                  : is_receive.day_money_state
                    ? $t("领取")
                    : $t("已领取")
              }}
            </div>
          </div>

          <!-- <div class="detailedBar">
            <span>{{ $t('每月话费额度') }}:{{ $t('元') }}{{ ele.phone_remark }}</span>
            <div class="receiveBox" :class="ele.level_true == 0
        ? 'onReceive'
        : is_receive.month_tel_state
          ? 'onReceive'
          : 'receive'
        " @click="
        [
          (show = true),
          (mobileShow = true),
          (curVipShow = false),
          (noCurId = ele.id),
        ]
        ">
              {{
        ele.level_true == 0
          ? $t("领取")
          : is_receive.month_tel_state
            ? $t("领取")
            : $t("已领取")
      }}
            </div>
          </div> -->
          <!-- <div class="detailedBar">
            <span>{{ $t('每月油卡额度') }}:{{ $t('元') }}{{ ele.oil_remark }}</span>
            <div class="receiveBox" :class="ele.level_true == 0
        ? 'onReceive'
        : is_receive.month_oil_state
          ? 'onReceive'
          : 'receive'
        " @click="
        [
          (show = true),
          (mobileShow = false),
          (curVipShow = false),
          (noCurId = ele.id),
        ]
        ">
              {{
        ele.level_true == 0
          ? $t("领取")
          : is_receive.month_oil_state
            ? $t("领取")
            : $t("已领取")
      }}
            </div>
          </div> -->

        </div>


        <div class="zhankai" @click="ele.zj_show = !ele.zj_show">
          <van-icon name="arrow-down" color="#fff" size="20" />
        </div>



      </div>
      <!-- 联系客服 -->
      <div class="btn">
        <van-button type="primary" color="#F1AC10" block @click="goContact">{{ $t('点击联系客服升级') }}</van-button>
      </div>
    </div>
    <!-- 输入框的弹窗 -->
    <van-popup v-model="show" closeable close-icon="close" position="bottom" :style="{ height: '30%' }">
      <div class="inputBox">
        <van-field v-show="mobileShow" v-model="mobile" type="tel" maxlength="11" :placeholder="$t('请输入手机号')"
          label="手机号" clearable />
        <van-field v-show="!mobileShow" v-model="oil_card" type="tel" :placeholder="$t('请输入油卡卡号')" label="油卡"
          clearable />
        <div v-show="curVipShow">
          <div v-show="mobileShow" class="btn" @click="noReceive(2, id)">
            {{ $t('确定') }}
          </div>
          <div v-show="!mobileShow" class="btn" @click="noReceive(4, id)">
            {{ $t('确定') }}
          </div>
        </div>
        <div v-show="!curVipShow">
          <div v-show="mobileShow" class="btn" @click="noReceive(2, noCurId)">
            {{ $t('确定') }}
          </div>
          <div v-show="!mobileShow" class="btn" @click="noReceive(4, noCurId)">
            {{ $t('确定') }}
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 提示弹窗 -->
    <van-overlay :show="msgShow">
      <div class="wrapper boxa" @click.stop>
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="navorders">{{ $t('确定') }}</van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "noticeDetails",
  props: {},
  data() {
    return {
      zj_show: false,
      vip_level: "",
      vip_name: "",
      list: {
        day_min_cash_num: "",
        day_max_cash_num: "",
      },
      is_receive: [],
      id: "",
      upgradList: [
        {
          vip_level: "",
          vip_name: "",
          vip_discount: "",
          day_min_cash_num: ".00",
          day_max_cash_num: ".00",
          level_true: "",
          cash_num: "",
          phone_remark: "",
          oil_remark: "",
        },
      ],
      webSiteInfo: "",
      // 输入弹窗
      show: false,
      mobileShow: true,
      curVipShow: true,
      onCurId: "",
      mobile: "",
      oil_card: "",
      // 提示弹窗
      msgShow: false,
      showcode: {},
      orders: {},
    };
  },
  created() {
    this.get_level();
  },
  methods: {
    // 话费  判断是否弹出输入框
    onIsTel(e) {
      if (!e == 1) {
        this.show = true;
        this.mobileShow = true;
        this.curVipShow = true
      } else {
        this.$toast(this.$t("您已经领取过该权益"))
      }
    },
    // 油卡  判断是否弹出输入框
    onIsOil(e) {
      if (!e == 1) {
        this.show = true;
        this.mobileShow = false;
        this.curVipShow = true
      } else {
        this.$toast(this.$t("您已经领取过该权益"))
      }
    },
    //领取
    noReceive(index, id) {
      //领取现金券
      if (index == 1) {
        this.$post({
          url: "/app/vip/receiveMoney",
          data: {
            vip_id: id,
          },
          success: (res) => {
            console.log(res);
            //this.$toast(res.msg);
            this.show = false;
            this.msgShow = true
            this.showcode = res
          },
          tip: (val) => { },
        });
      }
      //领取每月话费
      else if (index == 2) {
        this.$post({
          url: "/app/vip/receiveMonthTelCharges",
          data: {
            vip_id: id,
            mobile: this.mobile,
          },
          success: (res) => {
            this.show = false;
            this.mobile = "";
            this.msgShow = true
            this.showcode = res
          },
          tip: (val) => { },
        });
      }
      //领取天天现金
      else if (index == 3) {
        this.$post({
          url: "/app/vip/receiveDayMoney",
          data: {
            vip_id: id,
          },
          success: (res) => {
            this.msgShow = true
            this.showcode = res
          },
          tip: (val) => { },
        });
      }
      //领取每月油卡
      else if (index == 4) {
        this.$post({
          url: "/app/vip/receiveMonthOilCharges",
          data: {
            vip_id: id,
            oil_card: this.oil_card,
          },
          success: (res) => {
            this.show = false;
            this.oil_card = "";
            this.msgShow = true
            this.showcode = res
          },
          tip: (val) => { },
        });
      }
    },
    navorders() {
      this.get_level();
      this.msgShow = false
    },
    //获得等级
    get_level() {
      this.$get({
        url: "/app/vip/list",
        success: (res) => {
          console.log(res);
          for (let ele of res.data.list) {
            if (res.data.vip_level == ele.vip_level) {
              this.id = ele.id;
              this.vip_name = ele.vip_name;
              this.vip_level = ele.vip_level;
              this.list = ele;
              this.is_receive = res.data.vip_receive;
            }
          }
          this.upgradList.splice(0);
          for (let i = 0; i < res.data.list.length; i++) {
            if (
              res.data.vip_level !== res.data.list[i].vip_level &&
              res.data.vip_level < res.data.list[i].vip_level
            ) {
              res.data.list[i].zj_show = false,
                this.upgradList.push(res.data.list[i]);
            }
          }
        },
      });
    },
    // 获取站点信息
    getWebSiteInfo() {
      this.$get({
        url: "/app/extend/getWebSiteInfo",
        success: (res) => {
          this.webSiteInfo = res.data;
        },
        tip: () => { },
      });
    },
    // 去联系
    goContact() {
      // window.location.href = this.webSiteInfo.mobile
      this.$router.push({
        name: "ChatWindow",
        query: {
          src: this.webSiteInfo.mobile,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.van-overlay {
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 80vw;
    height: 99vw;
    padding: 21vw 14vw 3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .van-button {
      width: 12vw;
    }

    .titblock {
      width: 100%;
      color: #000;
      font-size: 4vw;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 4vw;
        text-align: center;
      }
    }
  }
}

.boxa {
  background: url("../../../assets/img/xxx.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}



.zhankai_box {
  transition: all .3s;
  transform: scaleY(0);
  height: 0;

  &.active {
    transform: scaleY(1);
    height: 60vw;
  }
}



.membershipBenefits {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 6.7vh 0 0 0;
  background: url("../../../assets/img/membershipBenefits_bc.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #f4b772;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: 700;
    }
  }

  .content {
    //padding:4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: $conter2;
    height: auto;

    >h3 {
      margin-top: 4vw;
      font-size: 4.5vw;
    }

    .levelWelfare {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2vw 0 4vw 0;
      padding: 0 3vw 0;
      width: 92vw;
      //height: 106vw;
      background-image: linear-gradient(156deg, #ffc98d, #e38c00);
      border-radius: 3vw;

      .grade {
        width: 100%;
        text-align: center;
        margin: 6vw 0 1vw 0;
        font-size: 5vw;
        color: #fff;
        font-family: Weibei SC;
        font-weight: bold;
      }

      .detailedBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4vw;
        padding: 0 4vw;
        width: 87vw;
        height: 13vw;
        border-radius: 2vw;
        color: #fff;
        background-color: #f7c24c;
        font-size: 3.5vw;

        .receiveBox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18vw;
          height: 7vw;
          border-radius: 2vw;
          color: #fff;
        }

        .receive {
          background-color: rgba(255, 255, 255, 0.2);
        }

        .onReceive {
          background-color: #f13b10;
        }
      }
    }

    .upgrade {
      margin-bottom: 1vw;
      font-size: 5vw;
      font-family: ZHSCNMT-GBK;
      font-weight: 400;
      color: #e25151;
    }

    .upgradEquity {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 4vw 0;
      padding: 0 3vw 5vw;
      width: 92vw;
      //height: 106vw;
      background-image: linear-gradient(156deg, #ffc98d, #e38c00);
      border-radius: 3vw;

      .grade {
        width: 100%;
        text-align: center;
        margin: 5vw 0 1vw 0;
        font-size: 5vw;
        color: #fff;
        font-family: Weibei SC;
        font-weight: bold;
      }

      >span {
        margin-bottom: 5vw;
      }

      .detailedBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 4vw 4vw;
        width: 87vw;
        border-radius: 2vw;
        color: #fff;
        background-color: #f7c24c;

        >div {
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
      }
    }

    .btn {
      margin-bottom: 4vw;

      /deep/.van-button {
        width: 79vw;
        height: 13vw;
        margin: 0 auto;
      }
    }
  }

  // 弹窗
  .inputBox {
    margin-top: 20vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      margin-right: 4vw;
      width: 25vw;
      height: 10vw;
      color: #fff;
      text-align: center;
      line-height: 10vw;
      background-color: #f13b10;
      border-radius: 1vw;
    }
  }
}
</style>
