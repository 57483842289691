<template>
  <!-- 设置银行卡 -->
  <div class="setBankCard">
    <div class="header_box"><van-nav-bar title="Add" left-arrow @click-left="$router.go(-1)" :border="false" /></div>

    <div class="content">

      <van-field :required="item.required == 1" v-for="(item, index) in list" :key="index" clearable
        v-model="item[item.label]" :label="item.label" :placeholder="`Please enter ${item.label}`"
        input-align="right" />

      <van-field clearable :value="currency" readonly v-if="currency" label="currency" input-align="right" />

      <!-- <van-field clearable v-model="bank_deposit" :label="$t('银行名称')" :placeholder="$t('请输入银行名称')"
        input-align="right" /> -->
      <!-- 
      <van-field clearable v-model="bank_branch" :label="$t('银行支行')" :placeholder="$t('请输入银行支行')" input-align="right" />

      <van-field clearable v-model="bank_no" :label="$t('银行卡号')" :placeholder="$t('请输入银行卡号')" input-align="right" /> -->

      <!-- <van-field clearable v-model="bank_kou" label="囗座番号" placeholder="囗座番号を入力してください" input-align="right" /> -->
      <div class="btn">
        <van-button type="primary" color="#F13B10" @click="onNewBankCard">{{ $t('保存') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      id: "", //编辑id
      payment_id: "", //编辑id
      title: "",
      mobile: "",
      sms_code: "",
      code: "",
      time: 60,
      is_time: 1,
      list: [],
      currency: ''
    };
  },
  created() { },
  mounted() {

    this.payment_id = this.$route.query.payment_id; //从银行卡列表过来的
    this.getUserInfo()

    this.getBankCardInfo(this.id);


  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      let obj = { id: this.payment_id }
      this.$get({
        url: "/app/payment/getPaymentInfo",
        data: obj,
        success: (res) => {
          this.$toast.clear();
          if (res.code == 1) {
            console.log(res.data);
            this.list = res.data.fields.map((item, index) => {
              return { label: item.field_name, [item.field_name]: '', required: item.required, id: item.id }
            })
            this.currency = res.data.currency
            console.log(this.list)
          }
        },
        tip: () => { },
      });
    },
    // 获取银行卡信息
    getBankCardInfo(id) {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      if (!id) {
        this.$toast.clear();
      } else {
        this.$get({
          url: "/app/user_wallet/detail",
          data: { id: id },
          success: (res) => {
            this.$toast.clear();
          },
          tip: () => { },
        });
      }
    },
    // 添加、编辑银行卡
    onNewBankCard() {

      console.log(this.list)

      let is_ok = false;

      this.list.some(item => {
        if (item.required && !item[item.label]) {
          this.xz_say(item.label + ' is required');
          is_ok = true;
          return true; // 检测到没值，返回 true 停止循环
        }
        return false; // 继续循环
      });

      if (is_ok) { return }

      let payment_id = this.payment_id;
      let obj = {
        payment_id: payment_id,
      }

      this.list.forEach(item => {
        obj[item.id] = item[item.label]
      })

      console.log(obj)

      //编辑
      if (this.id) {

        this.$toast.loading({
          message: this.$t("加载中..."),
          forbidClick: true,
        });

        this.$post({
          url: "/app/user_payment/edit",
          data: obj,
          success: (res) => {
            this.xz_say(res.msg);
            setTimeout(() => {

              this.xz_back();
              this.$toast.clear();
            }, 1000)
          },
          tip: () => {
            setTimeout(() => {
              this.$toast.clear();
            }, 1000)
          },
        });
      } else {
        this.$toast.loading({
          message: this.$t("加载中..."),
          forbidClick: true,
        });
        // 添加
        this.$post({
          url: "/app/user_payment/add",
          data: obj,
          success: (res) => {
            this.xz_say(res.msg);
            setTimeout(() => {
              this.xz_back();
              this.$toast.clear();
            }, 1000)
          },
          tip: () => {
            setTimeout(() => {
              this.$toast.clear();
            }, 1000)
          },
        });
      }
    },
    // 获取验证码
    sendcode() {
      if (!this.mobile) return this.$toast("请输入手机号");
      if (!/^1[23456789]\d{9}$/.test(this.mobile))
        return this.$toast("请输入正确格式的手机号");
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.$post({
        url: "/app/sms/sendSms",
        data: {
          mobile: this.tell,
          scene: "reset_trade",
        },
        success: () => {
          this.$toast.clear();
          this.$toast("发送成功");
          this.is_time = 2;
          this.time = 60;
          var timer = setInterval(() => {
            this.time--;
            if (this.time == 0) {
              clearInterval(timer);
              this.is_time = 1;
            }
          }, 1000);
        },
        tip: () => { },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.setBankCard {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }


  .van-cell--required::before {
    position: absolute;
    left: 8px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
    top: 5vw;
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    /deep/.van-cell {
      width: 100vw;
      height: 14vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {
        justify-content: flex-end;
      }
    }

    /deep/.van-cell::after {
      border: 0;
    }

    .btn {
      margin: 33vh auto 0;
      width: 79vw;

      /deep/.van-button {
        width: 79vw;
        height: 15vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
  }
}
</style>