<template>
  <!-- 我的银行卡 -->
  <div class="bankCardList">
    <div class="header_box">
      <van-nav-bar :title="title" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>

    <div class="content">
      <div class="list" :class="`list${color % 3}`" v-for="ele in list" :key="ele.id">
        <!-- <div class="nameType">
          <span class="name">{{ ele.payment_name }}</span>
        </div> -->
        <div class="num">
          {{ ele.items[0].field_name }}：{{ ele.items[0].field_value }}
        </div>

        <div class="num">
          {{ ele.items[1].field_name }}：{{ ele.items[1].field_value }}
        </div>
        <div class="btn">
          <!-- <div class="edit" @click="xz_go('setBankCard', ele.id)">{{ $t('编辑') }}</div> -->
          <div class="del" @click="onDel(ele.id)">{{ $t('删除') }}</div>
          <div v-show="opt_id" class="use"
            @click="go_back(ele.id, ele.items[0].field_name, ele.items[1].field_name, ele.items[0].field_value, ele.items[1].field_value)">
            {{ $t('使用') }}
          </div>
        </div>
      </div>
      <div style="height: 15vw;"></div>
    </div>
    <van-goods-action>
      <van-goods-action-button color="#F13B10" type="danger" text="Add" @click="go_to(id)" />
    </van-goods-action>
  </div>
</template>

<script>
export default {
  name: "bankCardList",
  props: {},
  data() {
    return {
      opt_id: '',
      color: 0,
      id: '',
      title: "",
      list: [],
    };
  },
  created() {
    this.opt_id = this.$route.query.opt_id;
    this.id = this.$route.query.id;
    this.color = this.$route.query.color;
    this.title = this.$route.query.title;
  },
  mounted() {
    this.getList();
  },
  methods: {
    go_back(id, name1, name2, value1, value2) {
      console.log(id)
      // 设置参数
      localStorage.setItem("user_payment_id", id)
      localStorage.setItem("user_payment_name1", name1)
      localStorage.setItem("user_payment_name2", name2)
      localStorage.setItem("user_payment_value1", value1)
      localStorage.setItem("user_payment_value2", value2)
      // 跳转两级
      this.$router.go(-2);
    },
    go_to(id) {

      this.$router.push({
        name: "setBankCard",
        query: {
          payment_id: id,
        },
      });
    },
    // 获取银行卡列表
    getList() {
      // this.$toast.loading({
      //   message: this.$t("加载中..."),
      //   forbidClick: true,
      // });
      let obj = { payment_id: this.id }
      this.$get({
        url: "/app/user_payment/getList",
        data: obj,
        success: (res) => {
          // this.$toast.clear();
          this.list = res.data.data;
        },
        tip: () => { },
      });
    },
    // 删除
    onDel(id) {

      this.$dialog
        .confirm({
          title: this.$t("提示"),
          message: "Are you sure you want to delete it?",
        })
        .then(() => {
          this.$post({
            url: "/app/user_payment/del",
            data: { id: id },
            success: (res) => {
              this.xz_say(res.msg);
              this.getList()
            },
            tip: () => { },
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  },
};
</script>

<style scoped lang='scss'>
.bankCardList {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
    }
  }

  // 内容
  .content {
    min-height: $navHeight;
    height: auto;

    >.list0 {
      background: url("../../../assets/img/bank_card_bc1.png") no-repeat;
      background-size: 100% 100%;
    }

    >.list1 {
      background: url("../../../assets/img/bank_card_bc2.png") no-repeat;
      background-size: cover;
    }

    >.list2 {
      background: url("../../../assets/img/bank_card_bc3.png") no-repeat;
      background-size: cover;
    }


    .list {
      position: relative;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      margin: 4vw auto 0;
      padding: 4vw;
      width: 92vw;
      height: 24vw;
      border-radius: 4vw;

      .nameType {
        display: flex;
        align-content: center;
        margin-bottom: 1vw;

        .name {
          margin-right: 5vw;
          font-size: 5vw;
          color: #fff;
        }

        .type {
          font-size: 3vw;
          color: #fff;
        }
      }

      .num {
        font-size: 3.5vw;
        color: #fff;
      }

      .btn {
        position: absolute;
        bottom: 0;
        right: 4vw;
        display: flex;
        justify-content: flex-end;
        align-content: center;

        div {
          height: 7vw;
        }

        .edit {
          color: #fff;
        }

        .del,
        .use {
          margin-left: 4vw;
          color: #fff;
        }
      }
    }
  }

  .addCard {
    position: absolute;
    bottom: 0;
    left: 0;

    /deep/.van-button {
      margin: 0;
      width: 100vw;
      height: 7vh;
      border-radius: 0vw;
    }

    /deep/.van-button--normal {
      padding: 0;
    }
  }

  /deep/.van-goods-action {
    width: 100%;
  }
}
</style>