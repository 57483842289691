<template>
  <div class="indexbox">
    <div class="header_box">
      <van-nav-bar :title="$t('购物车')" :right-text="edit ? $t('完成') : $t('编辑')" left-arrow @click-left="$router.go(-1)"
        @click-right="edit = !edit" :border="false" fixed />
    </div>

    <div class="content">
      <van-list v-model="loading" :finished="finished" :finished-text="$t('没有更多了')" @load="getdata">
        <div class="list" v-for="(val, index) in list" :key="index">
          <van-swipe-cell>
            <div class="orders">
              <div class="left">
                <img src="../../../assets/img/weixuan@2x(1).png" alt="" v-if="val.is_selected == 1"
                  @click="changeCheck(val, 1)" />
                <img src="../../../assets/img/xuanzhong@2x(1).png" alt="" v-if="val.is_selected == 2"
                  @click="changeCheck(val, 2)" />
              </div>
              <div class="right">
                <img v-lazy="val.goods_thumb" alt="" @click="xz_go('productDetailsOrEvaluation', val.goods_id)" />
                <div class="user">
                  <div class="nameAndMoeny">
                    <p class="name one">{{ val.goods_name }}</p>
                    <p class="moeny">{{ $t('元') }}{{ val.goods_price }}</p>
                  </div>
                  <div class="numbox">

                    <p></p>
                    <div class="nums">
                      <van-stepper v-model="val.goods_amount" min="1" :disabled="!edit ? false : true" disable-input
                        @change="onChange(val)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <van-button square type="danger" :text="$t('删除')" @click="beforeClose(val)" />
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>

    <div class="footBox" v-if="list.length">
      <p>
        <img src="../../../assets/img/weixuan@2x(1).png" alt="" v-if="!checkAllFlag" @click="allget" />
        <img src="../../../assets/img/xuanzhong@2x(1).png" alt="" v-if="checkAllFlag" @click="allget" />
        {{ $t('全选') }}
      </p>
      <p v-show="!edit">{{ $t('合计') }}：{{ $t('元') }}{{ totalMoney }}</p>
      <div class="btn">
        <van-button type="info" color="#F13B10" v-show="!edit" @click="getorder">{{ $t('立即付款') }}</van-button>
        <van-button type="info" color="#F13B10" v-show="edit" @click="beforeClose">{{ $t('确定删除') }}</van-button>
      </div>
    </div>

    <!-- <van-submit-bar :price="totalMoney" button-text="提交订单" @submit="getorder">
      <van-checkbox v-model="checkAllFlag">全选</van-checkbox>
    </van-submit-bar> -->
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      checkAllFlag: false, // true-全选  false-未全选
      totalMoney: 0, //总价
      selectId: [], //选中的商品id

      loading: false,
      finished: false,
      page: 1,
      list: [],
      edit: false,
    }
  },
  computed: {},
  watch: {
    // 求总价
    list() {
      this.totalPrice()
    },
  },
  methods: {
    getdata() {
      var page = this.page++
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      })
      this.$get({
        url: "/app/goods_cart/list",
        data: {
          page: page,
        },
        success: (res) => {
          this.$toast.clear()
          if (res.data.list.length == 0) {
            this.finished = true
          } else {
            res.data.list.forEach((val) => {
              val.is_selected = 1
            })

            if (res.data.list.some((item) => item.is_selected != 2))
              this.checkAllFlag = false

            var list = res.data.list
            if (page == 1) {
              this.list = list
            } else {
              for (var i in list) {
                this.list.push(list[i])
              }
            }
          }
          this.loading = false
        },
        tip: () => {
          this.finished = true
        },
      })
    },

    allget() {
      this.checkAllFlag = !this.checkAllFlag
      this.choseAll(this.checkAllFlag)
    },

    onChange(val) {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      })
      this.$post({
        url: "/app/goods_cart/edit",
        data: {
          goods_id: val.goods_id,
          specs_id: val.specs_id,
          amount: val.goods_amount,
        },
        success: (res) => {
          this.$toast.clear()
          this.totalPrice()
        },
        tip: () => { },
      })
    },

    //单选
    changeCheck(val, is_selected) {
      //   2选中，1未选中
      if (is_selected === 1) {
        val.is_selected = 2
      } else {
        val.is_selected = 1
      }
      if (this.list.some((item) => item.is_selected != 2)) {
        this.checkAllFlag = false
      } else {
        this.checkAllFlag = true
      }
      this.totalPrice()
    },

    //全选
    choseAll(flag) {
      if (this.list.length > 0) {
        var _this = this
        if (flag) {
          _this.list.forEach((item, index) => {
            item.is_selected = 2
          })
          this.totalPrice()
        } else {
          _this.list.forEach((item, index) => {
            item.is_selected = 1
          })
          this.totalPrice()
        }
      } else {
        this.$toast(this.$t("物车商品为空,请添加商品！"))
      }
    },

    // 求总价
    totalPrice() {
      var _this = this
      this.totalMoney = 0
      this.list.forEach((item, index) => {
        if (item.is_selected == 2) {
          //求总价
          _this.totalMoney +=
            parseFloat(_this.list[index].goods_amount) *
            parseFloat(_this.list[index].goods_price)
        }
      })
    },

    getorder() {
      console.log(this.list)
      this.list.forEach((item, index) => {
        if (item.is_selected == 2) {
          let ids = `${item.goods_id}_${item.goods_amount}`
          console.log(ids)
          this.selectId.push(ids)
        }
      })
      if (!this.selectId.length) return this.$toast(this.$t("请选择商品！"))
      let ids = this.selectId.join(";")
      this.$router.push({ name: "carOrder", query: { ids } })
    },

    beforeClose(val) {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      })
      if (!this.edit) {
        this.$post({
          url: "/app/goods_cart/delete",
          data: {
            ids_json: `${val.goods_id}`,
          },
          success: (res) => {
            this.$toast.clear()
            this.$toast(this.$t("删除成功"))
            setTimeout(() => {
              this.page = 1
              this.list = []
              this.getdata()
            }, 800)
          },
          tip: () => { },
        })
      } else {
        this.list.forEach((item, index) => {
          if (item.is_selected == 2) {
            let ids = `${item.goods_id}`
            console.log(ids)
            this.selectId.push(ids)
          }
        })
        if (!this.selectId.length) return this.$toast(this.$t("请选择商品！"))
        let ids = this.selectId.join(";")
        this.$post({
          url: "/app/goods_cart/delete",
          data: {
            ids_json: ids,
          },
          success: (res) => {
            this.$toast.clear()
            this.$toast(this.$t("删除成功"))
            setTimeout(() => {
              this.page = 1
              this.list = []
              this.getdata()
            }, 800)
          },
          tip: () => { },
        })
      }
    },
  },
  created() { },
  mounted() { },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #ffffff;
  padding: 7.5vh 3vw 12vh;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666666;
    }
  }

  .content {}

  .list {
    width: 100%;
    padding: 2vw 3vw 1vw;
    background: #ffffff;
    box-shadow: 0px 3px 6px #e5e5e5;
    border-radius: 2vw;
    margin-bottom: 3vw;

    .orders {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 3vw;

      .left {
        width: 10%;

        >img {
          width: 5vw;
          height: 5vw;
          object-fit: cover;
        }
      }

      .right {
        width: 90%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        >img {
          width: 20vw;
          height: 20vw;
          object-fit: cover;
        }

        .user {
          width: 77%;
          min-height: 24vw;
          padding: 0 0 0 2vw;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .nameAndMoeny {
            width: 100%;

            .name {
              width: 100%;
              color: #000000;
              font-size: 4vw;
              font-weight: 500;
            }

            .moeny {
              width: 100%;
              color: #e83323;
              font-size: 4vw;
              font-weight: 550;
            }
          }

          .numbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tit {
              max-width: 55%;
              color: #999999;
              font-size: 3vw;
            }

            .nums {
              width: 40%;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              /deep/.van-stepper {
                display: flex;
                transform: scale(0.8);

                .van-stepper__minus::before,
                .van-stepper__plus::after {
                  opacity: 1 !important;
                }
              }
            }
          }
        }
      }
    }

    /deep/.van-swipe-cell__right {
      .van-button {
        height: 100%;
      }
    }
  }

  .footBox {
    width: 100%;
    height: 7vh;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 0 2vw 3vw;
    background: #ffffff;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.06);

    >p:nth-child(1) {
      color: #999999;
      font-size: 3.6vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      >img {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
      }
    }

    >p:nth-child(2) {
      flex: 1;
      padding: 0 2vw;
      color: #333333;
      font-size: 3.6vw;
      font-weight: 550;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .btn {
      display: flex;
      justify-content: center;

      /deep/.van-button {
        width: 32vw;
        height: 13vw;
        border-radius: 0vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 3vw;
      }
    }
  }
}
</style>
