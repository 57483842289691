<template>
  <!-- 提现记录 -->
  <div class="cashOutRecord">
    <div class="header_box">
      <van-nav-bar :title="$t('提现记录')" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>

    <div class="content">
      <vant-up-down ref="child" @list="(val) => (recordList = val)" res="res.data.list" url="/app/wallet/list"
        :data="{}">
        <div class="allList" v-if="!recordList.length == 0">
          <div class="recordList" v-for="(ele, index) in recordList" :key="index">
            <div class="timeState">
              <p class="state font4">
                {{
                  ele.state == 1 ? $t("待审核") : ele.state == 2 ? $t("通过") : ele.state == 3 ? $t("驳回") : $t("已取消")
                }}
              </p>
              <p v-show="ele.state == 3">{{ ele.remarks }}</p>
              <p class="time grey">{{ ele.create_time }}</p>
            </div>
            <div class="btnNum">
              <div class="num font4 fontBold">{{ ele.withdrawal_num }}</div>
              <div class="btn" @click="onCancel(ele.id)" v-show="ele.state == 1">
                {{ $t('取消') }}
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>
    <!-- <div class="pagination">
      <van-pagination
        v-model="currentPage"
        :page-count="recordList.total_page"
        mode="simple"
        @change="onPage"
      />
    </div> -->
  </div>
</template>

<script>
import vantUpDown from "../../../vant-extend/vant-up-down/vant-up-down.vue";
export default {
  components: { vantUpDown },
  name: "",
  props: {},
  data() {
    return {
      recordList: {
        list: {
          // {id:1,state:"待审核",time:"2021.02.03 17:00:02",num:"-30.00"},
          // {id:1,state:"待审核",time:"2021.02.03 17:00:02",num:"-30.00"},
          // {id:1,state:"待审核",time:"2021.02.03 17:00:02",num:"-30.00"},
        },
      },
      currentPage: 1,
    };
  },
  created() { },
  mounted() {
    //this.getRecordData()
  },
  methods: {
    // 取消提现
    onCancel(id) {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$post({
        url: "/app/wallet/cancleWithdrawal",
        data: { id: id },
        success: (res) => {
          this.$toast.clear();
          setTimeout(() => {
            this.$refs.child.list_init();
          }, 0);
        },
        tip: () => { },
      });
    },
    // 获取提现记录数据
    // getRecordData() {
    //   this.$toast.loading({
    //     message: "加载中...",
    //     forbidClick: true,
    //   });
    //   this.$get({
    //     url: "/app/wallet/list",
    //     data: {page:this.currentPage, page_size:10},
    //     success: (res) => {
    //       this.$toast.clear();
    //       this.recordList = res.data;
    //     },
    //     tip: () => {},
    //   });
    // },
    // onPage(e) {
    //   this.currentPage = e;
    //   this.getRecordData();
    // },
  },
};
</script>

<style scoped lang='scss'>
.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.grey {
  color: #999;
}

.cashOutRecord {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .allList {
      min-height: $conter2;

      .recordList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4vw;
        border-bottom: 1px solid #f5f5f5;

        .btnNum {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;

          .num {
            color: #14ae95;
          }

          .btn {
            height: 5vw;
            width: 8vw;
            text-align: center;
            line-height: 5vw;
            margin-top: 2vw;
            color: #fff;
            background-color: #f13b10;
            border-radius: 1vw;
          }
        }
      }
    }
  }

  .pagination {
    height: 5.4vh;

    /deep/.van-pagination__item,
    .van-pagination__page-desc {
      height: 5.4vh;
    }
  }
}
</style>