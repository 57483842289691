<template>
  <!-- 上传单号 -->
  <div class="upOrderSn">
    <div class="header_box">
      <van-nav-bar :title="$t('上传单号')" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>
    <div class="content">
      <van-field v-model="delivery_num" :label="$t('配送单号')" :placeholder="$t('请输入配送单号')" clearable />
      <van-field v-model="delivery_com" :label="$t('配送公司')" :placeholder="$t('请输入配送公司')" clearable />
      <div class="btn">
        <van-button type="primary" color="#F13B10" @click="uploadOrder">{{ $t('上传') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      order_id: "",
      delivery_num: "",
      delivery_com: "",
    };
  },
  created() { },
  mounted() {
    this.order_id = this.$route.query.id;
  },
  methods: {
    // 上传
    uploadOrder() {
      this.$post({
        url: "/app/order_goods/buyerDelivery",
        data: {
          order_id: this.order_id,
          delivery_num: this.delivery_num,
          delivery_com: this.delivery_com,
        },
        success: (res) => {
          this.$toast(this.$t('上传成功'));

          setTimeout(() => {
            this.xz_back()
            // this.$router.replace({
            //   name: "orderDetails",
            //   query: { state: 7, id: this.order_id },
            // });
          }, 500);
        },
        tip: (val) => { },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.upOrderSn {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    /deep/.van-cell {
      width: 100vw;
      height: 14vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {}
    }

    /deep/.van-cell::after {
      border: 0;
    }

    .btn {
      margin: 45vh auto 0;
      width: 79vw;

      /deep/.van-button {
        width: 79vw;
        height: 15vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
  }
}
</style>