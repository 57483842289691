<template>
  <!-- 确认订单 -->
  <div class="confirmOrder greyBc">
    <div class="header_box">
      <van-nav-bar :title="$t('确认订单')" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>

    <div class="content">
      <div class="personalInformation whiteBc padding04" @click="goAddressList(addressInof.id)">
        <img src="../../../assets/img/orderDateils_tel_address_icon.png" alt=""
          style="width: 7vw; height: 7vw; margin-right: 4vw" />
        <div v-if="!addressInof.id" class="contact tel font4 fontBold">
          {{ $t('请去添加地址') }}
        </div>
        <div v-if="addressInof.id" class="contact">
          <div class="tel font4 fontBold">
            {{ addressInof.name }} {{ mask_code(addressInof.phone) }}
          </div>
          <div class="address">
            {{
              addressInof.area_name.split(' ')[0] +
              "********"
            }}
          </div>
        </div>
        <van-icon name="arrow" size="18" />
      </div>
      <div class="productInformation whiteBc">
        <div class="imgTite" v-for="val in goods_list" :key="val.id">
          <img :src="val.goods_thumb" alt="" style="
              width: 23vw;
              height: 23vw;
              border-radius: 2vw;
              overflow: hidden;
              margin-right: 3vw;
              object-fit: cover;
            " />
          <div class="tite">
            <h3 class="one">{{ val.goods_name }}</h3>
            <div class="specs"></div>
            <div class="priceNum">
              <div class="orange fontBold font4">{{ $t('元') }}{{ val.goods_price }}</div>
              <div class="num grey">X{{ val.amount }}</div>
            </div>
          </div>
        </div>
        <div class="altogether grey">
          <span>{{ $t('共') }}{{ orderdata.goods_num }}{{ $t('件') }} {{ $t('小计') }}</span><span
            class="orange font4 fontBold">{{ $t('元') }}{{
              orderdata.total_price
            }}</span>
        </div>
      </div>
    </div>

    <van-goods-action>
      <div class="numPrice">
        {{ $t('共') }}{{ orderdata.goods_num }}{{ $t('件') }}，{{ $t('合计') }}：{{ $t('元') }}{{ orderdata.pay_price }}
      </div>
      <van-goods-action-button type="danger" :text="$t('结算')" color="#F13B10" @click="onIsAddress" />
    </van-goods-action>
    <!-- 输入密码弹窗 -->
    <van-dialog v-model="passshow" :title="$t('交易密码')" show-cancel-button :before-close="onConfirmBtn">
      <van-cell-group>
        <van-field v-model="transactionPw" :label="$t('交易密码')" type="password" :placeholder="$t('请输入交易密码')"
          :border="false" clearable maxlength="6" />
      </van-cell-group>
    </van-dialog>
    <!-- 支付成功弹窗 -->
    <div class="paymentSuccessfulPopup" v-show="showSuccessful">
      <div class="paymentSuccessful">
        <div class="closeIcon" @click="showSuccessful = false">
          <van-icon name="close" size="18" />
        </div>
        <p class="font4">{{ $t('支付成功') }}</p>
        <img src="../../../assets/img/login_bc@2x.png" alt="" style="width: 34vw; height: 34vw; margin-top: 4vw" />
        <span class="grey">{{ $t('订单金额') }} {{ $t('元') }}{{ ordersinfo.order_price }}</span>
        <div class="orderNum">
          <span class="grey">{{ $t('订单单号') }}:{{ ordersinfo.order_sn }}</span>
          <span class="btnCody" v-clipboard:copy="ordersinfo.order_sn" v-clipboard:success="onCopy"
            v-clipboard:error="onError">{{ $t('复制') }}</span>
        </div>
        <div class="btn">
          <van-button type="primary" color="#F13B10" @click="
            $router.push({
              name: 'gradeDiscount',
              query: { sn: ordersinfo.order_sn },
            })
            ">{{ $t('查看其他等级购买价格') }}</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      ids: this.$route.query.ids,

      goods_list: {}, //商品信息
      orderdata: {}, //未提交订单信息
      ordersinfo: {}, //订单信息

      transactionPw: "", //密码
      // 输入密码弹窗
      passshow: false,
      // 支付成功弹窗
      showSuccessful: false,
      // 地址信息
      addressInof: {},
      address_id: "", //这是从选择地址里返回的地址id
    };
  },
  created() {
    this.address_id = this.$route.query.address_id;
    this.getdata();
    this.getAddressInof(this.address_id);
  },
  methods: {
    getdata() {
      this.$get({
        url: "/app/order_goods/getWaitPayInfo",
        data: {
          goods_json: this.ids,
        },
        success: (res) => {
          this.$toast.clear();
          if (!this.address_id) {
            this.addressInof = res.data.address;
          }
          // this.addressInof = res.data.address
          this.goods_list = res.data.goods_list;
          this.orderdata = res.data;
        },
        tip: () => { },
      });
    },

    // 获取地址信息
    getAddressInof(e) {
      this.$get({
        url: "/app/region/list",
        success: (res) => {
          this.$toast.clear();
          for (let i = 0; i < res.data.length; i++) {
            if (!e) {
              if (res.data[i].is_default == 1) {
                this.addressInof = res.data[i];
              }
            } else {
              if (res.data[i].id == e) {
                this.addressInof = res.data[i];
              }
            }
          }
        },
        tip: () => { },
      });
    },
    // 更换地址
    goAddressList(id) {
      this.$router.replace({
        name: "myAddress",
        query: {
          is_order: true,
          order_id: id,
          goods_id: "",
          specs_id: "",
          amount: "",
          is_seckill: "",
          ids: this.ids,
        },
      });
    },

    onBtn() {
      this.showPopup = true;
    },
    // 结算前判断是否有地址
    onIsAddress() {
      if (!this.addressInof.id) {
        this.$toast(this.$t("请选择收货地址"));
      } else {
        this.passshow = true;
      }
    },

    onConfirmBtn(action, done) {
      if (action == "confirm") {
        if (!this.transactionPw) {
          this.$toast(this.$t("请输入交易密码"));
          done(false);
          return;
        }
        this.$post({
          url: "/app/order_goods/makeOrder",
          data: {
            goods_json: this.ids,
            password: this.transactionPw,
            address_id: this.addressInof.id,
          },
          success: (res) => {
            this.$toast(this.$t("提交成功"));
            this.ordersinfo = res.data;
            this.showPopup = false;
            this.showSuccessful = true;
            done();
          },
          tip: () => {
            done();
          },
        });
      } else {
        done();
      }
    },

    // 复制成功
    onCopy(e) {
      window.sessionStorage.setItem("order_sn", e.text);
      this.$toast(this.$t("复制成功"));
    },
    // 复制失败
    onError() {
      this.$toast(this.$t("复制失败"));
    },
  },
};
</script>

<style scoped lang='scss'>
.grey {
  color: #999;
}

.greyBc {
  background-color: #f9f9f9;
}

.orange {
  color: #f13710;
}

.white {
  color: #fff;
}

.whiteBc {
  background-color: #fff;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop4 {
  margin-top: 4vw;
}

.padding04 {
  padding: 0 4vw;
}

.confirmOrder {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;

  /deep/.van-nav-bar {
    background-color: #fff;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666;
      font-size: 4vw;
    }
  }

  // 支付密码弹窗
  /deep/.van-dialog {
    top: 28vh
  }

  // 内容
  .content {
    padding-top: 6.7vh;
    min-height: 86.7vh;
    height: auto;

    .personalInformation {
      display: flex;
      align-items: center;
      margin-bottom: 3vw;

      .contact {
        padding: 4vw 0;
        width: 80vw;
      }
    }

    .productInformation {
      padding: 0 4vw;

      .imgTite {
        display: flex;
        justify-content: space-between;
        padding: 4vw 0;
        border-bottom: 1px solid #f5f5f5;

        .tite {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 66vw;

          .specs {
            width: 100%;
            color: #999;
            font-size: 3.4vw;
          }

          .priceNum {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .altogether {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 13vw;
      }
    }
  }

  /deep/.van-goods-action {
    display: flex;
    align-items: center;
    width: 100%;

    .numPrice {
      display: flex;
      align-content: center;
      justify-content: flex-end;
      padding: 0 4vw;
      width: 68vw;
      // /deep/.van-radio__label {
      //   line-height: 6.5vw;
      // }
    }

    .van-button {
      width: 32vw;
      height: 13vw;
      border-radius: 0vw;
    }

    .van-button--normal {
      padding: 0;
      font-size: 3vw;
    }

    .van-goods-action-button--last,
    .van-goods-action-button--first {
      margin: 0;
    }
  }

  // 确认订单弹窗
  .passwordPopup {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 4vw;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .pwInput {
      position: relative;
      margin-top: 61vw;
      width: 92vw;
      height: 48vw;
      background-color: #fff;
      overflow: hidden;

      >p {
        display: flex;
        justify-content: center;
        margin: 5vw 0 6vw;
      }

      .btn {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 92vw;
        height: 15vw;
        border-top: 1px solid #f9f9f9;

        .cancelBtn,
        .confirmBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 4vw;
        }

        .confirmBtn {
          border-left: 1px solid #f9f9f9;
        }
      }

      // /deep/.van-password-input__security {
      //   display: flex;
      //   justify-content: space-between;
      //   height: 9vw;
      // }
    }
  }

  // 支付成功弹窗
  .paymentSuccessfulPopup {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 4vw;
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: 0.2);

    .paymentSuccessful {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 54vw;
      width: 92vw;
      height: 94vw;
      background-color: #fff;
      overflow: hidden;
      border-radius: 2vw;

      .closeIcon {
        position: absolute;
        top: 5vw;
        right: 4vw;
      }

      >p {
        display: flex;
        justify-content: center;
        margin-top: 5vw;
      }

      >span {
        margin: 6vw 0 3vw;
      }

      .orderNum {
        margin-bottom: 7vw;

        .btnCody {
          margin-left: 1vw;
          padding: 0 1vw;
          border-radius: 1vw;
          color: #fff;
          background-color: #f13710;
        }
      }

      .btn {
        display: flex;
        justify-content: center;

        /deep/.van-button {
          width: 71vw;
          height: 11vw;
          border-radius: 1vw;
        }

        /deep/.van-button--normal {
          padding: 0;
          font-size: 3vw;
        }
      }
    }
  }
}
</style>