<template>
  <!-- 公司介绍 -->
  <div class="companyDetails">
    <div class="header_box">
      <van-nav-bar :title="$t('公司介绍')" left-arrow @click-left="$router.replace('/')" :border="false" />
    </div>
    <div class="content" v-if="companyDetails">
      <div>{{ $t('发布时间') }}：{{ companyDetails.create_time }}</div>
      <div class="detailsInformation" v-html="companyDetails.content">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "companyDetails",
  props: {},
  data() {
    return {
      companyDetails: {},
    }
  },
  created() {
    this.get_companyDetails()
  },
  methods: {
    get_companyDetails() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      })
      this.$get({
        url: "/app/extend/getCompay",
        success: (res) => {
          this.$toast.clear()
          this.companyDetails = res.data
        },
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.companyDetails {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;
  overflow-y: hidden;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  // 二级
  .content {
    padding: 4vw;
    padding-top: 6.7vh;
    min-height: $conter2;
    height: auto;
  }

  .detailsInformation {
    /deep/span {
      font-size: 4vw;
      color: #333;
    }

    /deep/p {
      font-size: 4vw !important;
      color: #333 !important;
      clear: both !important;
      line-height: normal !important;
      text-indent: inherit !important;
    }

    /deep/img {
      width: 100% !important;
      object-fit: cover !important;
    }
  }
}
</style>