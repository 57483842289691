<template>
  <!-- 公告页面 -->
  <div class="noticeList">
    <div class="header_box">
      <van-nav-bar :title="$t('公告')" left-arrow @click-left="$router.replace('/')" :border="false" />
    </div>

    <vant-up-down @list="(val) => (list = val)" res="res.data.list" url="/app/announcement/getAnnouncementList" data="">
      <div class="noticeAll">
        <div class="noticeInformation" v-for="(item, index) in list" :key="index"
          @click="xz_go('noticeDetails', item.id)">
          <div class="content">
            <h3>{{ item.title }}</h3>
            <span class="explain">{{ item.introduction }}</span>
            <span class="date">{{ item.create_time }}</span>
          </div>
          <!-- <img
          src="../../../assets/img/index_whole_shop.png"
          alt=""
          style="
            width: 21vw;
            height: 21vw;
            border-radius: 3vw;
            overflow: hidden;
          "
        /> -->
        </div>
      </div>
    </vant-up-down>
  </div>
</template>

<script>
export default {
  name: 'noticeList',
  data() {
    return {
      list: [],
    }
  },
  created() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.noticeList {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  .noticeAll {
    padding: 4vw;
    min-height: $conter2;
    height: auto;

    .noticeInformation {
      padding-bottom: 4vw;
      border-bottom: 0.1vw solid #f5f5f5;

      .content {
        display: flex;
        flex-direction: column;

        >h3,
        span {
          /*文字溢出隐藏显示省略号*/
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .explain {
          font-size: 3vw;
          color: #8c9198;
          font-weight: 600;
        }

        .date {
          font-size: 3vw;
          color: #8c9198;
          transform: scale(0.99);
        }
      }
    }
  }
}
</style>
