<template>
  <div class="VantDown">
    <van-pull-refresh v-model="Down" :success-text="$t('刷新成功')" @refresh="get_down">
      <van-empty :description="$t('这里空空如也~')" v-if="list && list.length == 0" />
      <slot></slot>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  name: "VantDown",
  props: ["url", "data", "res"],
  data() {
    return {
      list: null,
      loading: false,
      Down: false,
    };
  },
  created() {
    this.xz_top();
    this.list_init();
  },
  methods: {
    //初始化数据
    list_init() {
      this.over = false;
      this.get_list("down"); // 加载数据
      console.log(this.list);
    },
    //下拉刷新 下拉的这个 调了getlistla
    get_down() {
      setTimeout(() => {
        this.over = false;
        this.get_list("down"); // 加载数据
      }, 1000);
    },
    // 上拉加载 上拉这个 调用getlist

    // 更多广告
    get_list(type) {
      let data = Object.assign(this.data);
      this.$get({
        url: this.url,
        data: data,
        success: (res) => {
          let data = eval(this.res);
          if (type == "down") {
            this.list = data;
            this.$emit("list", this.list);
          }
          this.Down = false;
        },
        tip: (val) => { },
      });
    },
  },
};
</script>

<style scoped lang="scss">
// 空空如也
.van-empty {
  padding: 50vw 0;
}
</style>