<template>
  <!-- 提现 -->
  <div class="cashOut">
    <header>
      <van-nav-bar :title="$t('提现')" left-arrow :right-text="$t('提现记录')" @click-left="$router.go(-1)"
        @click-right="$router.push('cashOutRecord')" :border="false" />
      <div class="wallet">
        <p class="num fontBold white">{{ cashOutData.balance }}</p>
        <p class="white">{{ $t('余额钱包') }}</p>
      </div>
    </header>

    <div class="content">
      <div class="bankCard" v-if="!user_payment_id" @click="go_to(1)">
        Select payment method
      </div>
      <div class="bankCardInfo" v-if="user_payment_id" @click="go_to(1)">
        <div class="info">
          <div class="num">
            {{ user_payment_name1 }}：{{ user_payment_value1 }}
          </div>
          <div class="num">
            {{ user_payment_name2 }}：{{ user_payment_value2 }}
          </div>
        </div>
        <div>
          <van-icon name="arrow" size="16" color="#fff" />
        </div>
      </div>
      <van-field clearable v-model="receivedQuantity" :label="$t('提现数量')" :placeholder="$t('请输入提现数量')"
        input-align="right" />
      <van-field clearable v-model="cashOutQuantity" :label="$t('到账数量')" :placeholder="$t('自动计算到账数量')"
        input-align="right" />
      <div class="rate orange padding04">
        {{ $t('手续费') }}{{ cashOutData.withdrawal_set.withdrawal_rate }}%
      </div>
    </div>

    <van-goods-action>
      <van-goods-action-button color="#F13B10" type="danger" :text="$t('确认提现')" @click="passshow = true" />
    </van-goods-action>

    <van-dialog v-model="passshow" :title="$t('交易密码')" show-cancel-button :before-close="onWithdrawal">
      <van-cell-group>
        <van-field v-model="transactionPw" :label="$t('交易密码')" type="password" :placeholder="$t('请输入交易密码')"
          :border="false" clearable maxlength="6" />
      </van-cell-group>
    </van-dialog>

    <!-- 自定义图标 -->
    <van-popup v-model="show" closeable close-icon="close" position="bottom" :style="{ height: '40%' }">
      <div class="popupBox">
        <div class="tite">{{ errDate.title }}</div>
        <div class="popupContent">
          <div v-html="errDate.content"></div>
        </div>
        <van-goods-action>
          <van-goods-action-button color="#F13B10" type="danger" :text="$t('确认')" @click="show = false" />
        </van-goods-action>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      user_payment_id: '',
      user_payment_name1: '',
      user_payment_name2: '',
      cashOutData: {
        balance: "", //可用余额
        withdrawal_set: {
          begin_time: "", //提现开始时间-整点
          end_time: "", //提现结束时间-整点
          withdrawal_rate: "", //提现手续费（%）
          withdrawal_min_num: "", //提现最小金额
          withdrawal_max_num: "", //提现最大金额
          withdrawal_state: "", //提现开关 :1：开启  2：关闭
        },
        //银行卡数据
        wallet: {
          id: "",
          bank_man: "",
          bank_deposit: "",
          bank_no: "",
        },
      },
      receivedQuantity: "",
      transactionPw: "",

      // 输入密码弹窗
      passshow: false,

      // 错误弹窗
      show: false,
      errDate: {},
    };
  },
  computed: {
    cashOutQuantity: {
      get() {
        return this.receivedQuantity == ""
          ? ""
          : this.receivedQuantity -
          this.receivedQuantity *
          this.cashOutData.withdrawal_set.withdrawal_rate *
          0.01;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  created() {

    let user_payment_id = localStorage.getItem("user_payment_id")
    let user_payment_name1 = localStorage.getItem("user_payment_name1")
    let user_payment_name2 = localStorage.getItem("user_payment_name2")
    let user_payment_value1 = localStorage.getItem("user_payment_value1")
    let user_payment_value2 = localStorage.getItem("user_payment_value2")

    if (user_payment_id) {
      this.user_payment_id = user_payment_id
      this.user_payment_name1 = user_payment_name1
      this.user_payment_name2 = user_payment_name2
      this.user_payment_value1 = user_payment_value1
      this.user_payment_value2 = user_payment_value2
    }
  },
  mounted() {
    this.getCashOutData();
  },
  methods: {
    go_to(id) {

      this.$router.push({
        name: "bankCardList01",
        query: {
          opt_id: id,
        },
      });
    },
    // 获取可提现数据
    getCashOutData() {
      this.$get({
        url: "/app/wallet/getWithdrawalPageinfo",
        success: (res) => {
          this.cashOutData = res.data;
        },
        tip: () => { },
      });
    },
    // 发起提现
    onWithdrawal(action, done) {
      if (action == "confirm") {
        if (!this.transactionPw) {
          this.$toast(this.$t("请输入交易密码"));
          done(false);
          return;
        }
        this.$post({
          url: "/app/wallet/withdrawal",
          data: {
            amount: this.receivedQuantity,
            password: this.transactionPw,
            user_payment_id: this.user_payment_id,
          },
          success: (res) => {
            console.log(res);
            this.$toast(this.$t("提交成功"));
            this.passshow = false;
            setTimeout(() => {
              this.$router.push('cashOutRecord')
            }, 500)
          },
          tip: (val) => {
            done(false)
            if (val.code == -4) {
              this.show = true;
              this.passshow = false;
              this.errDate = val.data;
            }
          },
        });
      } else {
        done();
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.grey {
  color: #999;
}

.greyBc {
  background-color: #f9f9f9;
}

.orange {
  color: #f13710;
}

.white {
  color: #fff;
}

.whiteBc {
  background-color: #fff;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop4 {
  margin-top: 4vw;
}

.padding04 {
  padding: 0 4vw;
}

.cashOut {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #fff;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #fff;
    }

    .van-nav-bar__text {
      color: #fff;
    }
  }

  header {
    width: 100vw;
    padding-bottom: 2vw;
    background-color: #f13b10;

    .wallet {
      display: flex;
      flex-direction: column;
      width: 100vw;
      align-items: center;

      .num {
        margin: 8vw 0 4vw;
        font-size: 5vw;
      }
    }
  }

  // 支付密码弹窗
  /deep/.van-dialog {
    top: 28vh
  }

  // 内容
  .content {
    width: 100%;
    overflow-y: auto;

    /deep/.van-cell {
      width: 100vw;
      height: 14vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {
        justify-content: flex-end;
      }
    }

    /deep/.van-cell::after {
      border: 0;
    }

    .bankCard {
      font-size: 4vw;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 4vw auto 0;
      padding: 4vw;
      width: 92vw;
      height: 24vw;
      background: url("../../../assets/img/bank_card_bc1.png") no-repeat;
      background-size: cover;
    }

    .bankCardInfo {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4vw auto 0;
      padding: 4vw;
      width: 92vw;
      height: 30vw;
      background: url("../../../assets/img/bank_card_bc1.png") no-repeat;
      background-size: cover;
      border-radius: 4vw;

      .info {

        // display: flex;
        // flex: 1;
        // flex-direction: column;
        // justify-content: space-between;
        .nameType {
          display: flex;
          align-content: center;
          margin-bottom: 1vw;

          .name {
            margin-right: 5vw;
            font-size: 5vw;
            color: #fff;
          }

          .type {
            font-size: 3vw;
            color: #fff;
          }
        }

        .num {
          font-size: 3.5vw;
          color: #fff;
        }
      }
    }

    .rate {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 13vw;
      border-top: 3vw solid #f9f9f9;
    }
  }

  // 弹窗
  .popupBox {
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tite {
      margin-top: 5vw;
      font-size: 4vw;
      font-weight: bold;
    }

    .popupContent {
      min-height: calc(40vh - 10vw - 50px);
      height: auto;
    }
  }

  /deep/.van-goods-action {
    width: 100%;
  }
}
</style>