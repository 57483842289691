<template>
  <!-- 设置个人资料 -->
  <div class="setPersonalData">
    <div class="header_box">
      <van-nav-bar :title="$t('个人资料')" left-arrow @click-left="$router.replace('mySet')" :border="false" />
    </div>
    <div class="content">
      <div class="editImg">
        <!-- <img
          :src="image"
          alt=""
          style="
            width: 20vw;
            height: 20vw;
            border-radius: 2vw;
            overflow: hidden;
          "
        /> -->
        <van-cell center title="" class="up_img_box">
          <template #label>
            <van-uploader :after-read="upImgFun" class="img">
              <van-image :src="httpPath + headimgurl" fit="contain" v-if="headimgurl"></van-image>
              <van-image :src="image" fit="contain" v-else />
            </van-uploader>
          </template>
        </van-cell>
        <p>{{ $t('修改头像') }}</p>
      </div>
      <!-- <van-field
        v-model="name"
        label="姓名"
        placeholder="请输入名字"
        input-align="right"
      />
      <van-field
        v-model="tel"
        label="手机号"
        placeholder="请输入手机号"
        input-align="right"
      /> -->
      <div class="btn">
        <van-button type="primary" color="#F13B10" @click="onPreservation">{{ $t('保存') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      image: "",
      headimgurl: "",
      // name:"",
      // tel:"",
    };
  },
  created() { },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/user/getUserInfo",
        success: (res) => {
          this.$toast.clear();
          if (res.code == 1) {
            this.image = res.data.image;
          }
        },
        tip: () => { },
      });
    },
    // 上传图片
    upImgFun(file) {
      var formData = new FormData();
      formData.append("image", file.file);
      this.$post({
        url: "/app/tool/uploadImage",
        data: formData,
        upload: true,
        success: (res) => {
          this.headimgurl = res.data;
          console.log(this.headimgurl);
        },
      });
    },
    // 保存
    onPreservation() {
      this.$post({
        url: "/app/user/updateImage",
        data: { image: this.headimgurl },
        success: (res) => {
          this.$toast(this.$t("保存成功"))
        },
        tip: () => { },
      });
    }
  },
};
</script>

<style scoped lang='scss'>
.setPersonalData {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .editImg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      background-color: #f9f9f9;

      >p {
        color: #999999;
      }

      .up_img_box::after {
        border-bottom: 0 !important;
      }

      // 二维码
      /deep/.up_img_box {
        background: #f9f9f9;
        // border-radius: 3vw;
        font-size: 3.2vw;
        color: #666;
        height: 17vh;

        .van-cell__label {
          margin-top: 2vw;
        }

        .img {
          display: flex;
          justify-content: center;
        }

        .van-image {
          background: #fff;
          border-radius: 50%;
          border: 2px solid #fff;
          overflow: hidden;
          height: 25vw;
          width: 25vw;
          display: block;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
          }
        }
      }
    }

    /deep/.van-cell {
      width: 100vw;
      height: 14vw;
      padding: 0 4vw;
      border-bottom: 1px solid #f5f5f5;

      .van-field__label,
      .van-cell__value {
        display: flex;
        align-items: center;
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {
        justify-content: flex-end;
      }
    }

    /deep/.van-cell::after {
      border: 0;
    }

    .btn {
      margin: 40vh auto 0;
      width: 79vw;

      /deep/.van-button {
        width: 79vw;
        height: 15vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
  }
}
</style>