<template>
  <swiper :options="swiperOptions">
    <swiper-slide>Slide 1</swiper-slide>
    <swiper-slide>Slide 2</swiper-slide>
    <swiper-slide>Slide 3</swiper-slide>
    <swiper-slide>Slide 4</swiper-slide>
    <swiper-slide>Slide 5</swiper-slide>
  </swiper>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  //npm install --save swiper@5.4.5  安装时指定版本，因为最新的版本会出错，有了swiper才能安装vue-awesome-swiper
  import 'swiper/css/swiper.css'
  export default {
    name: 'carrousel',
	components: {
	    Swiper,
	    SwiperSlide
	  },
    data() {
      return {
       
      }
    },
    computed: {
    },
  }
</script> 