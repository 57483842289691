var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"searchCommodity"},[_c('div',{staticClass:"header_box"},[_c('van-nav-bar',{attrs:{"title":_vm.$t('搜索商品'),"left-arrow":"","border":false},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"searchBox"},[_c('van-search',{attrs:{"shape":"round","placeholder":_vm.$t('请输入搜索关键词')},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.onSearch()}}},[_c('van-icon',{attrs:{"name":"search","size":"18"}})],1)]},proxy:true}]),model:{value:(_vm.searchContent),callback:function ($$v) {_vm.searchContent=$$v},expression:"searchContent"}})],1)],1),_c('div',{staticClass:"container"},[_c('vant-up-down',{ref:"child",attrs:{"res":"res.data.list","url":"/app/goods/list","data":{
      search: _vm.searchContent,
      cat_id: 0,
      brand_id: 0,
      is_recommend: 0,
      is_seckill: 2,
    }},on:{"list":(val) => (_vm.searchList = val)}},[_c('div',{staticClass:"content"},_vm._l((_vm.searchList),function(ele){return _c('div',{key:ele.id,staticClass:"list"},[_c('div',{staticClass:"productInformation"},[_c('div',{staticClass:"imgTite",on:{"click":function($event){return _vm.xz_go('productDetailsOrEvaluation', ele.id)}}},[_c('img',{staticStyle:{"width":"23vw","height":"23vw","border-radius":"2vw","overflow":"hidden","margin":"4vw 3vw 4vw 0vw"},attrs:{"src":ele.goods_thumb,"alt":""}}),_c('div',{staticClass:"tite"},[_c('h3',[_vm._v(_vm._s(ele.goods_name))]),_c('div',{staticClass:"priceNum"},[_c('div',[_c('div',{staticClass:"originalPrice grey"},[_vm._v(" "+_vm._s(_vm.$t('元'))+_vm._s(ele.goods_price)+" ")]),_c('div',{staticClass:"orange"},[_c('span',{staticClass:"font4 fontBold"},[_vm._v(" "+_vm._s(_vm.$t('元'))+_vm._s(ele.discount_price)+" ")])])]),_c('div',{staticClass:"num grey"},[_vm._v(_vm._s(_vm.$t('已售'))+_vm._s(ele.goods_sales)+_vm._s(_vm.$t('件')))])])])])])])}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }