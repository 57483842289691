module.exports = {
  "请输入搜索关键词": "请输入搜索关键词",
  "加载中...": "加载中...",
  "综合": "综合",
  "价格": "价格",
  "销量": "销量",
  "人": "人",
  "付款": "付款",
  "确认订单": "确认订单",
  "请去添加地址": "请去添加地址",
  "规格": "规格",
  "共": "共",
  "件": "件",
  "小计": "小计",
  "合计": "合计",
  "结算": "结算",
  "交易密码": "交易密码",
  "请输入交易密码": "请输入交易密码",
  "支付成功": "支付成功",
  "订单金额": "订单金额",
  "订单单号": "订单单号",
  "复制": "复制",
  "查看其他等级购买价格": "查看其他等级购买价格",
  "请选择收货地址": "请选择收货地址",
  "提交成功": "提交成功",
  "复制成功": "复制成功",
  "复制失败": "复制失败",
  "联系客服": "联系客服",
  "公司介绍": "公司介绍",
  "发布时间": "发布时间",
  "购买价格": "购买价格",
  "全场会员折扣": "全场会员折扣",
  "等级利润": "等级利润",
  "转卖价格": "转卖价格",
  "转卖利润": "转卖利润",
  "收益计算": "收益计算",
  "请输入数量": "请输入数量",
  "开始计算": "开始计算",
  "抢购价": "抢购价",
  "元": "元",
  "转卖价": "转卖价",
  "选择抢购专区商品": "选择抢购专区商品",
  "新款商品": "新款商品",
  "活动商品": "活动商品",
  "资讯详情": "资讯详情",
  "资讯中心": "资讯中心",
  "会员福利": "会员福利",
  "当前会员等级": "当前会员等级",
  "已领取": "已领取",
  "领取": "领取",
  "会员升级礼金": "会员升级礼金",
  "天天现金红包": "天天现金红包",
  "每月话费额度": "每月话费额度",
  "每月油卡额度": "每月油卡额度",
  "升级更高等级获得更多权益": "升级更高等级获得更多权益",
  "点击联系客服升级": "点击联系客服升级",
  "请输入手机号": "请输入手机号",
  "请输入油卡卡号": "请输入油卡卡号",
  "确定": "确定",
  "您已经领取过该权益": "您已经领取过该权益",
  "公告详情": "公告详情",
  "公告": "公告",
  "商品详情": "商品详情",
  "库存": "库存",
  "评价": "评价",
  "商家回复": "商家回复",
  "商家未回复，请耐心等待": "商家未回复，请耐心等待",
  "购物车": "购物车",
  "已收藏": "已收藏",
  "收藏": "收藏",
  "加入购物车": "加入购物车",
  "立即购买": "立即购买",
  "数量": "数量",
  "收藏成功": "收藏成功",
  "取消收藏成功": "取消收藏成功",
  "刷新成功": "刷新成功",
  "这里空空如也~": "这里空空如也~",
  "人付款": "人付款",
  "等级制度": "等级制度",
  "点击联系客服": "点击联系客服",
  "请去添加收货地址": "请去添加收货地址",
  "选择规格": "选择规格",
  "提交订单": "提交订单",
  "请选择规格": "请选择规格",
  "抢购商品详情": "抢购商品详情",
  "立即抢购": "立即抢购",
  "关闭": "关闭",
  "搜索商品": "搜索商品",
  "已售": "已售",
  "未开始": "未开始",
  "已结束": "已结束",
  "完成": "完成",
  "编辑": "编辑",
  "没有更多了": "没有更多了",
  "删除": "删除",
  "全选": "全选",
  "立即付款": "立即付款",
  "确定删除": "确定删除",
  "隐私协议": "隐私协议",
  "注册协议": "注册协议",
  "下载安装": "下载安装",
  "适用于安卓手机": "适用于安卓手机",
  "扫描二维码下载APP": "扫描二维码下载APP",
  "登录": "登录",
  "用户名": "用户名",
  "请输入用户名": "请输入用户名",
  "密码": "密码",
  "请输入您的登录密码": "请输入您的登录密码",
  "立即注册": "立即注册",
  "忘记密码": "忘记密码",
  "在线客服": "在线客服",
  "请输入登录密码": "请输入登录密码",
  "登录成功": "登录成功",
  "验证码": "验证码",
  "请输入验证码": "请输入验证码",
  "获取验证码": "获取验证码",
  "后获取": "后获取",
  "登录密码": "登录密码",
  "请输入登录密码(数字加字母6 - 12)": "请输入登录密码(数字加字母6 - 12)",
  "确认登录密码": "确认登录密码",
  "请确认登录密码": "请确认登录密码",
  "请输入正确格式的手机号": "请输入正确格式的手机号",
  "发送成功": "发送成功",
  "登录密码必须是6至12位的字母数字组合": "登录密码必须是6至12位的字母数字组合",
  "请输入确认登录密码": "请输入确认登录密码",
  "两次输入的登录密码不一致": "两次输入的登录密码不一致",
  "修改成功": "修改成功",
  "请设置6-12位登录密码": "请设置6-12位登录密码",
  "确认交易密码": "确认交易密码",
  "请输入确认交易密码": "请输入确认交易密码",
  "注册": "注册",
  "登录即代表阅读并同意": "登录即代表阅读并同意",
  "和": "和",
  "注册成功": "注册成功",
  "姓名": "姓名",
  "收货人姓名": "收货人姓名",
  "电话": "电话",
  "收货人手机号": "收货人手机号",
  "地区": "地区",
  "请选择省 / 市 / 区": "请选择省 / 市 / 区",
  "设为默认收货地址": "设为默认收货地址",
  "保存": "保存",
  "添加地址": "添加地址",
  "编辑地址": "编辑地址",
  "我的银行卡": "我的银行卡",
  "使用": "使用",
  "添加银行卡": "添加银行卡",
  "提现": "提现",
  "提现记录": "提现记录",
  "余额钱包": "余额钱包",
  "去添加银行卡": "去添加银行卡",
  "提现数量": "提现数量",
  "请输入提现数量": "请输入提现数量",
  "到账数量": "到账数量",
  "自动计算到账数量": "自动计算到账数量",
  "手续费": "手续费",
  "确认提现": "确认提现",
  "确认": "确认",
  "待审核": "待审核",
  "通过": "通过",
  "驳回": "驳回",
  "已取消": "已取消",
  "取消": "取消",
  "本月收益": "本月收益",
  "收益": "收益",
  "转卖订单号": "转卖订单号",
  "由": "由",
  "进行转卖": "进行转卖",
  "本月": "本月",
  "推广名片": "推广名片",
  "推广享优惠": "推广享优惠",
  "复制链接": "复制链接",
  "资金记录": "资金记录",
  "全部": "全部",
  "简体中文": "简体中文",
  "日语": "日语",
  "收货地址": "收货地址",
  "默认": "默认",
  "设为默认": "设为默认",
  "使用中": "使用中",
  "提示": "提示",
  "确认要删除吗?": "确认要删除吗?",
  "删除成功": "删除成功",
  "确认要使用此地址码?": "确认要使用此地址码?",
  "绑定成功": "绑定成功",
  "我的收藏": "我的收藏",
  "取消收藏": "取消收藏",
  "取消成功": "取消成功",
  "条评价": "条评价",
  "评论内容": "评论内容",
  "订单号": "订单号",
  "我的资料": "我的资料",
  "修改交易密码": "修改交易密码",
  "修改登录密码": "修改登录密码",
  "选择语言": "选择语言",
  "请输入姓名": "请输入姓名",
  "开户行": "开户行",
  "请输入开户行": "请输入开户行",
  "银行卡号": "银行卡号",
  "请输入银行卡号": "请输入银行卡号",
  "绑定银行卡": "绑定银行卡",
  "旧密码": "旧密码",
  "请输入旧密码": "请输入旧密码",
  "确认密码": "确认密码",
  "请再次输入登录密码": "请再次输入登录密码",
  "个人资料": "个人资料",
  "修改头像": "修改头像",
  "保存成功": "保存成功",
  "请再次输入交易密码": "请再次输入交易密码",
  "订单详情": "订单详情",
  "待支付": "待支付",
  "已支付": "已支付",
  "已发货": "已发货",
  "已收货": "已收货",
  "已完成": "已完成",
  "退货申请中": "退货申请中",
  "退货中待发货": "退货中待发货",
  "退货中已发货": "退货中已发货",
  "退款成功": "退款成功",
  "订单取消": "订单取消",
  "转卖中": "转卖中",
  "转卖完成": "转卖完成",
  "倒计时": "倒计时",
  "订单总数": "订单总数",
  "总金额": "总金额",
  "创建时间": "创建时间",
  "申请退货": "申请退货",
  "上传单号": "上传单号",
  "确认收货": "确认收货",
  "立即评价": "立即评价",
  "立即支付": "立即支付",
  "收货成功": "收货成功",
  "此时此地，想和大家分享什么": "此时此地，想和大家分享什么",
  "请输入退货理由": "请输入退货理由",
  "提交": "提交",
  "评价完成": "评价完成",
  "提交申请成功": "提交申请成功",
  "配送单号": "配送单号",
  "请输入配送单号": "请输入配送单号",
  "配送公司": "配送公司",
  "请输入配送公司": "请输入配送公司",
  "上传": "上传",
  "上传成功": "上传成功",
  "转卖中心": "转卖中心",
  "待转卖": "待转卖",
  "已转卖": "已转卖",
  "已失败": "已失败",
  "订单编号": "订单编号",
  "商品原价": "商品原价",
  "转卖金额": "转卖金额",
  "利润": "利润",
  "名牌购商城": "名牌购商城",
  "全部商品": "全部商品",
  "超值正版好物": "超值正版好物",
  "抢购专区": "抢购专区",
  "限时抢购，抢抢抢": "限时抢购，抢抢抢",
  "轻松出手，赚赚赚": "轻松出手，赚赚赚",
  "查看全部": "查看全部",
  "品牌推荐": "品牌推荐",
  "更多": "更多",
  "现价": "现价",
  "充值": "充值",
  "我的评价": "我的评价",
  "资金明细": "资金明细",
  "退出登录": "退出登录",
  "确认要退出登录吗?": "确认要退出登录吗?",
  "退出登录成功": "退出登录成功",
  "全部订单": "全部订单",
  "未转卖": "未转卖",
  "转卖成功": "转卖成功",
  "转卖失败": "转卖失败",
  "订单类型": "订单类型",
  "商品购买": "商品购买",
  "幸运抢购": "幸运抢购",
  "幸运爆单": "幸运爆单",
  "幸运连单": "幸运连单",
  "去评价": "去评价",
  "退款中": "退款中",
  "查看详情": "查看详情",
  "售后": "售后",
  "转卖区": "转卖区",
  "转卖记录": "转卖记录",
  "请输入订单编号": "请输入订单编号",
  "粘贴": "粘贴",
  "确认转卖": "确认转卖",
  "查看其他等级转卖利润": "查看其他等级转卖利润",
  "请先输入订单编号": "请先输入订单编号",
  "首页": "首页",
  "抢购区": "抢购区",
  "订单": "订单",
  "我的": "我的",
  "银行名称": "银行名称",

  "接口不存在": "接口不存在",
  "未登录": "未登录",
  "服务器内部错误": "服务器内部错误",

  "详细地址": "详细地址",
  "街道门牌、楼层房间号等": "街道门牌、楼层房间号等",
  "加入成功": "加入成功",

  "上传单号": "上传单号",
  "请输入银行名称": "请输入银行名称",
  "联系电话": "联系电话",

  "请选择商品！": "请选择商品！",
  "订单超时了,请联系客服": "订单超时了,请联系客服",
  "身份证明书": "身份证明书",
  "邮政编码": "邮政编码",
  "自动生成": "自动生成",
  "银行支行": "银行支行",
  "请输入银行支行": "请输入银行支行"
}