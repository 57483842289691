<template>
  <!-- 收益计算 -->
  <div class="incomeCalculation">
    <div class="header_box">
      <van-nav-bar :title="$t('收益计算')" left-arrow @click-left="$router.replace('/')" :border="false" />
    </div>

    <div class="content">
      <img src="../../../assets/img/incomeCalculation_bc.png" alt="" style="
          width: 92vw;
          height: 50vw;
          border-radius: 2vw;
          overflow: hidden;
          margin-top: 4vw;
        " />
      <div>
        <van-dropdown-menu :overlay="false" active-color="#F1AC10">
          <van-dropdown-item v-model="brandId" :options="rushGoodList" />
        </van-dropdown-menu>

        <van-field v-model="amount" type="digit" :placeholder="$t('请输入数量')" :border="false" clearable />
        <van-button type="primary" color="#F1AC10" block @click="onCalculation">{{ $t('开始计算') }}</van-button>
        <div class="resultBox" v-show="show" v-for="ele in alculationResults" :key="ele.id">
          <img src="../../../assets/img/incomeCalculation_coin.png" alt=""
            style="
              width: 14vw;height: 14vw;border-radius: 2vw;overflow:hidden; margin-top: 4vw;margin-left: 4vw;margin-right: 3vw;" />
          <div class="resultInformation">
            <h3>{{ ele.vip_name }}</h3>
            <div class="resultInformation">
              <!-- <span>抢购商品:{{ele.goods_name}}</span> -->
              <span>{{ $t('抢购价') }}:{{ $t('元') }}{{ ele.seckill_price }}</span>
              <!-- <span>会员折上折:{{ele.vip_price}}元</span> -->
              <span>{{ $t('转卖价') }}:{{ $t('元') }}{{ ele.transfer_price }}</span>
              <div class="revenueResults">{{ $t('转卖利润') }}:{{ $t('元') }}{{ ele.transfer_profit }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "incomeCalculation",
  props: {},
  data() {
    return {
      // selectShop: "",
      // shopList: [{ name: "鞋子" }, { name: "衣服" }],

      brandId: 0,
      rushGoodList: [
        { text: this.$t("选择抢购专区商品"), value: 0 },
        { text: this.$t("新款商品"), value: 1 },
        { text: this.$t("活动商品"), value: 2 },
      ],
      //   showShopChoice: false,
      amount: "",
      // 计算结果
      alculationResults: "",
      show: false,
    };
  },
  created() { },
  mounted() {
    this.getRushGoods();
  },
  methods: {
    // 获取抢购区的商品列表
    getRushGoods() {
      this.$get({
        url: '/app/goods/list',
        data: {
          is_recommend: 0,
          is_seckill: 1,
          brand_id: 0,
          cat_id: 0,
          search: "",
          paginate_state: 1,
        },
        success: (res) => {
          let arr = [{ text: this.$t("选择抢购专区商品"), value: 0 }];
          for (let i = 0; i < res.data.list.length; i++) {
            let obj = { text: res.data.list[i].goods_name, value: res.data.list[i].id };
            arr.push(obj)

          }
          this.rushGoodList = arr;
        },
        tip: () => { },
      })
    },
    // 开始计算
    onCalculation() {
      this.$get({
        url: '/app/profit_cal/calProfit',
        data: {
          goods_id: this.brandId,
          amount: this.amount,
        },
        success: (res) => {
          this.show = true
          this.alculationResults = res.data
        },
        tip: () => { },
      })
    }
    // onSelect(item) {
    //   this.showShopChoice = false;
    //   this.selectShop = item.name;
    // },
  },
};
</script>

<style scoped lang='scss'>
.incomeCalculation {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 4vw 0;
  background-image: linear-gradient(#fbb152, #f45e1a);

  /deep/.van-ellipsis {
    overflow: hidden;
  }

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fbb152;
    }

    .van-icon {
      color: #fff;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #fff;
    }
  }

  .content {
    padding: 0 4vw;
    padding-top: 6.7vh;
    min-height: $conter2;
    height: auto;

    >div {
      padding: 0 4vw;
      margin-top: 6vw;

      //下拉菜单  start-----------
      /deep/.van-dropdown-menu__bar {
        margin-bottom: 4vw;
        border-radius: 2vw;
        width: 84vw;
        height: 13vw;
        background-color: rgba(255, 255, 255, 0.2);

        .van-dropdown-menu__title {
          margin-left: 2vw;
          color: #fff;
        }

        .van-dropdown-menu__item {
          display: flex;
          align-items: center;
          width: 84vw;
          justify-content: space-between;

          .van-dropdown-menu__title {
            width: 84vw;
          }

          .van-dropdown-menu__title::after {
            position: absolute;
            margin-top: -6px;
            border: 1vw solid;
            border-color: transparent transparent #ffffff #ffffff;
            right: 6vw;
          }
        }
      }

      /deep/.van-cell__title,
      .van-cell__value {
        display: flex;
        align-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        line-height: 4vw;
      }

      /deep/.van-cell--clickable .van-cell__title span {
        /*文字溢出隐藏显示省略号*/
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80vw;
      }

      /deep/.van-popup--top {
        top: 0;
        left: 8vw;
        width: 84vw;
        background-color: #fff;

        .van-cell {
          color: #000;
        }
      }

      //下拉菜单  end-------------

      /deep/.van-cell {
        display: flex;
        align-items: center;
        margin-bottom: 4vw;
        width: 84vw;
        height: 13vw;
        line-height: 0;
        font-size: 4vw;
        color: #fff;
        border-radius: 1vw;
        background-color: rgba(255, 255, 255, 0.2);

        .van-field__control {
          color: #fff;
        }

        input::-webkit-input-placeholder,
        -moz-input-placeholder,
        -o-input-placeholder {
          color: #fff !important;
        }
      }

      /deep/.van-button {
        width: 84vw;
        height: 13vw;
      }

      /deep/.van-button--normal {
        margin: 0vw auto 4vw;
        font-size: 4vw;
        border-radius: 1vw;
      }

      .resultBox {
        display: flex;
        margin-bottom: 4vw;
        width: 84vw;
        height: auto;
        border-radius: 2vw;
        background-color: rgba(255, 255, 255, 0.2);

        .resultInformation {
          display: flex;
          flex: 1;
          flex-direction: column;
          margin: 3vw 0 0 0;
          color: #fff;

          .resultInformation {
            >span {
              font-size: 3vw;
              color: #fff;
            }

            .revenueResults {
              margin-right: 3vw;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              height: 13vw;
              font-size: 4vw;
              border-top: 0.6vw solid #E8E8E8;
            }
          }

        }
      }
    }
  }
}
</style>