<template>
  <!-- 聊天窗口--联系客服 -->
  <div class="ChatWindow">
    <div class="header_box">
      <van-nav-bar :title="$t('联系客服')" left-arrow :border="false" @click-left="$router.go(-1)" />
    </div>

    <div class="content">
      <!-- <iframe :src="src" class="box" id="iframe"></iframe>  -->
       <iframe :src="src" frameborder="0" scrolling="no" style="width:100%;height:100%;"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      src: this.$route.query.src
      // src: this.httpPath + this.$route.query.url,
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.src)
  },
  mounted() {
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
.ChatWindow {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }

    .van-nav-bar__text {
      color: #666666;
    }
  }

  .content {
    padding-top: 6.7vh;
    min-height: $conter2;
    height: auto;

    >iframe {
      width: 100%;
      min-height: 92vh;
      height: auto;
      border: none;
    }
  }
}
</style>
