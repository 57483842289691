<template>
  <div class="VantCode">
    <van-button size="small" type="primary" v-if="is_time" @click="sendsms()"
      >发送验证码</van-button
    >
    <van-button size="small" type="primary" v-else class="no"
      >{{ scend }}S</van-button
    >
  </div>
</template>

<script>
export default {
  name: "VantCode",
  props: {
    url: String,
    scene: String,
  },
  data() {
    return {
      is_time: true,
      scend: 60,
    };
  },
  created() {
    console.log("加载验证码按钮组件，组件地址为vant-extend");
  },
  methods: {
    hClick() {
      alert("失败");
    },
    sendsms() {
      this.$post({
        url: this.url,
        data: { scene: this.scene },
        success: (res) => {
          this.$toast({
            message: "获取成功",
          });
          this.is_time = false;
          var timer = setInterval(() => {
            this.scend--;
            if (this.scend == 0) {
              clearInterval(timer);
              this.is_time = true;
              this.scend = 60;
            }
          }, 1000);
        },
        tip: () => {},
      });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
.VantCode {
  .van-button--small {
    background: transparent;
    color: $theme;
    border: none;
  }
}
</style>