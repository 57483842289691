<template>
  <!-- 资金明细 -->
  <div class="fundDetails">
    <div class="header_box">
      <van-nav-bar :title="$t('资金记录')" left-arrow @click-left="$router.replace('mine')" :border="false" />
      <div class="selectDate">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="monthList" @change="getChangesFundsData(value1)" />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="content">
      <!-- //封装的vant上啦加载下拉刷新。位置在vant-extend -->
      <vant-up-down ref="child" @list="(val) => (list = val)" res="res.data.list" url="/app/asset/getBalanceList"
        :data="{ month: now_time }">
        <div class="allList">
          <!-- <div class="date" v-show="monthArr[currentPage-1].indexOf(ele.month_time) == -1">{{ ele.month_time }}</div> -->

          <div v-for="(item, index) in list" :key="index">
            <!-- <div > {{ list[index-1].create_time.substr(0,7)}}</div> -->
            <!-- //如果 数组下标是0 站所 时间 -->
            <div class="year_class" v-if="index == 0">
              <div class="fontBold font4">
                {{ item.create_time.substr(0, 7) }}
              </div>
            </div>
            <!-- //如果 数组下标不是0 判断和上一个是否相同 不相同则显示 -->
            <div class="year_class" v-if="index > 1">
              <div class="fontBold font4" v-if="list[index].create_time.substr(0, 7) !=
        list[index - 1].create_time.substr(0, 7)
        ">
                {{ item.create_time.substr(0, 7) }}
              </div>
            </div>
            <div class="recordList">
              <div class="timeState">
                <p class="state font4">{{ item.desc }}</p>
                <p class="time grey">{{ item.create_time }}</p>
              </div>
              <div class="font5 fontBold" :class="item.value_types == 1 ? 'getNum' : 'cashOutNum'">
                {{ item.num }}
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>
    <!-- //上一页下一页 -->
    <!-- <div class="pagination">
      <van-pagination
        v-model="currentPage"
        :page-count="currentList.total_page"
        mode="simple"
        @change="onPage"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      value1: 0,
      monthList: [
        // { text: "全部", value: 0 },
        // { text: "2018-03", value: 1 },
        // { text: "2018-04", value: 2 },
      ],
      now_time: "",
      currentList: {
        balance: "",
        allList: [
          {
            month_time: "2018-03",
            list: [
              // {
              //   id: 1,
              //   state: "获得",
              //   time: "2021.02.03 17:00:02",
              //   num: "-30.00",
              // },
            ],
          },
        ],
        total_page: 0, //分页数
        total_rows: 0, //每一条数
      },
      list: null,
      monthArr: [["不能删"]],
      currentPage: 1,
      //页数叠加
      addPage: 1,
    };
  },
  created() { },
  mounted() {
    this.getMonthBetween();
    this.getChangesFundsData();
  },
  methods: {
    //------[获取3年间所有的月份中]
    getMonthBetween() {
      let currentList = [];
      let date = new Date();
      let curMonth = date.getMonth() + 1;
      let curYear = date.getFullYear();
      // 当前年份
      let curYearMonth = curYear + "-" + curMonth;
      let oldYear = curYear - 3;
      // 后3年份
      let oldYearMonth = oldYear + "-" + curMonth;

      var s = oldYearMonth.split("-");
      var e = curYearMonth.split("-");
      var min = new Date();
      var max = new Date();
      min.setFullYear(s[0], s[1] * 1 - 1, 1); //开始日期
      max.setFullYear(e[0], e[1] * 1 - 1, 1); //结束日期
      console.log(e[0] + "---- " + e[1]);
      var curr = min;
      while (curr <= max) {
        var month = curr.getMonth();
        var str = curr.getFullYear() + "-" + (month + 1);
        if (str == curYearMonth) {
          if (str.length < 7) {
            curYearMonth = curYearMonth.replace("-", "-0");
          }
          str = {
            text: curYearMonth,
            value: Number(curYearMonth.replace("-", "")),
          };
        } else {
          if (str.length < 7) {
            str = str.replace("-", "-0");
          }
          str = { text: str, value: Number(str.replace("-", "")) };
        }
        currentList.push(str);
        curr.setMonth(month + 1);
      }
      this.monthList = currentList.reverse();
      this.monthList.unshift({ text: this.$t("全部"), value: 0 });
      this.now_time = this.value1 = 0;
    },
    // 获取资金变动数据
    getChangesFundsData(e) {
      // this.$toast.loading({
      //   message: "加载中...",
      //   forbidClick: true,
      // });
      this.addPage = 1;
      let seleceYearMonth = "";
      if (!e || e == 0) {
        seleceYearMonth = "";
      } else {
        this.value1 = e;
        seleceYearMonth = String(e).substr(0, 4) + "-" + String(e).substr(4, 2);
        console.log("获取年月", seleceYearMonth);
      }

      this.now_time = seleceYearMonth;
      setTimeout(() => {
        this.$refs.child.list_init();
      }, 0);
      // let that = this;
      // this.$get({
      //   url: "/app/asset/getBalanceList",
      //   data: { page: this.currentPage, page_size: 10, month: seleceYearMonth },
      //   success: (res) => {
      //     this.$toast.clear();
      //     if (res.code == 1) {
      //       that.currentList.balance = res.data.balance;
      //       that.currentList.total_page = res.data.total_page;
      //       that.currentList.total_rows = res.data.total_rows;
      //       // 建立一个id数据，用与判断是否多余的存储
      //       let idArr = [];
      //       // 建立一个一级分类月份，加强过滤性
      //       let monthArr = [];
      //       let allList = [
      //         {
      //           // id: "",
      //           month_time: "", //月份
      //           // 对应每月的全部数据列表
      //           list: [],
      //         },
      //       ];
      //       idArr[0] = res.data.list[0].id;
      //       monthArr[0] = res.data.list[0].month_time;

      //       allList[0].month_time = res.data.list[0].month_time;
      //       allList[0].list.push(res.data.list[0]);

      //       let obj = {
      //         // id:"",
      //         month_time: "",
      //         list: [],
      //       };
      //       for (let i = 0; i < allList.length; i++) {
      //         for (let j = 1; j < res.data.list.length; j++) {
      //           if (idArr.indexOf(res.data.list[j].id) == -1) {

      //             if ( allList[i].month_time == res.data.list[j].month_time) {
      //               idArr.push(res.data.list[j].id);

      //               allList[i].list[allList[i].list.length] = res.data.list[j];
      //               allList;
      //             } else {
      //               if (monthArr.indexOf(res.data.list[j].month_time) !== -1) {
      //                 // 已存在该月份
      //                 idArr.push(res.data.list[j].id);
      //                 //放到已存在的月份数组里
      //                 allList[allList.length-1].list[  allList[allList.length-1].list.length  ] = res.data.list[j];
      //                 allList;
      //               } else{
      //                 // obj.id = res.data.list[j].id;
      //                 idArr.push(res.data.list[j].id);
      //                 monthArr.push(res.data.list[j].month_time);

      //                 obj.month_time = res.data.list[j].month_time;
      //                 obj.list =[];
      //                 obj.list.push(res.data.list[j]);
      //                 allList[allList.length] = JSON.parse(JSON.stringify(obj));
      //                 allList;
      //               }

      //             }
      //           }
      //         }
      //       }
      //       if(that.addPage <= that.currentPage ) {
      //         that.monthArr[this.addPage] = monthArr;
      //         that.addPage = that.addPage + 1;
      //       }
      //       that.currentList.allList = allList;
      //     }
      //   },
      //   tip: () => {},
      // });
    },
    // onPage(e) {
    //   this.currentPage = e;
    //   this.getChangesFundsData(this.value1);
    // },
  },
};
</script>

<style scoped lang='scss'>
.year_class {
  padding: 0 5vw;
}

.font4 {
  font-size: 4vw;
}

.font5 {
  font-size: 5vw;
}

.fontBold {
  font-weight: bold;
}

.grey {
  color: #999;
}

.fundDetails {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 4vw 0;
  padding-top: calc(6.3vh + 6.5vh);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: 6.3vh;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  .selectDate {
    width: 100vw;
    height: 6.5vh;
    background-color: #f5f5f5;

    /deep/.van-dropdown-menu__bar {
      background-color: #f5f5f5;
      height: 6.5vh;
      box-shadow: none;

      .van-dropdown-menu__title {
        font-size: 4vw;
        color: #333333;

        &::after {
          border-color: transparent transparent #333333 #333333;
        }
      }
    }
  }

  // 内容
  .content {
    min-height: calc(100vh - 6.3vh + 6.5vh);
    height: auto;

    .allList {
      min-height: calc(100vh - 6.3vh + 6.5vh);
      padding: 4vw 0vw 0;

      .date {
        margin: 0 4vw;
        font-weight: bold;
      }

      .recordList {
        padding: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;

        .cashOutNum {
          color: #14ae95;
        }

        .getNum {
          color: #f13b10;
        }
      }
    }
  }

  .pagination {
    height: 5.4vh;

    /deep/.van-pagination__item,
    .van-pagination__page-desc {
      height: 5.4vh;
    }
  }
}
</style>