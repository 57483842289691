<template>
  <!-- 公告详情 -->
  <div class="noticeDetails">
    <div class="header_box">
      <van-nav-bar :title="$t('公告详情')" left-arrow @click-left="$router.replace('noticeList')" :border="false" />
    </div>

    <div class="detailsInformation">
      <h2>{{ detail.title }}</h2>
      <div class="date">{{ detail.create_time }}</div>
      <div class="span" v-html="detail.content"></div>
      <!-- <img
        src="../../../assets/img/index_shopping_area.png"
        alt=""
        style="
          width: 92vw;
          height: 43vw;
          border-radius: 2vw;
          overflow: hidden;
          margintop: 1vw;
        "
      /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'noticeDetails',
  props: {},
  data() {
    return {
      id: this.xz_id(),
      detail: {},
    }
  },
  created() {
    this.get_noticeDetail()
  },
  methods: {
    get_noticeDetail() {
      this.$get({
        url: '/app/announcement/getAnnouncementDetail',
        data: { id: this.id },
        success: (res) => {
          console.log(res)
          this.detail = res.data
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.noticeDetails {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
    }
  }

  .detailsInformation {
    padding: 4vw;
    display: flex;
    flex-direction: column;
    min-height: $conter2;
    height: auto;

    .date {
      margin-top: 4vw;
      font-size: 3vw;
      color: #999999;
    }

    .span {
      margin-top: 4vw;
      font-size: 4vw;
      font-weight: 500;
      color: #333;
      text-indent: 2em;
    }
  }
}
</style>
