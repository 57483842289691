<template>

  <!-- 设置 -->
  <div class="mySet">
    <div class="header_box">
      <van-nav-bar title="选择语言" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>
    <div class="content">
      <div class="navbox">
        <div @click="opt('zh')" class="navs">
          <p>{{ $t('简体中文') }}</p>
          <van-icon name="arrow" color="#000" size="18" />
        </div>
        <div @click="opt('jp')" class="navs">
          <p>{{ $t('日语') }}</p>
          <van-icon name="arrow" color="#000" size="18" />
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      lang_obj: { zh: "1", jp: "2" },
      radio: "1",
    }
  },
  created() {
    this.radio = this.lang_obj[this.getLang()]
  },
  methods: {
    opt(lang) {
      this.radio = this.lang_obj[lang]
      this.changeLang(lang)
      this.goBack()
    },
  },
}
</script>
<style lang='scss' scoped>
.mySet {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .navbox {
      width: 100%;
      padding: 2vw 0vw;

      .navs {
        width: 100%;
        height: 14vw;
        padding: 3vw 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;

        p {
          flex: 1;
          padding-left: 2vw;
          color: #000000;
          font-size: 4vw;
        }

        // &:last-child {
        //   border-bottom: none;
        // }
      }
    }
  }
}
</style>
