import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import { Locale } from 'vant'

import zhCN from 'vant/lib/locale/lang/zh-CN';
import enUS from 'vant/lib/locale/lang/en-US'
import jaJP from 'vant/lib/locale/lang/ja-JP';

import zhLocale from './zh'
import enLocale from './en'
import jpLocale from './jp'

const messages = {
  zh: { ...zhCN, ...zhLocale },
  en: { ...enUS, ...enLocale },
  jp: { ...jaJP, ...jpLocale }
}
// https://kazupon.github.io/vue-i18n/zh/api/#%E6%9E%84%E9%80%A0%E5%87%BD%E6%95%B0%E9%80%89%E9%A1%B9
const i18n = new VueI18n({
  locale: localStorage.getItem(`mingpaigoushangchengus_lang`) || 'en',//默认中文 l('lang') || 'zh',
  messages,
  silentTranslationWarn: false
})
// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  localStorage.setItem(`mingpaigoushangchengus_lang`, lang)
  if (lang == 'en') {
    Locale.use(lang, enUS)
  } else if (lang == 'jp') {
    Locale.use(lang, jaJP)
  } else {
    Locale.use(lang, zhCN)
  }
}

export { i18n, vantLocales }