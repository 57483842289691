<template>
  <!-- 退货或发布评价 -->
  <div class="returnOrEvaluation">
    <div class="header_box">
      <van-nav-bar :title="title" left-arrow @click-left="$router.go(-1)" :border="false" />
    </div>
    <div class="content">
      <van-field v-model="content" rows="5" maxlength="200" show-word-limit autosize type="textarea" :placeholder="title == $t('评价') ? $t('此时此地，想和大家分享什么') : $t('请输入退货理由')
        " />
      <van-uploader :before-delete="del" v-model="fileList" multiple :after-read="upImgFun" style="margin: 0 4vw" />
      <div class="btn">
        <van-button type="primary" @click="save()" color="#F13B10">{{ $t('提交') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      title: "",
      order_id: "",
      content: "",
      fileList: [],
      headimgurl: [],
    };
  },
  created() { },
  mounted() {
    this.title = this.$route.query.title;
    this.order_id = this.$route.query.id;
  },
  methods: {
    // 上传图片
    upImgFun(file) {

      // 判断是不是数组
      let is_arr = Array.isArray(file);
      //不是数组走单个上传
      if (!is_arr) {
        var that = this;
        var formData = new FormData();
        formData.append("image", file.file);

        this.$post({
          url: "/app/tool/uploadImage",
          data: formData,
          upload: true,
          success: (res) => {
            this.headimgurl.push(res.data);
            console.log(this.headimgurl);
          },
          tip: (val) => { },
        });
      } else {
        //是数组 遍历后 执行多次上传
        var that = this;
        file.forEach((item, index) => {
          console.log(index);
          var formData = new FormData();
          formData.append("image", file[index].file);

          that.$post({
            url: "/app/tool/uploadImage",
            data: formData,
            upload: true,
            success: (res) => {
              that.headimgurl.push(res.data);
              console.log(that.headimgurl);
            },
            tip: (val) => { },
          });
        });
      }
    },

    del(file, detail) {
      console.log(detail);
      this.fileList.splice(detail.index, 1);
      this.headimgurl.splice(detail.index, 1);
    },
    save() {
      if (this.headimgurl.length > 3)
        return this.$toast(this.$t("上传图片不能超过三张"));
      let url = "";
      if (this.title == this.$t("评价")) {
        url = "/app/order_goods/evaluateOrder";
      } else if (this.title == this.$t("申请退货")) {
        url = "/app/order_goods/refundApply";
      }
      this.$post({
        url: url,
        data: {
          order_id: this.order_id,
          content: this.content,
          imgs: this.headimgurl.length == 0 ? "" : this.headimgurl,
        },
        success: (res) => {
          console.log(res);
          if (this.title == this.$t("评价")) {
            this.$toast(this.$t("评价完成"));
            setTimeout(() => {
              this.xz_back()
            }, 500);
          }
          if (this.title == this.$t("申请退货")) {
            this.$toast(this.$t("提交申请成功"));
            setTimeout(() => {
              this.xz_back()
              // this.$router.replace({
              //   name: "orderDetails",
              //   query: { state: 5, id: this.order_id },
              // });
            }, 500);
          }
        },
        tip: (val) => { },
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.returnOrEvaluation {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;
    padding: 6vw 0 0 0;
    height: $conter2;
    overflow-y: auto;

    .date {
      margin-top: 4vw;
      font-size: 3vw;
      color: #999999;
    }

    >span {
      margin-top: 4vw;
      font-size: 4vw;
      color: #333;
      text-indent: 2em;
    }

    /deep/.van-cell {
      .van-field__label,
      .van-cell__value {
        font-size: 4vw;
        font-weight: bold;
      }

      .van-cell__value {
        justify-content: flex-end;
      }
    }

    /deep/.van-cell::after {
      border: 0;
    }

    /deep/.van-uploader__preview {
      .van-uploader__preview-image {
        // overflow: visible;
      }

      .van-uploader__preview-delete {
        // top: -2.2vw;
        // right:-2.2vw;
        // width: 5vw;
        // height: 5vw;
        background-color: #f13b10;
        // border-radius: 5vw;
      }
    }

    .btn {
      margin: 11vh auto 0;
      width: 79vw;

      /deep/.van-button {
        width: 79vw;
        height: 15vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        padding: 0;
        font-size: 4vw;
      }
    }
  }
}
</style>