<template>
  <!-- 订单详情 -->
  <div class="orderDetails greyBc">
    <div class="header_box">
      <van-nav-bar :title="$t('订单详情')" left-arrow @click-left="$router.go(-1)" :border="false"
        style="background-color: #f13b10" />
      <div class="orderState">
        <div class="state">
          <div class="white font4 fontBold">
            {{
              state == -1
                ? $t("待支付")
                : state == 1
                  ? $t("已支付")
                  : state == 2
                    ? $t("已发货")
                    : state == 3
                      ? $t("已收货")
                      : state == 4
                        ? $t("已完成")
                        : state == 5
                          ? $t("退货申请中")
                          : state == 6
                            ? $t("退货中待发货")
                            : state == 7
                              ? $t("退货中已发货")
                              : state == 8
                                ? $t("退款成功")
                                : state == 9
                                  ? $t("订单取消")
                                  : state == 10
                                    ? $t("转卖中")
                                    : state == 11
                                      ? $t("转卖完成")
                                      : "" }}
          </div>
          <div class="white time" v-if="state == -1">
            {{ $t('倒计时') }}：
            <van-count-down @finish="finish" :time="(order_info.pay_expire_time * 1000) | get_time" />
          </div>
          <!-- <div class="white" style="margin-top: 2vw">订单已完成</div> -->
        </div>
        <img v-if="state == -1" src="../../../assets/img/orderDetails_return_goods_icon.png" alt=""
          style="width: 9vw; height: 9vw" />

        <img v-if="state == 1" src="../../../assets/img/orderDetails_toBeShipped_icon.png" alt=""
          style="width: 9vw; height: 9vw" />
        <img v-if="state == 2 || state == 10" src="../../../assets/img/orderDetails_unclaimed_icon.png" alt=""
          style="width: 9vw; height: 9vw" />
        <img v-if="state == 3" src="../../../assets/img/orderDetails_ToBeEvaluated_icon.png" alt=""
          style="width: 9vw; height: 9vw" />
        <img v-if="state == 4 || state == 8 || state == 9 || state == 11"
          src="../../../assets/img/orderDetails_completed_icon.png" alt="" style="width: 9vw; height: 9vw" />
        <img v-if="state == 5 || state == 6 || state == 7" src="../../../assets/img/orderDetails_return_goods_icon.png"
          alt="" style="width: 9vw; height: 9vw" />
      </div>
    </div>

    <div class="content">
      <div class="personalInformation whiteBc padding04">
        <img src="../../../assets/img/orderDateils_tel_address_icon.png" alt=""
          style="width: 7vw; height: 7vw; margin-right: 4vw" />
        <div v-if="!address_info.name" class="contact tel font4 fontBold" @click="
          $router.push({ name: 'myAddress', query: { code, order_id } })
          ">
          {{ $t('请选择收货地址') }}
        </div>
        <div class="contact" v-else>
          <div class="tel font4 fontBold">
            {{ address_info.name + " " + mask_code(address_info.phone) }}
          </div>
          <div class="address">{{ address_info.area_name.split(' ')[0] +
            "********" }}</div>
        </div>
      </div>
      <div class="productInformation whiteBc">
        <div v-for="(ele, index) in goods_list" :key="index">
          <div class="imgTite">
            <img :src="ele.goods_thumb" alt="" style="
                width: 23vw;
                height: 23vw;
                border-radius: 2vw;
                overflow: hidden;
                margin-right: 3vw;
                object-fit: cover;
              " />
            <div class="tite">
              <h3>{{ ele.goods_name }}</h3>
              <div class="priceNum">
                <div class="orange fontBold font4">
                  {{ $t('元') }}{{ ele.discount_price }}
                </div>
                <div class="num grey">X{{ ele.amount }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="altogether font4" style="font-size: 3.5vw; color: #999">
          {{ $t('订单总数') }}：{{ order_info.order_total_num }} ,{{ $t('总金额') }}：
          <span style="color: #f13710; font-weight: 800">
            {{ $t('元') }}{{ order_info.pay_price }}
          </span>
        </div>
      </div>
      <div class="orderInformation marginTop2 whiteBc">
        <div class="font4 fontBold">{{ $t('订单详情') }}</div>
        <div class="orderNum marginTop2">
          <div>{{ $t('订单号') }}：{{ order_info.sn }}</div>
          <div class="btnCody" v-clipboard:copy="order_info.sn" v-clipboard:success="onCopy"
            v-clipboard:error="onError">
            {{ $t('复制') }}
          </div>
        </div>
        <div class="paymentTime marginTop2">
          {{ $t('创建时间') }}：{{ order_info.create_time }}
        </div>
      </div>
      <div class="btn marginTop2 padding04 whiteBc">
        <van-button type="primary" color="#FFB100" round v-if="state == 2" @click="
          $router.push({
            name: 'returnOrEvaluation',
            query: { title: $t('申请退货'), id: order_id },
          })
          ">{{ $t('申请退货') }}</van-button>
        <van-button type="primary" color="#FFB100" round v-if="state == 6"
          @click="$router.push({ name: 'upOrderSn', query: { id: order_id } })">{{ $t('上传单号') }}</van-button>
        <van-button type="primary" color="#F13B10" round v-if="state == 2" @click="onTakeDelivery(order_id)">{{
          $t('确认收货') }}</van-button>
        <van-button type="primary" color="#F13B10" round v-if="state == 3" @click="
          $router.push({
            name: 'returnOrEvaluation',
            query: { title: $t('评价'), id: order_id },
          })
          ">
          {{ $t('立即评价') }}
        </van-button>
        <van-button type="primary" color="#F13B10" v-if="state == -1" @click="passshow = true" round>
          {{ $t('立即支付') }}
        </van-button>
      </div>
    </div>

    <!-- 输入密码弹窗 -->
    <van-dialog v-model="passshow" :title="$t('交易密码')" show-cancel-button :before-close="onConfirmBtn">
      <van-cell-group>
        <van-field v-model="transactionPw" :label="$t('交易密码')" :placeholder="$t('请输入交易密码')" type="password"
          :border="false" clearable maxlength="6" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      code: -5,
      state: "",
      order_id: "",
      address_info: {},
      goods_list: {},
      order_info: {},
      transactionPw: "",
      passshow: false,
    };
  },
  filters: {
    get_time(times) {
      var date = new Date(); //当前时间
      var time = date.getTime();
      var dates = new Date(times); //进行时间
      var timea = dates.getTime();
      let sj = timea - time;
      return sj;
    },
  },
  created() { },
  mounted() {
    this.state = this.$route.query.state;
    this.order_id = this.$route.query.id;
    this.getOrderDetails(this.order_id);
  },
  methods: {
    finish() {
      console.log("倒计时结束");
      this.xz_back();
      // this.getOrderDetails(this.order_id)
    },
    // 获取订单详情
    getOrderDetails(id) {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/order_goods/detail",
        data: { order_id: id },
        success: (res) => {
          this.$toast.clear();
          this.state = res.data.order_info.state;
          this.address_info = res.data.address_info;
          this.goods_list = res.data.goods_list;
          this.order_info = res.data.order_info;
        },
        tip: () => { },
      });
    },
    // 确认收货
    onTakeDelivery(id) {
      this.$post({
        url: "/app/order_goods/confirmOrder",
        data: { order_id: id },
        success: (res) => {
          this.$toast.clear();
          this.$toast(this.$t("收货成功"));
          setTimeout(() => {
            this.$router.push({ name: "order" });
          }, 500);
        },
        tip: () => { },
      });
    },
    // 支付密码确认
    onConfirmBtn(action, done) {
      if (action == "confirm") {
        if (!this.address_info.name) {
          this.$toast("请先选择收货地址");
          done(false);
          return;
        }
        if (!this.transactionPw) {
          this.$toast("请输入交易密码");
          done(false);
          return;
        }
        this.$post({
          url: "/app/order_goods/pay",
          data: {
            order_id: this.order_id,
            password: this.transactionPw,
          },
          success: (res) => {
            this.$toast(this.$t("支付成功"));
            setTimeout(() => {
              this.$router.push({ name: "order" });
            }, 500);
            done();
          },
          tip: (err) => {
            if (err.code == -5) {
              done();
              this.code = -5;
            } else {
              done(false);
            }
          },
        });
      } else {
        done();
      }
    },
    // 复制成功
    onCopy() {
      this.$toast(this.$t("复制成功"));
    },
    // 复制失败
    onError() {
      this.$toast(this.$t("复制失败"));
    },
  },
};
</script>

<style scoped lang='scss'>
.grey {
  color: #999;
}

.greyBc {
  background-color: #f9f9f9;
}

.orange {
  color: #f13710;
}

.white {
  color: #fff;
}

.whiteBc {
  background-color: #fff;
}

.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.marginTop2 {
  margin-top: 2vw;
}

.padding04 {
  padding: 0 4vw;
}

// 支付密码弹窗
/deep/.van-dialog {
  top: 28vh
}

.orderDetails {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: calc(6.3vh + 13.5vh);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #fff;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #fff;
    }
  }

  .orderState {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4vw;
    height: 13.5vh;
    background-color: #f13b10;

    .state {
      display: flex;
      flex-direction: column;
      justify-content: centers;
    }

    .time {
      padding: 1vw 0;
      display: flex;
      // justify-content: flex-end;
      // align-items: flex-end;
      font-size: 3.2vw;

      /deep/.van-count-down {
        color: #fff;
        font-size: 4vw;
        line-height: 20px;
      }
    }
  }

  .content {
    min-height: calc(100vh - 6.3vh - 13.5vh);
    height: auto;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .personalInformation {
      display: flex;
      align-items: center;
      margin-bottom: 3vw;

      .contact {
        flex: 1;
        padding: 4vw 0;
      }
    }

    .productInformation {
      padding: 0 4vw;

      .imgTite {
        display: flex;
        justify-content: space-between;
        padding: 4vw 0;
        border-bottom: 1px solid #f5f5f5;

        .tite {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 66vw;

          .priceNum {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .altogether {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 13vw;
      }
    }

    .orderInformation {
      padding: 2vw 4vw;

      .orderNum {
        display: flex;
        justify-content: space-between;

        .btnCody {
          padding: 0 1vw;
          border-radius: 1vw;
          color: #fff;
          background-color: #f13710;
        }
      }
    }

    .btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 16vw;

      /deep/.van-button {
        margin-left: 4vw;
        width: 23vw;
        height: 8vw;
      }

      /deep/.van-button--normal {
        padding: 0;
      }

      .logisticsBtn {
        color: #999999 !important;
        background-color: transparent;
        border: 1px solid #999999;
      }
    }
  }
}
</style>