import { render, staticRenderFns } from "./membershipBenefits.vue?vue&type=template&id=8aa2f0a0&scoped=true"
import script from "./membershipBenefits.vue?vue&type=script&lang=js"
export * from "./membershipBenefits.vue?vue&type=script&lang=js"
import style0 from "./membershipBenefits.vue?vue&type=style&index=0&id=8aa2f0a0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8aa2f0a0",
  null
  
)

export default component.exports