import router from '../router/index';
// 国际化
import { i18n, vantLocales } from '../utils/i18n'
import { Toast, Lazyload, ImagePreview } from 'vant'

//小政提取的公共方法
export default {
  install(Vue, options) {
    vantLocales(i18n.locale)
    // 国际化 获取语言
    Vue.prototype.getLang = function () {
      return i18n.locale
    }
    // 国际化 切换语言
    Vue.prototype.changeLang = function (lang, reload = false) {
      i18n.locale = lang
      vantLocales(lang)
      reload && window.location.reload()
    }
    //push 是打开一个页面
    Vue.prototype.xz_go = function (name, id) {
      router.push({ path: name, query: { id: id } });
      console.log("桥通跳转路由")
    }
    Vue.prototype.xz_id = function () {
      console.log("桥通获取路由参数")
      return router.app._route.query.id;
    }
    //如何拿去参数
    Vue.prototype.xz_goto = function (name, item) {
      router.push({ path: name, query: { item: JSON.stringify(item) } });
      console.log("桥通跳转路由JOSN格式")
    }
    Vue.prototype.xz_item = function () {
      console.log("桥通获取JOSN格式数据")
      return JSON.parse(router.app._route.query.item);
    }
    //replace 是关闭所有页面 。打开底部栏的页面 用。
    Vue.prototype.xz_goin = function (name) {
      router.replace({ path: name });
      console.log("桥通关闭页面跳转路由")
    }
    //2.返回上一页
    Vue.prototype.xz_back = function () {
      //router.go(-1) 返回上一页
      router.go(-1);
      console.log("桥通返回上一页")
    }
    // 3.本地存储封装
    Vue.prototype.xz_save = {
      set(key, value) {
        localStorage.setItem(key, value);
        console.log("桥通保存到本地操作");
      },
      get(key) {
        console.log("桥通获取本地数据");
        return localStorage.getItem(key);
      },
      del(key) {
        console.log("桥通删除本地数据");
        localStorage.removeItem(key);
      }
    }
    Vue.prototype.xz_say = function (val) {
      //页面滚动到顶部  这个是黑色的小提示
      console.log('说话了');
      Vue.prototype.$toast({ message: val, val, forbidClick: true });

    }
    Vue.prototype.xz_top = function () {
      //页面滚动到顶部
      console.log('回到顶部');
      window.scrollTo(0, 0);
    }

    // 国际化 获取语言
    Vue.prototype.getLang = function () {
      return i18n.locale
    }

    // 国际化 切换语言
    Vue.prototype.changeLang = function (lang, reload = false) {
      i18n.locale = lang
      vantLocales(lang)
      reload && window.location.reload()
    }

    //返回上一页
    Vue.prototype.goBack = function () {
      window.scrollTo(0, 0);
      this.$router.isBack = true;
      router.go(-1);
    }

    // 图片预览
    Vue.prototype.lookImg = function (images, index = 0,) {
      ImagePreview({
        images: [images],
        startPosition: index,
        closeable: true,
      });
    }

    Vue.prototype.mask_code = function (value, type = 0) {

      if (type == 0) {
        return value?.slice(0, 3) + '****' + value?.slice(7);
      } else if (type == 1) {
        return value?.slice(0, 4) + '****' + value?.slice(15);
      } else if (type == 2) {
        return value?.slice(0, 3) + '****';
      }
    }

    Vue.prototype.qt_tel = function (ph) {
      window.location.href = "tel://" + ph;
    },

      // 注册一个全局自定义指令 `v-focus`
      Vue.directive('focus', {
        // 当被绑定的元素插入到 DOM 中时……
        inserted: function (el) {
          // 聚焦元素
          el.focus() // 页面加载完成之后自动让输入框获取到焦点的小功能
        }
      })

  },

}