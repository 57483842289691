<template>
  <!-- 转卖区 -->
  <div class="resell">
    <div class="header_box">
      <van-nav-bar :title="$t('转卖区')" :right-text="$t('转卖记录')" @click-right="$router.push('resaleCenter')"
        :border="false" />
      <div class="notice">
        <van-notice-bar :scrollable="false" background="#FFE600">
          <template #left-icon>
            <img src="../../assets/img/rushToPurchase_notice_icon.png"
              style="margin-right: 3vw; width: 5vw; object-fit: cover" alt="" />
            <!-- <span class="noticeTite">公告</span> -->
          </template>
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in ResellBanner" :key="index">{{ item.content }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
    </div>

    <div class="content">
      <img src="../../assets/img/resell_bc.png" alt="" style="
          width: 92vw;
          height: 50vw;
          border-radius: 2vw;
          overflow: hidden;
          margin-top: 4vw;
        " />
      <div>
        <van-field v-model="num" :label="$t('订单编号')" type="text" :placeholder="$t('请输入订单编号')" clearable :border="false"
          @focus="onFalse" @blur="onTrue">
          <template #button>
            <van-button size="small" type="primary" @click="paste" color="#F13B10" style="marginRight: 2vw">
              {{ $t('粘贴') }}
            </van-button>
            <van-button size="small" type="primary" :disabled="!num" @click="get_info" color="#F13B10">
              {{ $t('确认') }}
            </van-button>
          </template>
        </van-field>
        <div class="resultBox" v-if="show">
          <div class="resultInformation">
            <div class="resullPeople">{{ $t('姓名') }}：{{ mask_code(info.user_name) }}</div>
            <div class="price">
              <span class="purchase">{{ $t('购买价格') }}：{{ $t('元') }}{{ info.order_price }}</span>
              <span>{{ $t('转卖价格') }}：{{ $t('元') }}{{ info.transfer_price }}</span>
            </div>
            <div class="profit">{{ $t('利润') }}：{{ $t('元') }}{{ info.transfer_profit }}</div>
          </div>
        </div>
        <div v-if="show">
          <van-button type="primary" color="#F13B10" block @click="passshow = true">
            {{ $t('确认转卖') }}
          </van-button>
          <van-button type="primary" color="#F06F48" block @click="profit">
            {{ $t('查看其他等级转卖利润') }}
          </van-button>
        </div>
      </div>
    </div>
    <!-- 提示弹窗 -->
    <van-popup v-model="popupShow" closeable close-icon="close" position="bottom" :style="{ height: '40%' }">
      <div class="popupBox">
        <div class="tite">{{ errDate.title }}</div>
        <div class="popupContent">
          <div v-html="errDate.content"></div>
        </div>
        <van-goods-action>
          <van-goods-action-button color="#F13B10" type="danger" :text="$t('确认')" @click="popupShow = false" />
        </van-goods-action>
      </div>
    </van-popup>
    <!-- 支付密码弹窗 -->
    <van-dialog v-model="passshow" :title="$t('交易密码')" show-cancel-button :before-close="resell">
      <van-cell-group>
        <van-field v-model="transactionPassword" :label="$t('交易密码')" type="password" :placeholder="$t('请输入交易密码')"
          :border="false" clearable maxlength="6" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import is_tabbar from "../../mixin/is_tabbar"
export default {
  mixins: [is_tabbar],
  data() {
    return {
      num: "",
      transactionPassword: "",
      ResellBanner: [], //公告轮播
      info: "",
      is_bank: 0,
      show: false,
      passshow: false,
      // 弹窗
      popupShow: false,
      errDate: {},
    }
  },
  created() {
    this.get_resell()
    // this.get_bank()
  },
  methods: {
    onFalse() {
      console.log("激活")
    },
    onTrue() {
      console.log("不激活")
    },
    //公告轮播
    get_resell() {
      this.$get({
        url: "/app/message/list",
        data: { type: 2 },
        success: (res) => {
          this.ResellBanner = res.data
        },
      })
    },
    //获得转卖信息
    get_info() {
      // if (!this.num) return this.$toast("请输入查询的单号")
      if (this.num) {
        this.$get({
          url: "/app/order_transfer/getTransferinfo",
          data: {
            order_sn: this.num,
          },
          success: (res) => {
            this.info = res.data
            // this.$toast({
            //   message: res.msg,
            // })
            this.show = true
          },
          tip: (val) => { },
        })
      }
    },
    //获取是否绑定银行卡
    get_bank() {
      this.$get({
        url: "/app/user_wallet/isBindWallet",
        success: (res) => {
          console.log(res)
          this.is_bank = res.data.bank
        },
      })
    },
    //确认转卖
    resell(action, done) {
      if (action == "confirm") {
        if (!this.transactionPassword) {
          this.$toast(this.$t("请输入交易密码"))
          done(false)
          return
        }
        this.$post({
          url: "/app/order_transfer/transferOrder",
          data: {
            order_sn: this.num,
            password: this.transactionPassword,
          },
          success: (res) => {
            this.$toast({
              message: res.msg,
            })
            setTimeout(() => {
              this.$router.push("resaleCenter")
            }, 500)
            done()
          },
          tip: (val) => {
            if (val.code == -4) {
              this.popupShow = true
              this.errDate = val.data
              done()
            } else {
              done(false)
            }
          },
        })
      } else {
        done()
      }
    },
    profit() {
      if (!this.num) {
        return this.$toast(this.$t("请先输入订单编号"))
      } else {
        this.$router.push({
          name: "Profit",
          params: {
            order_sn: this.num,
          },
        })
      }
    },
    //粘贴
    paste() {
      this.num = window.sessionStorage.getItem("order_sn")
    },
  },
}
</script>
<style scoped lang="scss">
.resell {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: calc(6.3vh + 40px);
  background-image: linear-gradient(#f45e1a, #fbb152);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #f45e1a;
    }

    .van-icon {
      color: #fff;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #fff;
    }

    .van-nav-bar__text {
      color: #fff;
    }
  }

  // 公告
  .notice {

    //margin-top: 3.47vw;
    .notice-swipe {
      height: 13.33vw;
      line-height: 13.33vw;
    }

    /deep/.van-notice-bar {
      font-size: 3vw;
      color: #000000;
    }

    .noticeTite {
      margin: 0 2.67vw;
    }
  }

  // 内容
  .content {
    padding: 0vw 4vw 8vh;
    min-height: calc(100vh - 6.3vh - 40px);
    height: auto;

    >div {
      padding: 0 4vw;
      margin-top: 3vw;

      /deep/.van-cell {
        display: block;
        align-items: center;
        margin-bottom: 3vw;
        width: 84vw;
        // height: 13vw;
        line-height: 8vw;
        font-size: 4vw;
        color: #fff;
        border-radius: 1vw;
        background-color: rgba(255, 255, 255, 0.2);

        .van-field__control {
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .van-field__label {
          color: #fff;
          width: auto;
        }

        .van-icon-search:before {
          color: #fff;
        }

        input::-webkit-input-placeholder,
        -moz-input-placeholder,
        -o-input-placeholder {
          color: #fff !important;
        }
      }

      // /deep/.van-button {
      //   width: 84vw;
      //   height: 13vw;
      // }

      /deep/.van-button--normal {
        margin: 0vw auto 4vw;
        font-size: 4vw;
        border-radius: 1vw;
      }

      .resultBox {
        display: flex;
        margin-bottom: 3vw;
        width: 84vw;
        border-radius: 2vw;
        background-color: rgba(255, 255, 255, 0.2);

        .resultInformation {
          display: flex;
          flex-direction: column;
          padding: 3vw 4vw;
          color: #fff;
          width: 100%;

          .resullPeople {
            font-size: 4vw;
            font-weight: bold;
          }

          .price {
            display: flex;
            justify-content: space-between;
            margin-top: 1vw;
            width: 100%;

            >span {
              font-weight: bold;
            }

            .purchase {
              //margin-right: 15vw;
            }
          }

          .profit {
            margin-top: 1vw;
            font-weight: bold;
          }
        }
      }
    }
  }

  // 提示弹窗
  .popupBox {
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tite {
      margin-top: 5vw;
      font-size: 4vw;
      font-weight: bold;
    }

    .popupContent {
      height: calc(40vh - 10vw - 50px);
      overflow: auto;
    }
  }

  // 支付密码弹窗
  /deep/.van-dialog {
    top: 37vh;
  }

  /deep/.van-goods-action {
    width: 100%;
  }
}
</style>
