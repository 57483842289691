<template>
  <!-- 推广名片 -->
  <div class="extension">
    <div class="header_box">
      <van-nav-bar :title="$t('推广名片')" left-arrow @click-left="$router.replace('mine')" :border="false" />
    </div>
    <div class="content">
      <div class="erweima">
        <div class="bot">
          <p>{{ $t('推广享优惠') }}</p>

          <!-- <img src="" alt="" /> -->
          <div class="img">
            <div id="qrcode" ref="qrCode"></div>
          </div>
        </div>
      </div>
      <div class="btn">
        <van-button type="primary" color="#F13B10" v-clipboard:copy="webSiteInfo.download_url"
          v-clipboard:success="onCopy" v-clipboard:error="onError">
          {{ $t('复制链接') }}
        </van-button>
        <!-- <van-button type="primary" color="#14AE95">分享</van-button> -->
      </div>
      <!-- <div class="rule">
          <h3>推广收益与规则</h3>
          <p>
              高效盈利 满24小时可申请结算,收益快速到达 持久变现 充分利用剩余流量 广告形式按需选择 文案派单 广告主自主创作广告文案,以图文消息的方式发布推广信息,通过精美个性的文案锁定兴趣用户及潜在…
          </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  props: {},
  data() {
    return {
      webSiteInfo: {
        download_url: "", //根据此链接生成二维码
      },
    };
  },
  created() {
    this.getWebSiteInfo();
  },
  methods: {
    bindQRCode: function (url) {
      new QRCode(this.$refs.qrCode, {
        text: url,
        width: 150,
        height: 150,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 获取站点信息
    getWebSiteInfo() {
      this.$toast.loading({
        message: this.$t("加载中..."),
        forbidClick: true,
      });
      this.$get({
        url: "/app/extend/getWebSiteInfo",
        success: (res) => {
          this.$toast.clear();
          this.webSiteInfo = res.data;
          this.$nextTick(() => {
            this.bindQRCode(res.data.download_url);
          });
        },
        tip: () => { },
      });
    },
    onCopy: function () {
      this.$toast({
        message: this.$t("复制成功"),
        duration: 400,
      });
    },
    onError: function () {
      this.$toast({
        message: this.$t("复制失败"),
        duration: 400,
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.extension {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: $navHeight 0 0 0;

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  // 内容
  .content {
    min-height: $conter2;
    height: auto;

    .erweima {
      margin: 3vw 4vw 0;
      width: 92vw;
      height: 72vh;
      border-radius: 3vw;
      background: url("../../../assets/img/extension_bc.png") #f88f3e no-repeat;
      background-size: 100%;

      .bot {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .img {
          margin-top: 29vh;
          // width: 46vw;
          // height: 46vw;
          background: #fff;
          padding: 3vw;

          #qrcode {
            //  width: 46vw;
            //  height: 46vw;
          }
        }

        p {
          margin-top: 10vw;
          font-size: 5vw;
          font-family: FZQingChunTiS;
          font-weight: bold;
          color: #f9f9f9;
        }
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      padding: 2vh 7vw 4vw;

      // border-bottom: 3vw solid #f9f9f9;
      /deep/.van-button {
        width: 37vw;
        height: 13vw;
        border-radius: 2vw;
      }

      /deep/.van-button--normal {
        font-size: 4vw;
        padding: 0;
      }
    }

    .rule {
      padding: 0 4vw;

      >h3 {
        margin-top: 4vw;
        display: flex;
        justify-content: center;
      }

      >p {
        color: #333;
      }
    }
  }
}
</style>
