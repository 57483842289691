module.exports = {
  "请输入搜索关键词": "Please enter search keywords",
  "加载中...": "Loading",
  "综合": "comprehensive",
  "价格": "price",
  "销量": "sales volume",
  "人": "people",
  "付款": "payment",
  "确认订单": "Confirm order",
  "请去添加地址": "Please add an address",
  "规格": "specifications",
  "共": "common",
  "件": "piece",
  "小计": "Subtotal",
  "合计": "total",
  "结算": "balance",
  "交易密码": "Transaction password",
  "请输入交易密码": "Please enter the transaction password",
  "支付成功": "Payment successful",
  "订单金额": "Order amount",
  "订单单号": "Order number",
  "复制": "copy",
  "查看其他等级购买价格": "View purchase prices for other levels",
  "请选择收货地址": "Please select a shipping address",
  "提交成功": "Submitted successfully",
  "复制成功": "Copy successful",
  "复制失败": "copy failed",
  "联系客服": "Contact customer service",
  "公司介绍": "Company Introduction",
  "发布时间": "Release time",
  "购买价格": "Purchase price",
  "全场会员折扣": "Full membership discount",
  "等级利润": "Grade profit",
  "转卖价格": "Resale price",
  "转卖利润": "Profit from resale",
  "收益计算": "Revenue calculation",
  "请输入数量": "Please enter the quantity",
  "开始计算": "Start calculation",
  "抢购价": "Flash sale price",
  "元": "$",
  "转卖价": "Resale price",
  "选择抢购专区商品": "Choose products from the flash sale zone",
  "新款商品": "New products",
  "活动商品": "Activity products",
  "资讯详情": "Information details",
  "资讯中心": "Information Center",
  "会员福利": "Membership benefits",
  "当前会员等级": "Current membership level",
  "已领取": "Received",
  "领取": "receive",
  "会员升级礼金": "Member upgrade bonus",
  "天天现金红包": "Daily cash red envelope",
  "每月话费额度": "Monthly phone bill limit",
  "每月油卡额度": "Monthly fuel card limit",
  "升级更高等级获得更多权益": "Upgrade to a higher level to gain more benefits",
  "点击联系客服升级": "Click to contact customer service for upgrade",
  "请输入手机号": "Please enter your phone number",
  "请输入油卡卡号": "Please enter the fuel card number",
  "确定": "confirm",
  "您已经领取过该权益": "You have already claimed this benefit",
  "公告详情": "Announcement details",
  "公告": "announcement",
  "商品详情": "Product details",
  "库存": "inventory",
  "评价": "evaluate",
  "商家回复": "Merchant response",
  "商家未回复，请耐心等待": "The merchant has not replied, please be patient and wait",
  "购物车": "shopping cart",
  "已收藏": "Favorite",
  "收藏": "collection",
  "加入购物车": "Add to shopping cart",
  "立即购买": "Buy Now",
  "数量": "quantity",
  "收藏成功": "Collection successful",
  "取消收藏成功": "Cancel collection successful",
  "刷新成功": "Refresh successful",
  "这里空空如也~": "This place is completely empty~",
  "人付款": "Payment by person",
  "等级制度": "Hierarchical system",
  "点击联系客服": "Click to contact customer service",
  "请去添加收货地址": "Please add a shipping address",
  "选择规格": "Select specifications",
  "提交订单": "place order",
  "请选择规格": "Please select a specification",
  "抢购商品详情": "Rush to purchase product details",
  "立即抢购": "Buy now",
  "关闭": "close",
  "搜索商品": "Search for products",
  "已售": "Sold ",
  "未开始": "Not started yet",
  "已结束": "Ended",
  "完成": "complete",
  "编辑": "edit",
  "没有更多了": "There's nothing more left",
  "删除": "delete",
  "全选": "Select All",
  "立即付款": "Immediate payment",
  "确定删除": "Confirm deletion",
  "隐私协议": "Privacy Agreement",
  "注册协议": "Registration Agreement",
  "下载安装": "Download and Install",
  "适用于安卓手机": "Suitable for Android phones",
  "扫描二维码下载APP": "Scan the QR code to download the app",
  "登录": "Login",
  "用户名": "user name",
  "请输入用户名": "enter one user name",
  "密码": "password",
  "请输入您的登录密码": "Please enter your login password",
  "立即注册": "Register Now",
  "忘记密码": "Forgot password",
  "在线客服": "Online Service ",
  "请输入登录密码": "Please enter your login password",
  "登录成功": "Login successful",
  "验证码": "Verification code",
  "请输入验证码": "Please enter the verification code",
  "获取验证码": "Obtain verification code",
  "后获取": "Post acquisition",
  "登录密码": "Login password",
  "请输入登录密码(数字加字母6 - 12)": "Please enter your login password (number plus letters 6-12)",
  "确认登录密码": "Confirm login password",
  "请确认登录密码": "Please confirm the login password",
  "请输入正确格式的手机号": "Please enter the correct formatted phone number",
  "发送成功": "Sending successful",
  "登录密码必须是6至12位的字母数字组合": "The login password must be a combination of 6 to 12 alphanumeric characters",
  "请输入确认登录密码": "Please enter the confirmed login password",
  "两次输入的登录密码不一致": "The login passwords entered twice are inconsistent",
  "修改成功": "Modified successfully",
  "请设置6-12位登录密码": "Please set a 6-12 digit login password",
  "确认交易密码": "Confirm transaction password",
  "请输入确认交易密码": "Please enter the confirmation transaction password",
  "注册": "register",
  "登录即代表阅读并同意": "Login means reading and agreeing",
  "和": "and",
  "注册成功": "login was successful",
  "姓名": "name",
  "收货人姓名": "Consignee Name ",
  "电话": "telephone",
  "收货人手机号": "Recipient's mobile phone number",
  "地区": "region",
  "请选择省 / 市 / 区": "Please select province/city/district",
  "设为默认收货地址": "Set as default shipping address",
  "保存": "preserve",
  "添加地址": "Add address",
  "编辑地址": "Edit Address",
  "我的银行卡": "My bank card",
  "使用": "apply",
  "添加银行卡": "Add bank card",
  "提现": "Withdrawal",
  "提现记录": "Withdrawal records",
  "余额钱包": "Balance wallet",
  "去添加银行卡": "Go and add a bank card",
  "提现数量": "Withdrawal quantity",
  "请输入提现数量": "Please enter the withdrawal quantity",
  "到账数量": "Received quantity",
  "自动计算到账数量": "Automatically calculate the received quantity",
  "手续费": "Handling fees",
  "确认提现": "Confirm withdrawal",
  "确认": "confirm",
  "待审核": "Pending review",
  "通过": "adopt",
  "驳回": "Reject",
  "已取消": "Canceled",
  "取消": "cancel",
  "本月收益": "Revenue for this month",
  "收益": "income",
  "转卖订单号": "Resale order number",
  "由": "from",
  "进行转卖": "Perform resale",
  "本月": "This month",
  "推广名片": "Promote business cards",
  "推广享优惠": "Promote and enjoy discounts",
  "复制链接": "Copy Link",
  "资金记录": "Financial records",
  "全部": "All",
  "简体中文": "Simplified Chinese",
  "日语": "Japanese",
  "收货地址": "Receiving address",
  "默认": "default",
  "设为默认": "Set as default",
  "使用中": "in use",
  "提示": "prompt",
  "确认要删除吗?": "Are you sure you want to delete it?",
  "删除成功": "Delete successful",
  "确认要使用此地址码?": "Are you sure you want to use this address code?",
  "绑定成功": "Binding successful",
  "我的收藏": "My favorites",
  "取消收藏": "Cancel Favorite",
  "取消成功": "Cancel successful",
  "条评价": "Evaluation",
  "评论内容": "Comment content",
  "订单号": "Order number",
  "我的资料": "My profile",
  "修改交易密码": "Change transaction password",
  "修改登录密码": "Change login password",
  "选择语言": "Choose language",
  "请输入姓名": "Please enter your name",
  "开户行": "Opening bank",
  "请输入开户行": "Please enter the account opening bank",
  "银行卡号": "Bank card number",
  "请输入银行卡号": "Please enter your bank card number",
  "绑定银行卡": "Bind bank card",
  "旧密码": "Old password",
  "请输入旧密码": "Please enter your old password",
  "确认密码": "Confirm password",
  "请再次输入登录密码": "Please enter your login password again",
  "个人资料": "personal data",
  "修改头像": "Modify avatar",
  "保存成功": "Save successful",
  "请再次输入交易密码": "Please re-enter the transaction password",
  "订单详情": "Order details",
  "待支付": "To be paid",
  "已支付": "Paid",
  "已发货": "Shipped",
  "已收货": "Received goods",
  "已完成": "Completed",
  "退货申请中": "Return request in progress",
  "退货中待发货": "Return pending shipment",
  "退货中已发货": "Shipped during return",
  "退款成功": "Refund successful",
  "订单取消": "Order cancellation",
  "转卖中": "Resale in progress",
  "转卖完成": "Completion of resale",
  "倒计时": "Countdown",
  "订单总数": "Total number of orders",
  "总金额": "Total amount",
  "创建时间": "Creation time",
  "申请退货": "Apply for return",
  "上传单号": "Upload order number",
  "确认收货": "Confirm receipt",
  "立即评价": "Evaluate Now",
  "立即支付": "Pay Now",
  "收货成功": "Received successfully",
  "此时此地，想和大家分享什么": "What would you like to share with everyone here and now",
  "请输入退货理由": "Please enter the reason for return",
  "提交": "Submit",
  "评价完成": "Evaluation completed",
  "提交申请成功": "Successfully submitted application",
  "配送单号": "Delivery tracking number",
  "请输入配送单号": "Please enter the delivery tracking number",
  "配送公司": "Delivery company",
  "请输入配送公司": "Please enter the delivery company",
  "上传": "upload",
  "上传成功": "Upload successful",
  "转卖中心": "Resale center",
  "待转卖": "To be resold",
  "已转卖": "Resold",
  "已失败": "Failed",
  "订单编号": "Order number",
  "商品原价": "Original price of goods",
  "转卖金额": "Resale amount",
  "利润": "profit",
  "名牌购商城": "Famous Brand Shopping Mall",
  "全部商品": "All products",
  "超值正版好物": "Premium genuine products",
  "抢购专区": "Rush Purchase Zone",
  "限时抢购，抢抢抢": "Limited time flash sale",
  "轻松出手，赚赚赚": "Easy to take action, earn money",
  "查看全部": "View All",
  "品牌推荐": "Brand recommendation",
  "更多": "more",
  "现价": "Current price",
  "充值": "Recharge",
  "我的评价": "My review",
  "资金明细": "Fund details",
  "退出登录": "Log out and log in",
  "确认要退出登录吗?": "Are you sure you want to log out?",
  "退出登录成功": "Successfully logged out and logged in",
  "全部订单": "All orders",
  "未转卖": "Not resold",
  "转卖成功": "Successful resale",
  "转卖失败": "Resale failed",
  "订单类型": "Order type",
  "商品购买": "Product purchase",
  "幸运抢购": "Lucky Rush",
  "幸运爆单": "Lucky Burst",
  "幸运连单": "Lucky combo",
  "去评价": "To evaluate",
  "退款中": "Refund in progress",
  "查看详情": "View details",
  "售后": "After sales",
  "转卖区": "Resale area",
  "转卖记录": "Resale records",
  "请输入订单编号": "Please enter the order number",
  "粘贴": "paste",
  "确认转卖": "Confirm resale",
  "查看其他等级转卖利润": "View other levels of resale profits",
  "请先输入订单编号": "Please enter the order number first",
  "首页": "home page",
  "抢购区": "Snatch area",
  "订单": "order form",
  "我的": "My",
  "银行名称": "Bank name",

  "接口不存在": "Interface does not exist",
  "未登录": "Not logged in",
  "服务器内部错误": "Internal server error",

  "详细地址": "Detailed address",
  "街道门牌、楼层房间号等": "Street number, floor room number, etc",
  "加入成功": "Joined successfully",
  "上传单号": "Upload order number",
  "请输入银行名称": "Please enter the bank name",
  "联系电话": "Contact phone number",
  "请选择商品！": "Please select a product!",
  "订单超时了,请联系客服": "The order has exceeded the time limit, please contact customer service",

  "身份证明书": "Certificate of Identity",
  "邮政编码": "Postal Code",
  "自动生成": "language generation",
  "银行支行": "bank branch",
  "请输入银行支行": "Please enter bank branch"
}
