<template>
  <!-- 抢购区 -->
  <div class="rushToPurchase">
    <div class="header_box">
      <header class="header">
        <van-search v-model="searchVal" shape="round" background="#F13B10" :placeholder="$t('请输入搜索关键词')"
          class="searchBox">
          <template #right-icon>
            <div @click="onSearch(searchVal)">
              <van-icon name="search" size="18" />
            </div>
          </template>
        </van-search>
      </header>
      <div class="notice">
        <van-notice-bar :scrollable="false" background="#F9F9F9">
          <template #left-icon>
            <img src="../../assets/img/rushToPurchase_notice_icon.png"
              style="margin-right: 3vw; width: 5vw; object-fit: cover" alt="" />
            <!-- <span class="noticeTite">公告</span> -->
          </template>
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
            <van-swipe-item v-for="(item, index) in RushBanner" :key="index">{{
              item.content
            }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="sort">
        <div class="comprehensive" :class="selectStyle == 1 ? 'cur' : ''" @click="changeTab(1)">
          {{ $t('综合') }}
        </div>
        <div class="price" :class="selectStyle == 2 ? 'cur' : ''" @click="changeTab(2)">
          {{ $t('价格') }}
          <img :src="getSortPriceUrl" style="width: '1vw'; height: 2.5vw" alt="" />
        </div>
        <div class="salesVolume" :class="selectStyle == 3 ? 'cur' : ''" @click="changeTab(3)">
          {{ $t('销量') }}
          <img :src="getSortSalesUrl" style="width: '1vw'; height: 2.5vw" alt="" />
        </div>
      </div>
    </div>

    <div class="container">
      <div class="content2">
        <van-pull-refresh v-model="Down" :success-text="$t('刷新成功')" @refresh="get_down">
          <van-list v-model="up" :finished="over" :finished-text="finished_text" @load="get_up"
            :immediate-check="false">
            <van-empty :description="$t('这里空空如也~')" v-if="list && list.length == 0" />
            <div class="rushToBuyList">
              <div class="rushToBuyShop" v-for="ele in list" :key="ele.id">
                <div class="timeAndImg">
                  <img :src="ele.goods_thumb" alt="" style="
                      height: 44vw;
                      border-radius: 3vw;
                      overflow: hidden;
                      object-fit: cover;
                    " />
                  <div class="time">
                    <van-icon name="clock-o" color="#fff" size="14" />
                    {{ $t('倒计时') }}
                    <van-count-down millisecond :time="reTime * 1000" format="HH:mm:ss" />
                  </div>
                </div>
                <h3>{{ ele.goods_name }}</h3>
                <span class="price">
                  <div class="sellingPrice one">
                    <span>{{ ele.discount_price.length > 7 ? ele.discount_price * 1 : ele.discount_price }}{{ $t('元')
                      }}</span>
                  </div>
                  <div class="originalPrice">
                    {{ ele.goods_price.length > 7 ? ele.goods_price * 1 : ele.goods_price }}
                  </div>
                </span>
                <div>{{ $t('销量') }}：{{ ele.goods_sales }}</div>
                <div class="purchase">
                  <div class="purchasePrice purchaseFlex">
                    <span class="one">{{ $t('元') }}{{ ele.discount_price }}</span>
                  </div>
                  <div class="state purchaseFlex" v-if="begin > +new Date() / 1000">
                    {{ $t('未开始') }}
                  </div>
                  <div class="state purchaseFlex" v-if="begin < +new Date() / 1000 && end > +new Date() / 1000
                  " @click="makePanicPurchase(ele)">
                    {{ $t('立即抢购') }}
                  </div>
                  <div class="state purchaseFlex" v-if="end < +new Date() / 1000">
                    {{ $t('已结束') }}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper boxa" @click.stop v-if="showcode.code == 1">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="navorders">
          {{ $t('确定') }}
        </van-button>
      </div>

      <div class="wrapper boxb" @click.stop v-if="showcode.code == 2">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D"
          @click="$router.replace({ name: 'order', query: { active: 1 } })">
          {{ $t('关闭') }}
        </van-button>
      </div>

      <div class="wrapper boxc" @click.stop v-if="showcode.code === 0">
        <div class="titblock">
          <p>{{ showcode.msg }}</p>
        </div>
        <van-button style="width: 85%" round type="info" color="#FFF20D" @click="show = false">
          {{ $t('关闭') }}
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "rushToPurchase",
  props: {},
  data() {
    return {
      searchVal: "",
      list: [],
      RushBanner: [],
      begin: "",
      end: "",

      // 排序    正序 :ASC ：倒序 ：DESC   空
      sort_price: "ASC", //默认正序
      sort_sales: "", //默认 空
      selectStyle: 1, //判断选用样式用

      finished_text: "",
      page: 1,
      loading: false,
      Down: false,
      up: false,
      over: false,
      show: false,
      showcode: {},
      orders: {},
    }
  },
  computed: {
    // 倒计时
    reTime: function () {
      return this.end - Math.floor(+new Date() / 1000)
    },
    // 价格
    getSortPriceUrl() {
      if (this.sort_price == "") {
        // 未按
        return require(`../../assets/img/sort_un.png`)
      } else if (this.sort_price == "ASC") {
        // 正序
        return require(`../../assets/img/sort_up.png`)
      } else {
        // 倒叙序
        return require(`../../assets/img/sort_down.png`)
      }
      return require(`../../assets/img/sort_un.png`)
    },
    // 销量
    getSortSalesUrl() {
      if (this.sort_sales == "") {
        // 未按
        return require(`../../assets/img/sort_un.png`)
      } else if (this.sort_sales == "DESC") {
        // 正序
        return require(`../../assets/img/sort_down.png`)
      } else {
        // 倒叙序
        return require(`../../assets/img/sort_up.png`)
      }
      return require(`../../assets/img/sort_un.png`)
    },
  },
  created() {
    //滚动到顶部
    this.xz_top()
    this.get_rush()
    this.list_init()
  },
  methods: {
    onSearch(val) {
      this.$router.push({
        name: "searchRush",
        query: {
          searchContent: val,
        },
      })
    },
    changeTab(e) {
      this.selectStyle = e
      if (e == 1) {
        // 综合
        this.sort_price = "ASC"
        this.sort_sales = ""
      } else if (e == 2) {
        // 价格
        this.sort_sales = ""
        if (this.sort_price == "ASC") {
          this.sort_price = "DESC"
        } else {
          this.sort_price = "ASC"
        }
      } else {
        // 销售
        this.sort_price = ""
        if (this.sort_sales == "DESC") {
          this.sort_sales = "ASC"
        } else {
          this.sort_sales = "DESC"
        }
      }
      //获取商品列表
      this.list_init()
    },
    // 公告
    get_rush() {
      this.$get({
        url: "/app/message/list",
        data: { type: 1 },
        success: (res) => {
          this.RushBanner = res.data
        },
      })
    },

    //初始化数据
    list_init() {
      console.log("初始化")
      this.page = 1
      this.over = false
      this.get_list("down") // 加载数据
      console.log(this.list)
    },
    //下拉刷新 下拉的这个 调了getlistla
    get_down() {
      console.log("下拉")
      setTimeout(() => {
        this.page = 1
        this.over = false
        this.get_list("down") // 加载数据
      }, 1000)
    },
    // 上拉加载 上拉这个 调用getlist
    get_up() {
      console.log("上拉")
      this.page++
      this.get_list("up") // 加载数据
    },
    // 更多广告
    get_list(type) {
      // let data = Object.assign({ page: this.page }, this.data);
      this.$get({
        url: "/app/goods/list",
        data: {
          page: this.page,
          is_recommend: 0,
          is_seckill: 1,
          brand_id: 0,
          cat_id: 0,
          search: this.searchVal,
          sort_price: this.sort_price,
          sort_sales: this.sort_sales,
        },
        success: (res) => {
          this.begin = res.data.seckill_set.begin_time
          this.end = res.data.seckill_set.end_time
          let data = res.data.list
          // console.log("返回的数据", data)
          if (type == "down") {
            this.list = data
          } else if (type == "up") {
            if (this.list) {
              this.list = [...this.list, ...data]
            }
            if (data.length == 0) {
              this.over = true
            }
          }
          this.Down = false
          this.up = false
        },
        tip: (val) => {
          this.over = true
        },
      })
    },
    makePanicPurchase(data) {
      this.$post({
        url: "/app/order_goods/makePanicPurchase",
        data: {
          goods_id: data.id,
        },
        success: (res) => {
          this.showcode = res
          this.orders.seckill_id = res.data.seckill_id
          this.orders.id = data.id
          this.show = true
        },
        tips: (err) => {
          console.log(12, err)
          this.showcode = err
          this.show = true
        },
      })
    },
    navorders() {
      this.$router.push({
        name: "RushconfirmOrder",
        query: {
          seckill_id: this.orders.seckill_id,
          goods_id: this.orders.id,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.van-overlay {
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 80vw;
    height: 80vw;
    padding: 18vw 14vw 3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    .titblock {
      width: 100%;
      color: #fff;
      font-size: 4vw;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
      }
    }
  }
}

.boxa {
  background: url("../../assets/img/a.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxb {
  background: url("../../assets/img/b.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.boxc {
  background: url("../../assets/img/c.png") no-repeat;
  background-size: 100% 100%;

  /deep/.van-button {
    color: #ef514b !important;
    font-weight: 550;
    margin-top: 2vh;
  }
}

.rushToPurchase {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 12vh 0;

  /deep/.van-count-down {
    color: #fff;
  }

  /deep/.van-search {
    flex: 1;

    .van-field__left-icon {
      display: none;
    }
  }

  .container {
    min-height: 80.3vh;
    height: auto;
    padding-top: calc(54px + 13.33vw + 15vw);
  }

  .notice {

    //margin-top: 3.47vw;
    .notice-swipe {
      height: 13.33vw;
      line-height: 13.33vw;
    }

    /deep/.van-notice-bar {
      font-size: 3vw;
      color: #000000;
    }

    .noticeTite {
      margin: 0 2.67vw;
    }
  }

  .sort {
    padding: 0 4vw 0 7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15vw;
    background-color: #fff;

    //background-color: chartreuse;
    .comprehensive {
      font-size: 4vw;
      font-weight: 500;
    }

    .price,
    .salesVolume {
      margin-right: 3vw;
      color: #999999;
      font-size: 4vw;
      font-weight: 500;
    }

    .cur {
      color: #f13b10;
    }
  }

  .content2 {
    padding: 0vw 4vw 8vh;

    .rushToBuyList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 92vw;

      .rushToBuyShop {
        margin-bottom: 3vw;
        width: 44vw;
        min-height: 77.07vw;
        height: auto;

        .timeAndImg {
          position: relative;

          .time {
            display: flex;
            align-items: center;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            height: 8vw;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            font-size: 3vw;
            color: #fff;
            border-bottom-left-radius: 3vw;
            border-bottom-right-radius: 3vw;
          }
        }

        >h3 {
          margin-top: 2.67vw;
          color: #3d444d;
          /*文字溢出隐藏显示省略号*/
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .price {
          display: flex;
          justify-content: space-between;
          margin-top: 0.67vw;

          .sellingPrice {
            font-size: 3vw;
            color: #3d444d;
            white-space: nowrap;

            >span {
              font-size: 4vw;
              font-weight: bold;
              color: #3a3d57;
            }
          }

          .originalPrice {
            color: #3a3d57;
            text-decoration: line-through;
          }
        }

        .purchase {
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          margin-top: 2.67vw;
          height: 9vw;
          border-radius: 1vw;
          width: 100%;

          .purchaseFlex {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            height: 100%;
          }

          .purchasePrice {
            font-size: 4vw;
            color: #f13b10;
            font-weight: bold;
            background-color: #ffce60;
          }

          .state {
            width: 40%;
            font-size: 3vw;
            color: #fff;
            background-color: #f13b10;
          }
        }
      }
    }
  }
}
</style>
