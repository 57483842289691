<template>
  <!-- 搜索商品 -->
  <div class="searchCommodity">
    <div class="header_box">
      <van-nav-bar :title="$t('搜索商品')" left-arrow @click-left="$router.go(-1)" :border="false" />
      <div class="searchBox">
        <van-search v-model="searchContent" shape="round" :placeholder="$t('请输入搜索关键词')">
          <template #right-icon>
            <div @click="onSearch()">
              <van-icon name="search" size="18" />
            </div>
          </template>
        </van-search>
      </div>
    </div>

    <div class="container">
      <vant-up-down ref="child" @list="(val) => (searchList = val)" res="res.data.list" url="/app/goods/list" :data="{
        search: searchContent,
        cat_id: 0,
        brand_id: 0,
        is_recommend: 0,
        is_seckill: 2,
      }">
        <div class="content">
          <div class="list" v-for="ele in searchList" :key="ele.id">
            <div class="productInformation">
              <div class="imgTite" @click="xz_go('productDetailsOrEvaluation', ele.id)">
                <img :src="ele.goods_thumb" alt="" style="
                  width: 23vw;
                  height: 23vw;
                  border-radius: 2vw;
                  overflow: hidden;
                  margin: 4vw 3vw 4vw 0vw;
                " />
                <div class="tite">
                  <h3>{{ ele.goods_name }}</h3>
                  <div class="priceNum">
                    <div>
                      <div class="originalPrice grey">
                        {{ $t('元') }}{{ ele.goods_price }}
                      </div>
                      <div class="orange">
                        <span class="font4 fontBold">
                          {{ $t('元') }}{{ ele.discount_price }}
                        </span>
                      </div>
                    </div>
                    <div class="num grey">{{ $t('已售') }}{{ ele.goods_sales }}{{ $t('件') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vant-up-down>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      searchContent: "",
      searchList: [],
    };
  },
  created() {
    this.searchContent = this.$route.query.searchContent;
    console.log(this.searchContent);
  },
  mounted() {
    //this.get_SeachGoodes();
  },
  methods: {
    onSearch() {
      this.$refs.child.list_init();
    },
    // get_SeachGoodes() {
    //   this.$get({
    //     url: "/app/goods/list",
    //     data: {
    //       search: this.searchContent,
    //       cat_id: 0,
    //       brand_id: 0,
    //       is_recommend: 0,
    //       is_seckill: 2,
    //     },
    //     success: (res) => {
    //       console.log(res);
    //       this.searchList = res.data.list;
    //     },
    //   });
    // },
  },
};
</script>

<style scoped lang="scss">
.font4 {
  font-size: 4vw;
}

.fontBold {
  font-weight: bold;
}

.grey {
  color: #999;
}

.orange {
  color: #f13710;
}

.searchCommodity {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 0 0 0;
  padding-top: calc(6.7vh + 54px);

  /deep/.van-nav-bar {
    background: none;

    .van-nav-bar__content {
      height: $navHeight;
      background-color: #fff;
    }

    .van-icon {
      color: #000;
    }

    .van-nav-bar__title {
      font-size: 4.5vw;
      font-weight: bold;
      color: #000;
    }
  }

  .searchBox {
    height: 7.3vh;

    /deep/.van-search {
      flex: 1;

      .van-field__left-icon {
        display: none;
      }
    }
  }

  // 内容
  .container {
    min-height: 84vh;
    height: auto;
  }

  .content {
    padding: 0 4vw;
    min-height: 84vh;

    .productInformation {
      .imgTite {
        display: flex;
        justify-content: space-between;

        .tite {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 4vw 0;
          width: 66vw;
          border-bottom: 1px solid #f5f5f5;

          >h3 {
            /*文字溢出隐藏显示省略号*/
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .priceNum {
            display: flex;
            justify-content: space-between;
            align-content: flex-end;

            .originalPrice {
              text-decoration: line-through;
              transform: scale(0.9);
            }

            .num {
              display: flex;
              align-items: flex-end;
            }
          }
        }
      }
    }
  }
}
</style>
